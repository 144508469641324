import { msg } from '@stores/msg'
import dayjs from 'dayjs'
import { runInAction } from 'mobx'
import practiceService from '@stores/services/practice.service'
import type { Advisor, AdvisorInput, Practice } from 'types/graphql'
import { practiceStore } from './practice.state'
import { filterAdvisors } from './practice.utils'
import { omit } from 'remeda'

/**
 * Set the practice data and initialize related state
 */
export function setPractice(practice: Practice, advisor: Advisor) {
  practiceStore.members.replace(filterAdvisors(practice, advisor))
  global.perms.setPermissions(practice?.permissions ?? [])

  Object.assign(practiceStore, omit(practice, ['members']))
  dayjs.tz.setDefault(practice?.tz ?? dayjs.tz.guess())
}

/**
 * Set practice statuses
 */
export function setStatuses(statuses: any) {
  practiceStore.statuses = statuses
}

/**
 * Set practice types
 */
export function setTypes(types: any) {
  practiceStore.types = types
}

/**
 * Add a new member to the practice, if not exists
 */
export const addMember = (member: Advisor) => {
  const mmbr = getMember(member.id)
  if (!mmbr) {
    practiceStore.members.set(Number(member.id), member)
  }
}

/**
 * Find a practice member
 */
export const getMember = (id: number | string) => {
  return practiceStore.members.get(Number(id))
}

/**
 * Update a practice member
 */
export const updateMember = (id: number | string, fields: Partial<AdvisorInput>) => {
  const member = getMember(id)
  if (member) {
    Object.keys(fields).forEach((key) => ((member as any)[key] = (fields as any)[key]))
    practiceStore.members.set(Number(id), member)
  }
  return member
}

/**
 * Update practice fields
 */
export async function updatePractice(fields: Partial<Practice>, showMsg = true) {
  try {
    const updated = await practiceService.updatePractice(practiceStore.id, {
      ...fields,
    })
    if (showMsg && updated) {
      msg.success('Practice updated successfully', '', 3)
    }
    runInAction(() => {
      Object.keys(fields).forEach((key) => {
        ;(practiceStore as any)[key] = fields[key as keyof Practice]
      })
      fields.tz ? dayjs.tz.setDefault(fields.tz) : dayjs.tz.setDefault()
    })
  } catch (_err) {
    msg.error('Ups, an error ocurred while updating the Practice')
  }
}
