import {
  atomToSmartfield,
  gqlToSmartField,
} from 'components/drawers/Smartfields/smartfield.convertors'
import { SmartField } from 'components/drawers/Smartfields/types'
import { SmartFieldAtomAttrs } from 'lib/remirror/extensions/smartFieldAtom/component'
import { isEmpty } from 'lodash'
import { makeAutoObservable, observable } from 'mobx'
import {
  create as createSmartfield,
  get as getSmartfields,
  remove as removeSmartfield,
  restore as restoreSmartfield,
  update as updateSmartfield,
} from 'stores/services/smartfield.service'
import { Smartfield as GQLSmartField, SmartfieldInput } from 'types/graphql'
import { v4 as uuidv4 } from 'uuid'
import { appendSid, extractSid, formatSid } from './utils'

class SmartFieldFactory {
  _smartfields = observable.map<string, SmartField>([])
  loading = true
  loaded = false

  get smartfields() {
    return (Array.from(this._smartfields.values()) as SmartField[]).filter(
      (sf) => isEmpty(sf.deletedAt),
    )
  }

  get archived() {
    return (Array.from(this._smartfields.values()) as SmartField[]).filter(
      (sf) => !isEmpty(sf.deletedAt),
    )
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   *
   */
  private load = async (): Promise<void> => {
    this.loading = true

    const smartfields = (await getSmartfields()) as GQLSmartField[]
    smartfields.map(this._store)

    this.loading = false
    this.loaded = true
  }

  /**
   *
   */
  get = (id: string): SmartField | undefined => {
    const fid = formatSid(id)
    const sid = extractSid(fid)

    const smartfield = this.smartfields.find((sf) => sf.id === `${sid}`)

    if (smartfield) {
      const newId = appendSid(fid, smartfield.id)
      return { ...smartfield, id: newId }
    }

    return smartfield
  }

  /**
   *
   */
  has = (id: string): boolean => {
    return !!this.get(id)
  }

  /**
   * @returns
   */
  getSmartfields = async (): Promise<SmartField[]> => {
    if (!this.loaded) await this.load()
    return this.smartfields
  }

  /**
   * @param atom
   * @returns
   */
  getSmartfield = async (atom: SmartFieldAtomAttrs): Promise<SmartField> => {
    if (atom.reuse === 'false' || atom.disableAtomSync) {
      // default config is for single-use atoms
      return atomToSmartfield(atom)
    }

    // extract if multi-use smartfield, and smartfields have loaded
    const smartfield = await this.getAsync(atom.id)

    // if reusable smartfield, return it, otherwise continue
    // to create non-reusable from atom below
    if (smartfield) {
      return smartfield
    }

    // if the smartfield doesn't exist in the store, it's either obsolete
    // or it's been soft deleted.  Either way, convert to single use smartfield
    return atomToSmartfield({ ...atom, reuse: 'false', id: uuidv4() })
  }

  /**
   * @param atom
   * @returns
   */
  getSmartfieldSync = (atom: SmartFieldAtomAttrs): SmartField => {
    if (atom.reuse === 'false' || atom.disableAtomSync) {
      // default config is for single-use atoms
      return atomToSmartfield(atom)
    }

    // extract if multi-use smartfield, and smartfields have loaded
    const smartfield = this.get(atom.id)

    // if reusable smartfield, return it, otherwise continue
    // to create non-reusable from atom below
    if (smartfield) {
      return smartfield
    }

    // if the smartfield doesn't exist in the store, it's either obsolete
    // or it's been soft deleted.  Either way, convert to single use smartfield
    return atomToSmartfield({ ...atom, reuse: 'false', id: uuidv4() })
  }

  /**
   * @param id
   * @returns
   */
  getAsync = async (id: string): Promise<SmartField | undefined> => {
    if (!this.loaded) await this.load()
    return this.get(id)
  }

  /**
   * Create a new Smartfield
   * @param input
   * @returns
   */
  create = async (input: SmartfieldInput): Promise<SmartField> => {
    return this._action(createSmartfield, input)
  }

  /**
   * Update existing smartfield
   * @param id
   * @param input
   * @returns
   */
  update = async (id: string, input: SmartfieldInput): Promise<SmartField> => {
    return this._action(updateSmartfield, extractSid(id), input)
  }

  /**
   * Delete a Smartfield
   * @param id
   * @returns
   */
  remove = async (id: string): Promise<SmartField> => {
    return this._action(removeSmartfield, extractSid(id))
  }

  /**
   * Restore a deleted Smartfield
   * @param id
   */
  restore = async (id: string): Promise<SmartField> => {
    return this._action(restoreSmartfield, extractSid(id))
  }

  /**
   * Primarily used for tests
   */
  reset = () => {
    this._smartfields.clear()
    this.loaded = false
    this.loading = false
  }

  /**
   * @param func
   * @param args
   * @returns
   */
  private _action = async (func: any, ...args: any[]) => {
    if (!this.loaded) await this.load()
    const smartfieldGQL = await func.apply(func, args)

    return this._store(smartfieldGQL)
  }

  /**
   * @param smartfieldGQL
   * @returns
   */
  private _store = (smartfieldGQL: GQLSmartField): SmartField => {
    const smartfield = gqlToSmartField(smartfieldGQL)
    this._smartfields.set(smartfield.id, smartfield)

    return smartfield
  }
}

const smartfieldFactory = new SmartFieldFactory()
export { smartfieldFactory }
