import { Cascader, Form, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/lib/cascader'
import { SmartFieldIntegrationFormatter } from 'components/drawers/Smartfields/integration/components/format.selector'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { SmartField } from 'components/drawers/Smartfields/types'
import {
  RiskalyzeConfig,
  RiskalyzeDataConfig
} from 'components/drawers/Smartfields/types/integration'
import { isArray } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { RiskalyzeDataType } from '../../riskalyze.types'
import { CascaderNode, cascaderOptions, parseDataFields } from './data.utils'

interface SmartFieldIntegrationRiskalyzeDataProps {
  form: FormInstance
  smartfield?: SmartField
  config: RiskalyzeConfig<RiskalyzeDataConfig>
  type: RiskalyzeDataType
}

export const SmartFieldIntegrationRiskalyzeData: FC<
  SmartFieldIntegrationRiskalyzeDataProps
> = ({ form, config, smartfield, type }) => {
  const [options, setOptions] = useState<CascaderNode[]>([])

  const onChange = useCallback(
    (_value: any, options: any) => {
      if (isArray(options) && options.length) {
        const newLabel = options.map((o) => o.label).join(' ')
        form.setFieldValue('label', newLabel)
      }
    },
    [form]
  )

  useEffect(() => {
    setOptions(cascaderOptions())
  }, [type])

  return (
    <>
      <Form.Item
        name='data_fields'
        rules={[{ required: true, message: `Select ${type} field` }]}
        label={
          <FormItemLabel
            label={`Data field`}
            description={`Select field to use`}
          />
        }
        initialValue={parseDataFields(config?.data_fields)}
        preserve={false}
      >
        <Cascader
          options={options}
          onChange={onChange}
          placeholder={`Select ${type} field`}
          size='large'
          allowClear
          autoFocus
          showSearch={{
            filter: (inputValue: string, path: DefaultOptionType[]) =>
              path.some(
                (option) =>
                  (option.label as string)
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) > -1
              )
          }}
        />
      </Form.Item>

      <SmartFieldIntegrationFormatter form={form} smartfield={smartfield} />
    </>
  )
}
