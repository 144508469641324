import {
  AppendLifecycleProps,
  ApplyStateLifecycleProps,
  CommandDecoratorOptions,
  extension,
  isElementDomNode,
  PrioritizedKeyBindings,
  StateUpdateLifecycleProps
} from '@remirror/core'
import {
  TableExtension,
  TableOptions,
  TableSchemaSpec
} from '@remirror/extension-tables'
import { Attrs } from '@remirror/pm/model'
import {
  CellSelection,
  TableView,
  toggleHeader,
  updateColumnsOnResize
} from '@remirror/pm/tables'
import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  convertCommand,
  CreateExtensionPlugin,
  ExtensionPriority,
  findParentNodeOfType,
  Handler,
  Helper,
  helper,
  NodeSpecOverride,
  NodeWithPosition,
  PrimitiveSelection,
  ProsemirrorNode,
  ResolvedPos,
  Transaction
} from 'remirror'
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore
} from './TableBasicCommands.utils'
import {
  createTableNodeSchema,
  goToNextCell,
  selectionCell,
  setCellAttrByResolvedPos,
  setSelectedTableAttribute
} from './TableCustom.utils'
import { reapplyTableBorders, setTableBorders } from './TableCustomBorder.utils'
import { TableRowCustomExtension } from './TableRowCustom.extension'

interface TableCustomOptions extends TableOptions {
  onClick: Handler<
    (
      event: MouseEvent,
      nodeWithPosition: NodeWithPosition
    ) => boolean | undefined | void
  >
}

const setTableCellColorOptions: CommandDecoratorOptions = {}

interface SetTableCellColorOptions {
  selection?: PrimitiveSelection
}

// @ts-ignore
@extension<TableCustomOptions>({
  defaultOptions: {
    resizable: true,
    resizeableOptions: {}
  },
  handlerKeys: ['onClick'],
  defaultPriority: ExtensionPriority.Default
})
// FIXME: we should add TableExtension extends NodeExtension<TableCustomOptions>
export class TableCustomExtension extends TableExtension {
  /**
   * The last known good state that didn't need fixing. This helps make the fix
   * command more effective.
   */

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).table
  }

  /**
   * We're overriding the internal `TableRowExtension` and `TableCellExtension` to enable them using our custom `createTableNodeSchema` function.
   * Using this so `verticalAlign` is inside the TableCell Attributes.
   */
  createExtensions() {
    return [new TableRowCustomExtension({ priority: ExtensionPriority.Low })]
  }

  createPlugin(): CreateExtensionPlugin<any> {
    const { resizable, resizeableOptions } = this.options
    if (!resizable) {
      return {}
    }
    if (!this.store.isMounted() || this.store.helpers.isViewEditable()) {
      return {}
    }
    const { cellMinWidth = 25 } = resizeableOptions
    return {
      props: {
        nodeViews: {
          table(node, view, getPos) {
            // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            const dom = view.nodeDOM(getPos())

            if (isElementDomNode(dom) && dom.tagName === 'TABLE') {
              updateColumnsOnResize(
                node,
                dom.firstChild as HTMLTableColElement,
                dom as HTMLTableElement,
                cellMinWidth
              )
            }

            return new TableView(node, cellMinWidth)
          }
        }
      }
    }
    return { ...super.createPlugin() }
    // return {
    //   props: {
    //     decorations: (state) => {
    //       const pluginState = this.getPluginState(state)
    //       const { doc, tr } = state
    //       const decorations: Decoration[] = []
    //       let isZebraEnabled = false

    //       /** In order to calculate the zebra elements */
    //       let index = 0

    //       /** Goes through all the nodes */
    //       state.doc.nodesBetween(0, doc.nodeSize - 2, (node, position) => {
    //         /** For each table, we initialize the index */
    //         if (node.type.name === 'table') {
    //           isZebraEnabled = node.attrs.zebra !== null
    //           index = 0
    //         } else if (
    //           /** We increase the index every row which contains simple cells */
    //           node.type.name === 'tableRow' &&
    //           node.firstChild!.type.name === 'tableCell' &&
    //           !node.firstChild!.attrs.className
    //         ) {
    //           index++
    //           const backgroundColor = index % 2 === 0 ? '#000000' : '#ffffff'
    //           console.log('im here')
    //           // @ts-ignore
    //           node.attrs.background = backgroundColor
    //           decorations.push(
    //             Decoration.node(position, position + node.nodeSize, { style: 'background:' + backgroundColor }),
    //           )
    //         }
    //       })

    //       console.log('isZebraEnabled', isZebraEnabled)
    //       console.log('pluginState', pluginState)
    //       console.log('currentState', state)
    //       if (isZebraEnabled) {
    //         return DecorationSet.create(state.doc, decorations)
    //       } else {
    //         const decorationSet = DecorationSet.create(state.doc, [])
    //         return null
    //       }
    //     },
    //   },
    // }
  }

  createKeymap(
    extractShortcutNames: (shortcut: string) => string[]
  ): PrioritizedKeyBindings {
    return {
      Tab: goToNextCell(1),
      'Shift-Tab': goToNextCell(-1)
    }
  }

  @helper()
  getTableCellColorForSelection(): Helper<string | null> {
    const state = this.store.getState()
    let $cell = selectionCell(state)
    const color = $cell?.nodeAfter?.attrs?.background

    return color || null
  }

  @helper()
  getTableCellResolvedPos(): Helper<ResolvedPos | null> {
    const state = this.store.getState()
    const $cell = selectionCell(state) || null
    return $cell
  }

  /**
   * Add a table row before the current selection.
   */
  @command()
  addTableRowBeforeCustom(): CommandFunction {
    return ({ state, tr, dispatch }) => {
      addRowBefore(state, tr)
      reapplyTableBorders(tr)
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  /**
   * Add a table row after the current selection.
   */
  @command()
  addTableRowAfterCustom(): CommandFunction {
    return ({ state, tr, dispatch }) => {
      addRowAfter(state, tr)
      reapplyTableBorders(tr)
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  @command()
  addTableColumnBeforeCustom(): CommandFunction {
    return ({ state, tr, dispatch }) => {
      addColumnBefore(state, tr)
      reapplyTableBorders(tr)
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }
  @command()
  addTableColumnAfterCustom(): CommandFunction {
    return ({ state, tr, dispatch }) => {
      addColumnAfter(state, tr)
      reapplyTableBorders(tr)
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  // @ts-ignore
  @command(setTableCellColorOptions)
  setTableCellColor(
    resolvedPos: ResolvedPos,
    color: string,
    options?: SetTableCellColorOptions
  ): CommandFunction {
    return setCellAttrByResolvedPos(resolvedPos, 'background', color)
  }

  @command()
  setTableCellAlignCenter() {
    return this.setSelectedTableCellAttribute({ textAlign: 'center' })
  }

  @command()
  setTableCellAlignLeft() {
    return this.setSelectedTableCellAttribute({ textAlign: 'left' })
  }

  @command()
  setTableCellAlignRight() {
    return this.setSelectedTableCellAttribute({ textAlign: 'right' })
  }

  @command()
  setTableCellAlignJustify() {
    return this.setSelectedTableCellAttribute({ textAlign: 'justify' })
  }

  @command()
  setTableCellVerticalAlignTop() {
    return this.setSelectedTableCellAttribute({ verticalAlign: 'top' })
  }

  @command()
  setTableCellVerticalAlignMiddle() {
    return this.setSelectedTableCellAttribute({ verticalAlign: 'middle' })
  }

  @command()
  setTableCellVerticalAlignBottom() {
    return this.setSelectedTableCellAttribute({ verticalAlign: 'bottom' })
  }

  @command()
  setTableBordersSolid(): CommandFunction {
    return ({ tr, dispatch }) => {
      setTableBorders(tr, null)
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  @command()
  setTableBordersHidden(): CommandFunction {
    return ({ tr, dispatch }) => {
      setTableBorders(tr, 'none')
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  @command()
  setTableBordersDashed(): CommandFunction {
    return ({ tr, dispatch }) => {
      setTableBorders(tr, 'dashed')
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  @command()
  toggleTableHeader(): CommandFunction {
    return convertCommand(toggleHeader('row'))
  }

  @command()
  toggleTableZebraStyle(): CommandFunction {
    return ({ tr, dispatch }) => {
      const tableAttrs = this.getTableAttributes(tr)
      const isZebraEnabled = tableAttrs!.zebra
      setSelectedTableAttribute(tr, { zebra: isZebraEnabled ? null : true })
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }
  }

  @helper()
  getIsTableSelected(): Helper<boolean | null> {
    const state = this.store.getState()
    const { $from, $to } = state.selection
    const range = $from.blockRange($to)

    if (!range) {
      return null
    }

    const { start, end } = range

    let isTableSelected = false

    const tableComponentNames = [
      'table',
      'tableCell',
      'tableRow',
      'tableHeaderCell'
    ]

    state.doc.nodesBetween(start, end, (node, pos, parent) => {
      if (pos < start || pos > end) {
        return
      }

      if (
        tableComponentNames.includes(node.type.name) ||
        (parent && tableComponentNames.includes(parent.type.name))
      ) {
        isTableSelected = true
        return false
      }

      return
    })
    return isTableSelected
  }

  getTableBorders(tr: Transaction): string | null {
    const tableAttrs = this.getTableAttributes(tr)
    if (tableAttrs!.border) {
      return tableAttrs!.border
    } else {
      const tableCellAttrs = this.getAllTableCellsAttributes(tr)
      if (tableCellAttrs) {
        const tableCellBorderAttrs = tableCellAttrs.map(
          ({ attrs }) => attrs.border
        )
        const allTableCellBorderHaveSameAttr = tableCellBorderAttrs.every(
          (attr) => attr === tableCellBorderAttrs[0]
        )
        if (allTableCellBorderHaveSameAttr) {
          return tableCellBorderAttrs[0]
        }
      }
    }
    return null
  }

  private getTableAttributes(tr: Transaction): Attrs | null {
    const foundTable = findParentNodeOfType({
      selection: tr.selection,
      types: 'table'
    })
    if (foundTable) {
      return foundTable.node.attrs
    }
    return null
  }

  private getAllTableCellsAttributes(
    tr: Transaction
  ): { pos: number; attrs: Attrs }[] | null {
    const { selection } = tr
    const foundTable = findParentNodeOfType({ selection, types: 'table' })
    if (foundTable) {
      const foundTableCells: { node: ProsemirrorNode; pos: number }[] = []
      foundTable.node.descendants((node, pos) => {
        if (node.type.name === 'tableCell') {
          foundTableCells.push({ node, pos: pos + foundTable.pos + 1 })
          return false
        }
        return true
      })

      const attrsFound = foundTableCells.map((tableCell) => {
        return {
          pos: tableCell.pos,
          attrs: tableCell.node.attrs
        }
      })
      return attrsFound
    }
    return null
  }

  private setSelectedTableCellAttribute(attributes: Attrs): CommandFunction {
    return (props) => {
      let { tr } = props
      const { dispatch } = props
      const { selection } = tr

      if (selection instanceof CellSelection) {
        selection.forEachCell((cellNode, pos) => {
          tr = tr.setNodeMarkup(pos, undefined, {
            ...cellNode.attrs,
            ...attributes
          })
        })
        dispatch?.(tr)
        return true
      }

      const found = findParentNodeOfType({ selection, types: 'tableCell' })

      if (found) {
        dispatch?.(
          tr.setNodeMarkup(found.pos, undefined, {
            ...found.node.attrs,
            ...attributes
          })
        )
        return true
      }

      return false
    }
  }

  onAppendTransaction(props: AppendLifecycleProps): void {
    const { tr } = props
    // console.log('tr', tr)
    // console.log('tr - docchanged', tr.docChanged)
  }

  onStateUpdate(props: StateUpdateLifecycleProps): void {}

  onApplyState(props: ApplyStateLifecycleProps): void {}
}
