import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Layout,
  Select,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  SmartFieldSettingsMode,
  smartfieldStore as store,
} from "stores/smartfields";
import { smartfieldFactory as factory } from "stores/smartfields/factory";
import { FlexRow } from "styles";
import { v4 as uuidv4 } from "uuid";
import Str from "../../../stores/util/str.util";
import { SmartFieldDate } from "./date";
import { SmartFieldIntegration } from "./integration";
import { SmartFieldList } from "./list";
import { SmartFieldNumber } from "./number";
import { ChangeReusableAlert } from "./smartfield.alerts";
import { useSmartFields, useSmartfieldChanged } from "./smartfield.hooks";
import { FormItemLabel } from "./smartfield.styles";
import { SmartField } from "./types";

type ButtonTitle = "Add" | "Insert" | "Update";

interface SmartFieldDrawerContentProps {
  smartfield?: SmartField;
  mode: SmartFieldSettingsMode;
}

export const SmartFieldDrawerContent: FC<SmartFieldDrawerContentProps> = ({
  smartfield,
  mode,
}) => {
  const [buttonTitle, setButtonTitle] = useState<ButtonTitle>("Insert");
  const [labelDisabled, setLabelDisabled] = useState(false);
  const [smartFieldTypes] = useState(store.smartFieldTypes());

  const {
    onInsertSmartField,
    onUpdateSmartField,
    hideSmartFieldDrawer,
    forceReuse,
  } = useSmartFields();
  const { changed, onChange } = useSmartfieldChanged();
  const smartfieldRef = useRef<HTMLElement>(null);

  const [form] = Form.useForm();
  const label = Form.useWatch("label", form);
  const type = Form.useWatch("type", form);

  const onFinish = useCallback(() => {
    const smartfieldFunc =
      mode === "add" ? onInsertSmartField : onUpdateSmartField;
    smartfieldFunc(form.getFieldsValue());
  }, [form, mode, onInsertSmartField, onUpdateSmartField]);

  useEffect(() => {
    setButtonTitle(forceReuse ? "Add" : mode === "add" ? "Insert" : "Update");
    if (forceReuse) {
      form.setFieldValue("reuse", forceReuse);
    }
  }, [form, mode, forceReuse]);

  useEffect(() => {
    form.resetFields(["label"]);
  }, [form]);

  useEffect(() => {
    const hasLabel = !isEmpty(smartfield?.label) || !isEmpty(label);
    setLabelDisabled(!hasLabel && type === "integration");
  }, [label, smartfield?.label, type]);

  return (
    <Layout
      style={{ padding: "24px 48px 32px 32px", backgroundColor: "white" }}
    >
      <Layout.Content
        className="p360-layout__conent-content"
        ref={smartfieldRef}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={onChange}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item name="id" hidden initialValue={smartfield?.id ?? uuidv4()}>
            <Input />
          </Form.Item>

          <Form.Item
            name="type"
            label={
              <FormItemLabel
                label="Type"
                description="Select Smartfield type"
              />
            }
            rules={[
              {
                required: true,
                message: "Please select SmartField type",
              },
            ]}
            initialValue={smartfield?.type ?? "text"}
          >
            <Select
              style={{ width: "100%" }}
              size="large"
              data-testid="smartfield-type-select"
              getPopupContainer={() =>
                smartfieldRef.current as unknown as HTMLElement
              }
            >
              {smartFieldTypes.map((type) => (
                <Select.Option
                  key={type}
                  value={type}
                  data-testid={`smartfield-type-option-${type}`}
                >
                  {Str.startCase(type)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="label"
            label={
              <FormItemLabel
                label="Label"
                description="Name it something unqiue"
              />
            }
            rules={[
              {
                required: true,
                message: "Please give your SmartField a label",
              },
              {
                message: "You have used that name already",
                validator: (_rule, value) => {
                  const lc = (str = "") => (str ? str.toLowerCase() : "");
                  const reuse = form.getFieldValue("reuse");

                  const used = factory.smartfields.some((sf: SmartField) => {
                    return (
                      lc(sf.label) === lc(value) &&
                      !smartfield?.id.endsWith(`|${sf.id}`)
                    );
                  });

                  if (used && reuse) {
                    return Promise.reject(
                      new Error("You have used that name already")
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            initialValue={smartfield?.label}
          >
            <Input
              style={{ width: "100%" }}
              size="large"
              autoFocus={true}
              data-testid="smartfield-label-input"
              // For integrations, initially disable unless there's a previous value.
              // The integration will auto-populate this when fields have been selected
              disabled={labelDisabled}
            />
          </Form.Item>

          {type === "date" && <SmartFieldDate smartfield={smartfield} />}
          {type === "integration" && (
            <SmartFieldIntegration smartfield={smartfield} form={form} />
          )}
          {type === "number" && (
            <SmartFieldNumber smartfield={smartfield} form={form} />
          )}
          {type === "list" && (
            <SmartFieldList smartfield={smartfield} form={form} />
          )}

          <Divider />
          {changed && <ChangeReusableAlert />}

          <FlexRow justify="space-between" align="center">
            <Space>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" size="large">
                  {buttonTitle} SmartField
                </Button>
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button onClick={hideSmartFieldDrawer} size="large">
                  Cancel
                </Button>
              </Form.Item>
            </Space>

            <Form.Item
              style={{ marginBottom: 0 }}
              name="reuse"
              valuePropName="checked"
              initialValue={smartfield?.reuse}
              hidden={forceReuse}
            >
              <Checkbox
                data-testid="smartfield-reuse-checkbox"
                onChange={() => form.validateFields()}
              >
                <Typography.Text>Reusable</Typography.Text>
              </Checkbox>
            </Form.Item>
          </FlexRow>
        </Form>
      </Layout.Content>
    </Layout>
  );
};
