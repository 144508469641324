import { captureExceptionSilently } from '@helpers/sentry'
import { msg } from '@stores/msg'
import noteService from '@stores/services/note.service'
import { orderBy } from 'lodash'
import { observable } from 'mobx'
import { NoteCreationInput, NoteInput } from 'types/graphql'
import dayjs from 'dayjs'
import { trackEvent } from '@helpers/posthog'
import scratchService from '@stores/services/scratch.service'
import Str from '@stores/util/str.util'

/**
 * Load all files for a given s3 path
 */
export const addNote = async (note: NoteCreationInput) => {
  return noteService.createNote(note)
}

export const createNoteFromMirrored = async (note: NoteCreationInput, originalNoteId: any) => {
  return noteService.createFromMirroredNote({ note, originalNoteId })
}

export const bulkAddNote = async (notes: NoteCreationInput[]) => {
  global.app.loading = `Adding ${notes.length} Notes! Working hard...`

  try {
    return await noteService.bulkAddNotes({ notes })
  } catch (error) {
    msg.error(['notes', 'adding'])
    captureExceptionSilently(error, {
      message: 'bulkAddNote',
      data: { notes },
    })
    throw error
  } finally {
    global.app.loading = false
  }
}

/**
 * Delete Note
 */
export const deleteNote = async (
  noteId: any,
  tasks = [],
  workflows = [],
  deleteOnProvider = false,
  deleteMirrored = false
) => {
  global.app.loading = 'Deleting...'
  const payload = {
    id: noteId,
    tasks: tasks.filter(Boolean),
    workflows: workflows.filter(Boolean),
    deleteOnProvider,
    deleteMirrored,
  }

  try {
    trackEvent('Delete Note', {
      task: payload.tasks.length,
      workflows: payload.workflows.length,
      deleteOnProvider,
      noteId: payload.id,
    })
    return await noteService.deleteNote(payload)
  } catch (error) {
    msg.error(['note', 'deleting'])
    captureExceptionSilently(error, {
      message: 'deleteNote',
      data: { payload },
    })
  } finally {
    global.app.loading = false
  }
}

export const removeMirroredNote = async (originalNoteId: any, eventId: any) => {
  const payload = {
    noteIds: [originalNoteId],
    eventId,
  }
  try {
    // TODO: Consider improving loading states
    global.app.loading = 'Deleting...'
    return noteService.removeMirroredNotes(payload)
  } catch (error) {
    msg.error(['note', 'deleting'])
    captureExceptionSilently(error, {
      message: 'removeMirroredNote',
      data: { payload },
    })
  } finally {
    global.app.loading = false
  }
}

export const updateNote = async (id: number, note: NoteInput) => {
  try {
    await noteService.updateNote({ id, note })
  } catch (error) {
    msg.error(['note', 'updating'])
    captureExceptionSilently(error, {
      message: 'updateNote',
      data: { id, note },
    })
    throw error
  }
}

export const updateNotesInBulk = async (notes: NoteInput[]) => {
  try {
    const accepted = await noteService.updateNotesInBulk({ notes })

    return accepted
  } catch (error) {
    msg.error(['notes', 'updating'])
    captureExceptionSilently(error, {
      message: 'updateNotesInBulk',
      data: { notes },
    })
  }
}

export const reorderNotes = async (apptId: number, order: number) => {
  await noteService.reorderAppointmentNotes({ apptId, order })
}

export const transfer = async (model: any, items: any, apptId: number, clientId: number) => {
  if (!clientId) {
    clientId = global.data.appt.getAppt(apptId).clientId as number
  }

  const payload = {
    [model]: (items || []).map((e: any) => (isNaN(e) ? e.id : e)),
    apptId,
    clientId,
  }

  try {
    trackEvent('Transfer ' + model, {
      itemsTransferred: items.length,
      clientId,
      apptId,
    })

    if (model === 'notes') {
      return await noteService.transferNotes(payload as any)
    } else {
      return await scratchService.transferScratches(payload as any)
    }
  } catch (error) {
    msg.error([model, 'transferring'])
    captureExceptionSilently(error, {
      message: 'transfer',
      data: { payload },
    })
  }
}

export const orderNotesById = (ids: any, notes: any) => {
  return ids
    .map((id: any, index: any) => ({
      ...notes.find((elem: any) => elem.id === id),
      order: index,
    }))
    .filter((note: any) => !!note.id)
}

export const updateNoteListItemProps = (noteList: any, itemId: any, itemFields: any) => {
  const index = noteList.findIndex((o: any) => o.id === itemId)
  if (index !== -1) {
    const item = noteList[index]
    Object.keys(itemFields).forEach((key) => {
      item[key] = itemFields[key]
    })

    const modifiedList = [...noteList.slice(0, index), item, ...noteList.slice(index + 1)]
    if ('order' in itemFields || 'createdAt' in itemFields || 'appointmentId' in itemFields) {
      return orderBy(modifiedList, ['appointmentId', 'order', 'createdAt'], ['asc', 'asc', 'desc'])
    } else {
      return modifiedList
    }
  }
  return noteList
}

/**
 * @param {*} note
 */
export const hydrateNotes = (notes: any, noteIdsIncluded: any[] = []) => {
  return observable(
    notes.filter(Boolean).map((note: any) => {
      let advisor = global.data.advisors.getAdvisor(note.advisorId)
      if (!advisor) {
        advisor = global.data.advisors.randomAdvisor()
      }

      const hydrated = {
        ...note,
        text: note.text ?? '',
        isAgenda: Str.toBool(note.isAgenda),
        isSummary: Str.toBool(note.isSummary),
        isPrivate: Str.toBool(note.isPrivate),
        createdAt: dayjs(note.createdAt),
        unreadMessages: 0,
        totalMessages: 0,
        tags: note.tags || [],
        advisor,
        order: !Number.isInteger(note.order) ? 0 : note.order,
        included: noteIdsIncluded.includes(note.id),
      }

      return hydrated
    })
  )
}

export const getScratch = async (scratchId: any) => {
  // FIXME: should be a different method. There are no way to force a refresh of the scratch
  const localScratch = global.data.appt.scratchNotes.find(
    (scratch) => scratch.id === Number(scratchId)
  )
  if (localScratch) {
    return localScratch
  }

  global.app.loading = 'Fetching Handwritten Note'

  try {
    const scratch = await scratchService.getScratch(scratchId)
    global.data.appt.scratchNotes.push(scratch)

    return scratch
  } catch (error) {
    msg.error(['handwritten note', 'fetching'])
    captureExceptionSilently(error, {
      message: 'getScratch',
      data: { scratchId },
    })
  } finally {
    global.app.loading = false
  }
}
