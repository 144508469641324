import { notification } from 'antd';

notification.config({
  placement: 'topRight',
  maxCount: 1,
  duration: 3,
});

class Msg {
  lastMsg = null;
  key = null;
  // success('Well Done', 3)
  // success('Well Done', 'You did good', 3)
  success(message: any, description: any, duration: any) {
    return this._msg('success', arguments);
  }

  /**
   * Shows a wser-facing error message
   * @param {String[] | String} message if Array, will use the generic wording, if string it will use that
   * @param {Number} duration
   */
  error(message: any, description = '', duration = 6) {
    if (Array.isArray(message)) {
      return this._msg('error', [this._genericErrorMsg(message, ''), description, duration]);
    }
    return this._msg('error', arguments);
  }

  info(message: any, description: any, duration: any) {
    return this._msg('info', arguments);
  }

  warning(message: any, description: any, duration = 6) {
    return this._msg('warning', arguments);
  }

  _genericErrorMsg(article: any, verb: any, adjective = 'the') {
    return `There was a problem ${verb} ${adjective} ${article}, we'll be taking a look to see what happened :-)`;
  }

  _msg(type: any, args: any) {
    let [message, description, duration] = args;
    let placement = 'topRight';

    if (typeof description === 'number') {
      duration = description;
      description = null;
      if (typeof duration === 'string') {
        placement = duration;
      }
    }

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    notification[type]({
      className: `p360-message ${type}`,
      description,
      placement,
      duration,
      message,
    });
  }
}

export const msg = new Msg();
