export const PERMS_KEYS = {
  AI_WRITER_METHODS: 'ui.route.ai-writer-methods',
  CONTACT_V2: 'ui.route.settings.contact-v2',
  CONTACT: 'ui.route.contact',
  DATA_TOOLS: 'ui.route.settings.datatools',
  DOCUMENTS: 'ui.route.documents',
  EDIT_TOPICS: 'ui.app.settings.edit-topics-enabled',
  FILE: 'ui.route.file',
  INTERCOM: 'ui.app.settings.intercom-enabled',
  PULSE_RECORDER: 'ui.app.settings.pulse-recorder',
  PULSE_RECORDER_CALL_BOT: 'ui.app.settings.capturegenius.bot',
  PULSE_RECORDER_UPLOAD: 'ui.app.settings.capturegenius.upload',
  PULSE_RECORDER_RECORD: 'ui.app.settings.capturegenius.recorder',
  QUICK_CAPTURE_NOTE: 'ui.route.quick-capture-note',
  READ_RECEIPTS: 'ui.app.settings.read-receipts',
  REPHRASE: 'ui.route.workspace.rephrase',
  SMARTFIELDS: 'ui.route.smartfields',
  SUBTOPICS: 'ui.route.settings.subtopics',
  TEMPLATE: 'ui.route.template',
  VIEW360_ANALYTICS: 'ui.route.360-analytics',
  VIEW360_DATA_COLUMN: 'ui.route.360-data-columns',
  VIEW360: 'ui.route.360view',
  LIBRARY: 'ui.route.library',
  WORKSPACE_AGENDA_ACTION: 'ui.route.workspace.agenda-action',
  WORKSPACE_AIWRITER_ACTION: 'ui.route.workspace.aiwriter-action',
  WORKSPACE_ALL_NOTES_ACTION: 'ui.route.workspace.all-note-action',
  WORKSPACE_COMMUNICATIONS_HISTORY: 'ui.route.workspace.communications-history',
  WORKSPACE_NOTES_ACTION: 'ui.route.workspace.notes-action',
  WORKSPACE_PAST_COMMUNICATIONS_ACTION:
    'ui.route.workspace.past-communications-action',
  WORKSPACE_SUMMARY_ACTION: 'ui.route.workspace.summary-action',
  CAPTURE_GENIUS: 'ui.route.capture-genius',
  CALENDAR_CONNECTION: 'ui.app.settings.calendar-connection',
  EMAIL_SETTINGS: 'ui.route.settings.smtp',
  EMAIL_CONNECTION: 'ui.route.settings.email',
  EMAIL_WET_SIGNATURE: 'ui.route.settings.wet',
  EDIT_TAGS: 'ui.route.settings.tags',
  DATA_IMPORT: 'ui.route.settings.import',
  INTEGRATIONS: 'ui.route.settings.integrations',
  AUDIT: 'ui.route.settings.audit',
  PERMISSIONS: 'ui.route.settings.permissions',
  ORGANIZATION_SETTINGS: 'ui.route.settings.practice',
  COLLECTIONS: 'ui.route.settings.collections',
  SMARTFIELDS_INTEGRATION: 'ui.route.settings.smartfields-integration',
  CAPTURE_GENIUS_SCHEDULE_DELETION: 'ui.app.settings.capturegenius.schedule-deletion',
}
