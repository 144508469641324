import { SmartFieldAtomAttrs } from "lib/remirror/extensions/smartFieldAtom/component";
import { ConnectionProvider } from "stores/connections";
import { SmartFieldKind } from "stores/smartfields/constants";
import { smartfieldFactory as factory } from "stores/smartfields/factory";
import { appendSid, removeSid } from "stores/smartfields/utils";
import { v4 as uuidv4 } from "uuid";
import Str from "../../../stores/util/str.util";
import { formToMention, mentionToInput } from "./smartfield.convertors";
import { singleUseRegex } from "./smartfield.regex";
import { SmartField, SmartFieldMentionAttrs, SmartFieldType } from "./types";
import { SmartFieldConfig, SmartFieldIntegrationConfig } from "./types/data";
import { SmartFieldFormValues } from "./types/form";
import { HolistiplanConfig } from "./types/integration";

export const smartFieldtypes = [
  "text",
  "number",
  "date",
  "list",
  "integration",
] as const;

export const smartFieldIntegrationTypes: ConnectionProvider[] = [
  "precisefp",
  "holistiplan",
  "riskalyze",
  "wealthbox-connector",
];

//==========================================================================

const emptySmartField = (): Partial<SmartField> => ({
  id: uuidv4(),
  label: undefined,
  type: undefined,
  reuse: false,
  config: undefined,
});

/**
 * Check if the Smartfield is a SingleUse one.  If it doesn't have a UUIDv4 id, it's already been saved
 * as a reusable one, so we don't want to create a new one if it exists in the db
 *
 * - Single use Smartfields have a UUIDv4 id
 * - Reusable Smartfields have 'smartfields' table primary key as id
 *
 * @param mentionAttrs
 * @returns
 */
export const isSingleUse = (attrs: SmartFieldMentionAttrs): boolean => {
  const isSingle = singleUseRegex.test(attrs.id);

  return isSingle === true;
};

export const isReusable = (reuse: "true" | "false"): boolean => {
  return reuse === "true";
};

/**
 * Create, Update, Remove Reusable Smartfields -------
 *
 * @param parsedValues
 * @param mentionAttrs
 * @returns
 */
export const saveSmartField = async (
  formValues: SmartFieldFormValues
): Promise<SmartFieldMentionAttrs> => {
  const mention = formToMention(formValues);

  // 1. if isSingleUse && isReusable - create new smartfield
  if (isSingleUse(mention) && isReusable(mention.reuse)) {
    const input = mentionToInput(mention, formValues.type);
    const smartfield = await factory.create(input);

    mention.config = JSON.stringify(smartfield.config);
    mention.id = appendSid(mention.id, smartfield.id);
  }

  // 2. if isSingleUse && !isReusable - do nothing

  // 3. if !isSingleUse && isReusable - update old smartfield
  else if (!isSingleUse(mention) && isReusable(mention.reuse)) {
    const input = mentionToInput(mention, formValues.type);
    const smartfield = await factory.update(mention.id, input);

    mention.config = JSON.stringify(smartfield.config);
    mention.id = appendSid(mention.id, smartfield.id);
  }

  // 4. if !isSingleUse && !isReusable - remove old and convert to single use
  else if (!isSingleUse(mention) && !isReusable(mention.reuse)) {
    await factory.remove(mention.id);
    mention.id = removeSid(mention.id);
  }

  return mention;
};

/**
 * @param config
 * @returns
 */
export const formatTooltip = (smartfield?: SmartField) => {
  const prefix = smartfield?.reuse ? "Reusable " : "";

  if (smartfield?.config?.type === "integration") {
    const intgrConfig =
      smartfield.config as SmartFieldConfig<SmartFieldIntegrationConfig>;

    if (intgrConfig.provider === "holistiplan") {
      const holistConfig = intgrConfig as SmartFieldConfig<
        SmartFieldIntegrationConfig<HolistiplanConfig>
      >;

      return `${prefix}${Str.startCase(intgrConfig.provider)}: ${Str.startCase(
        holistConfig.field
      )}`;
    } else {
      return `${Str.startCase(intgrConfig.provider)}: ${
        smartfield?.config?.label
      }`;
    }
  }

  const smartFieldType = smartFieldtypes.find(
    (type) => type === smartfield?.config?.type
  );

  return `${prefix}${Str.startCase(smartFieldType)}: ${
    smartfield?.config?.label
  }`;
};

const formatLabel = (mention: SmartFieldAtomAttrs) => {
  if (mention.label === "Add SmartField") return mention.label;

  return mention.label;
};

const formatKind = (mention: SmartFieldAtomAttrs) => {
  if (mention.kind === SmartFieldKind.CUSTOM) {
    return "smartfield";
  }

  if (mention.kind === SmartFieldKind.SMARTFIELD) {
    const { type } = JSON.parse(
      mention.config ?? JSON.stringify({ type: "text" })
    );

    switch (type as SmartFieldType) {
      case "integration":
        return "dynamic";
      default:
        return Str.startCase(type);
    }
  }

  return "auto";
};
