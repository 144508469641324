import { addFonts, fixLatoFont } from 'styles/fonts/outputs/cdn_fonts'
import { fontsAndFallbacks } from 'styles/fonts/outputs/fonts_data'
import { editorPrintFromBrowserRules } from '../styles/styles'
import { getPulse360Domain } from 'constants/domain'
import { BODY } from 'components/legacy/froala/utils'

const cdn = getPulse360Domain()

function addFallbackFonts(html: string) {
  if (!html) {
    return html
  }

  const output = fontsAndFallbacks.reduce((acum, { fallback, searchValue }) => {
    return acum.replace(
      new RegExp(`font-family:\\s?${searchValue}`, 'g'),
      `font-family:${searchValue}, ${fallback}`,
    )
  }, html)

  return output
}

function fixParagraph(html: string) {
  const proseMirrorTrailingBreak = html.replaceAll(
    /<br><\/p>/gm,
    '<br /><br /></p>',
  )
  return proseMirrorTrailingBreak.replaceAll(
    /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/gm,
    '<p style=""><br /></p>',
  )
}

function addChecklist(html: string) {
  function createCheckbox(checked: boolean) {
    const checkbox = document.createElement('img')
    checkbox.classList.add('task-list-item-checkbox')
    checkbox.src = checked
      ? `${cdn}/static/images/checkbox-checked.png`
      : `${cdn}/static/images/checkbox-unchecked.png`

    return checkbox
  }

  const body = BODY(html)
  const lists = body.querySelectorAll('ul[data-task-list')
  for (const list of lists) {
    list.classList.add('task-list')

    const items = list.querySelectorAll('li[data-task-list-item')

    for (const item of items) {
      const checked = item.hasAttribute('data-checked')
      const wrapper = document.createElement('div')
      wrapper.classList.add('task-list-item-wrapper')
      wrapper.innerHTML = item.innerHTML
      item.innerHTML = wrapper.outerHTML
      item.prepend(createCheckbox(checked))
    }
  }

  return body.innerHTML
}

const html_tmpl = (html: string, includeFonts = true, addTitle = true) => {
  const body = addChecklist(fixParagraph(addFallbackFonts(html)))
  return `
  <!DOCTYPE html>
    <html>
    <head>
      ${addTitle ? '<title>Pulse360</title>' : ''}
      ${fixLatoFont()}
      ${includeFonts ? addFonts(html) : ''}
      <style>
      ${editorPrintFromBrowserRules}
      </style>
    </head>
    <body>
      ${body}
    </body>
  </html>
  `
}

export default html_tmpl
