import { Advisor, Practice } from 'types/graphql'

function toBool(input: any) {
  return input ? Boolean(JSON.parse(input)) : false
}

/**
 * On Production, we don't want to show Pulse360 admin users to our customers
 * If:
 *   - Is Production, and
 *   - Is not a test account, and
 *   - I'm not an admin user
 *   - Is not a test account
 *
 * Then, filter out all admin advisors, so that they don't appear in the app
 */
export const filterAdvisors = (
  { domain, members = [] }: Practice,
  advisor: Advisor
): [number, Advisor][] => {
  const isAdminUser = !!(advisor && advisor.isAdmin)
  const isTestAccount = domain?.startsWith('test_')
  const isProduction = import.meta.env.VITE_ENV === 'prod'

  let practiceMembers = [...members]

  if (isProduction && !isTestAccount && !isAdminUser) {
    practiceMembers = practiceMembers.filter((member) => !toBool(member.isAdmin))
  }

  return practiceMembers.map((member) => [Number(member.id), member])
}
