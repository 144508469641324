import { FC } from 'react';
import { ConnectionIcon } from '../connection.interfaces'

export const WealthboxIcon: FC<ConnectionIcon> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 246 280" // Adjust based on the SVG's original dimensions
    width="100%" // Set width to 100% to fill the parent container's width
    height="100%" // Set height to 100% to fill the parent container's height
    preserveAspectRatio="xMidYMid meet" // Keeps the aspect ratio
  >
    <title>{'Wealthbox Icon/1 Blue'}</title>
    <defs>
      <path
        id="a"
        d="M115.314 2.518a15.356 15.356 0 0 1 15.374 0l107.625 62.136A15.373 15.373 0 0 1 246 77.969v124.27c0 5.493-2.93 10.568-7.688 13.315l-107.623 62.14a15.38 15.38 0 0 1-15.376 0L7.686 215.554A15.375 15.375 0 0 1 0 202.239V77.969c0-5.493 2.93-10.57 7.688-13.315L115.314 2.518Zm3.844 242.071a7.68 7.68 0 0 0 3.844 1.029 7.683 7.683 0 0 0 3.842-1.029l84.562-48.82a7.689 7.689 0 0 0 3.844-6.656V91.48a7.687 7.687 0 0 0-3.844-6.659l-84.562-48.816a7.674 7.674 0 0 0-7.688 0L34.594 84.822a7.683 7.683 0 0 0-3.844 6.66v97.63a7.684 7.684 0 0 0 3.844 6.658l84.564 48.82Zm49.967-77.663V78.151l28.828 16.64a3.847 3.847 0 0 1 1.922 3.33v84.33a3.844 3.844 0 0 1-1.922 3.33l-28.828 16.644-46.123-26.631-46.127 26.631-28.828-16.643a3.844 3.844 0 0 1-1.922-3.33v-84.33c0-1.375.732-2.643 1.922-3.331l28.828-16.64v88.777l46.127-26.631 46.123 26.63Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="#0767D0" fillRule="nonzero" mask="url(#b)">
        <path d="M0 0h246v280H0z" />
      </g>
    </g>
  </svg>
)
