import {
  extension,
  getMarkRanges,
  getTextSelection,
  Helper,
  helper,
  PrimitiveSelection,
} from '@remirror/core'
import {
  palette,
  TextColorExtension,
  TextColorOptions,
} from '@remirror/extension-text-color'

/**
 * Wraps text with a styled span using the color css property. The name of the wrapper tag should be configurable.
 */
// @ts-ignore
@extension<TextColorOptions>({
  defaultOptions: {
    defaultColor: '',
    palette,
  },
  staticKeys: ['defaultColor'],
})
export class TextColorCustomExtension extends TextColorExtension {
  /**
   * Get the Text Color at the current selection (or provided custom selection).
   * Returns the text color in the non-empty selection
   */
  @helper()
  getTextColorForSelection(
    position?: PrimitiveSelection,
  ): Helper<string | null> {
    const state = this.store.getState()
    const selection = getTextSelection(position ?? state.selection, state.doc)
    const [range] = getMarkRanges(selection, this.type)

    if (range) {
      return range.mark.attrs.color
    }

    return null
  }
}
