import { makeAutoObservable, observable } from 'mobx'
import type { Advisor } from 'types/graphql'

export interface CaptureGeniuesConfig {
  scheduleDeletion: {
    enabled: boolean
    days: number
    sources: string
  }
}

export interface PracticeConfig {
  captureGenius: CaptureGeniuesConfig
}

class Practice {
  statuses = []
  types = []
  members = observable.map<number, Advisor>([])
  id = 0
  name = ''
  type = ''
  userPool = ''
  domain = ''
  desc = ''
  phone = ''
  logoUrl = ''
  bucket = ''
  address1 = ''
  address2 = ''
  defaultCc = ''
  defaultBcc = ''
  secureFieldPrefix = ''
  city = ''
  state = ''
  country = ''
  locale = ''
  tz = ''
  zip = ''
  enabled = false
  permissions = []
  planId = null
  defaultReadReceipt = false
  createdAt = ''
  legalBucket = false
  syncSummaryCrm = false
  config?: PracticeConfig

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
}

export const practiceStore = new Practice()
