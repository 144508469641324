import styled from 'styled-components'
import { Layout } from 'antd'
import { styles } from 'styles'

export default styled(Layout)`
  height: 100%;
  &:not(.centered) {
    background: ${styles.colors.extraLightGrey};
  }

  .centered-extra {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  &.centered {
    border-bottom: 2px solid #53aa28;
    border-top: 2px solid #53aa28;
    // background-color: #fbfcff;
    background-color: rgba(251, 252, 255, 0.1);
  }

  @keyframes rightToLeft {
    from {
      right: 2%;
    }
    to {
      right: 95%;
    }
  }

  .c-layout__sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    -webkit-box-shadow: 1px 0 4px rgba(0, 21, 41, 0.08);
    max-width: ${styles.sideMenuWidth}px!important;
    min-width: ${styles.sideMenuWidth}px!important;
    width: ${styles.sideMenuWidth}px!important;
    left: 0;

    .ant-layout-sider-trigger {
      width: ${styles.sideMenuWidth}px!important;
    }

    &.collapsed {
      max-width: ${styles.sideMenuCollapsedWidth}px!important;
      min-width: ${styles.sideMenuCollapsedWidth}px!important;
      width: ${styles.sideMenuCollapsedWidth}px!important;
      .ant-layout-sider-trigger {
        width: ${styles.sideMenuCollapsedWidth}px!important;
      }
    }
  }

  .c-layout__main {
    margin-left: ${styles.sideMenuWidth}px!important;
    transition: all 0.2s;
    height: 100vh;

    &.collapsed {
      margin-left: ${styles.sideMenuCollapsedWidth}px!important;
    }

    .c-layout__header {
      background: ${styles.colors.white};
      height: ${styles.headerHeight}px;
      border-bottom: 1px solid ${styles.colors.border};
      -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      justify-content: space-between;
      padding: 0 24px 0 0;
      z-index: 1;
      display: flex;

      .header-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .toggle {
          width: 60px;
          height: 100%;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            color: ${styles.colors.lightBlack};
            background: ${styles.colors.selectedBackground};
          }
          .anticon {
            color: ${styles.colors.black};
            cursor: pointer;
          }
        }
        h2,
        h3 {
          color: ${styles.colors.black}!important;
          margin-bottom: 0;
          &.route {
            color: ${styles.colors.reallyLightBlack}!important;
          }
        }
      }

      .ant-menu {
        line-height: 64px;
      }
    }
    .c-layout__content {
      margin: ${styles.padding}px;
      height: 100%;

      &.rightSider {
        .ant-layout-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          .header {
            border-bottom: 1px solid ${styles.colors.border};
            padding: ${styles.padding}px;
            &.tight {
              padding: 0;
            }
            background: ${styles.colors.white};
          }
          .children {
            display: flex;
            flex-direction: column;
            margin: ${styles.padding}px;
            flex: 1;
          }
        }
        .ant-layout-sider {
          border-left: 1px solid ${styles.colors.border};
          background: ${styles.colors.white};
          &:not(.tight) {
            padding: ${styles.padding}px;
          }
        }
        display: flex;
        flex-direction: column;
        margin: 0;
      }
    }
  }

  .c-layout__content {
    &.flex {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
`
