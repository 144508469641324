import { NoteTag } from 'lib/remirror/extensions/noteReplacer/NoteReplacer.interfaces'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

export const useSubTopics = () => {
  const [subTopics, setSubTopics] = useState<NoteTag[]>([])

  useEffect(() => {
    return autorun(() => {
      // @ts-ignore
      setSubTopics(global.tags.subtopics)
    })
  }, [])

  return {
    subTopics,
  }
}
