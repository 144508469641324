import {
  extension,
  Helper,
  helper,
  isEmptyArray,
  PrimitiveSelection,
  ProsemirrorNode,
} from '@remirror/core'
import { NodeFormattingExtension } from '@remirror/extension-node-formatting'
import { gatherNodes } from './nodeFormating.util'

const DEFAULT_ALIGN = 'left'
const DEFAULT_LINE_HEIGHT = 1.5

/**
 * Support consistent formatting of nodes within your editor.
 */
// @ts-ignore
@extension({})
export class NodeFormattingCustomExtension extends NodeFormattingExtension {
  /**
   * Get the alignment at the current selection (or provided custom selection).
   * Returns the alignment in the non-empty selection
   */
  @helper()
  getAlignForSelection(position?: PrimitiveSelection): Helper<string | null> {
    const state = this.store.getState()

    const gatheredNodes = gatherNodes(
      state,
      this.store.nodeTags.formattingNode,
      this.options.excludeNodes,
    )

    if (isEmptyArray(gatheredNodes)) {
      return DEFAULT_ALIGN
    }

    for (const nodeWithPos of gatheredNodes) {
      const getNodeTextAlignment = ({ node }: { node: ProsemirrorNode }) => {
        const nodeTextAlignment = node.attrs.nodeTextAlignment as
          | null
          | undefined
          | string
        if (!nodeTextAlignment) {
          return undefined
        }

        return nodeTextAlignment
      }
      const nodeTextAlignment = getNodeTextAlignment(nodeWithPos)

      if (!nodeTextAlignment) {
        continue
      }

      return nodeTextAlignment
    }

    return DEFAULT_ALIGN
  }

  /**
   * Get the line height at the current selection (TODO: or provided custom selection).
   * Returns the line height in the non-empty selection
   */
  @helper()
  getLineHeightForSelection(
    position?: PrimitiveSelection,
  ): Helper<number | null> {
    const state = this.store.getState()

    const gatheredNodes = gatherNodes(
      state,
      this.store.nodeTags.formattingNode,
      this.options.excludeNodes,
    )

    if (isEmptyArray(gatheredNodes)) {
      return DEFAULT_LINE_HEIGHT
    }

    for (const nodeWithPos of gatheredNodes) {
      const getNodeLineHeight = ({ node }: { node: ProsemirrorNode }) => {
        const nodeLineHeight = node.attrs.nodeLineHeight as
          | null
          | undefined
          | number
        if (!nodeLineHeight) {
          return undefined
        }

        return nodeLineHeight
      }
      const nodeLineHeight = getNodeLineHeight(nodeWithPos)

      if (!nodeLineHeight) {
        continue
      }

      return nodeLineHeight
    }

    return DEFAULT_LINE_HEIGHT
  }
}
