import { Suspense, lazy, ComponentType, ComponentProps } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { styles } from 'styles';

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
`;

interface LoadingProps {
  error?: Error;
  offset?: number;
  loader?: () => Promise<{ default: ComponentType<any> }>;
}

export const Loading = ({ error, offset, loader }: LoadingProps) => {
  if (error) throw error;

  console.log('RouteLoader:', loader);

  const horizontalOffset = offset ? styles.sideMenuCollapsedWidth : 0;
  return (
    <Container className="route-loader">
      <Spin size="large" style={{ marginLeft: horizontalOffset }} />
    </Container>
  );
};

export const Lazy = <T extends ComponentType<any>>(loader: () => Promise<{ default: T }>) => {
  const Component = lazy(loader);

  return (props: ComponentProps<T>) => (
    <Suspense fallback={<Loading offset={0} loader={loader} />}>
      <Component {...props} />
    </Suspense>
  );
};
