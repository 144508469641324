import { SmartFieldAtomAttrs } from 'lib/remirror/extensions/smartFieldAtom/component'
import { omit, pick } from 'lodash'
import { SmartFieldKind } from 'stores/smartfields/constants'
import Str from 'stores/util/str.util'
import { Smartfield as SmartFieldGQL, SmartfieldInput } from 'types/graphql'
import { dateConfigParser, dateFormParser } from './date/parser'
import {
  integrationConfigParser,
  integrationFormParser,
} from './integration/parser'
import { listConfigParser, listFormParser } from './list/parser'
import { numberConfigParser, numberFormParser } from './number/parser'
import { textConfigParser, textFormParser } from './text/parser'
import { SmartField, SmartFieldMentionAttrs, SmartFieldType } from './types'
import { NoConfig, SmartFieldAllConfig, SmartFieldConfig } from './types/data'
import {
  NoFormValues,
  SmartFieldAllFormValues,
  SmartFieldFormValues,
} from './types/form'

// Convertors ========================================================

// Atom to ...

export const atomToSmartfield = (atom: SmartFieldAtomAttrs): SmartField => {
  const { id, label: lbl, config, reuse } = atom

  const label = lbl.replace(/(<([^>]+)>)/gi, '')
  const cfg = Str.isJSON(config) ? JSON.parse(config) : config

  if (cfg.type === 'string') {
    cfg.type = 'text'
  }

  return {
    id,
    label,
    type: cfg?.type,
    reuse: reuse === 'true',
    config: { ...cfg, label },
  }
}

// Config to ...

export const configToForm = <
  TConfig extends SmartFieldAllConfig = NoConfig,
  TFormValues extends SmartFieldAllFormValues = NoFormValues,
>(
  config: SmartFieldConfig<TConfig>,
): TFormValues => {
  let formValues: Partial<TFormValues> = {}

  switch (config.type) {
    case 'integration':
      formValues = dynamicConfigToForm(config) as TFormValues
      break
    default:
      formValues = standardConfigToForm(config) as TFormValues
      break
  }

  return formValues as TFormValues
}

const standardConfigToForm = <
  TConfig extends SmartFieldAllConfig = NoConfig,
  TFormValues extends SmartFieldAllFormValues = NoFormValues,
>(
  config: SmartFieldConfig<TConfig>,
): TFormValues => {
  let formValues: Partial<TFormValues> = {}

  switch (config.type) {
    case 'date':
      formValues = dateConfigParser(config) as TFormValues
      break
    case 'number':
      formValues = numberConfigParser(config) as TFormValues
      break
    case 'list':
      formValues = listConfigParser(config) as TFormValues
      break
    case 'text':
      formValues = textConfigParser(config) as TFormValues
      break
  }

  return formValues as TFormValues
}

const dynamicConfigToForm = <
  TConfig extends SmartFieldAllConfig = NoConfig,
  TFormValues extends SmartFieldAllFormValues = NoFormValues,
>(
  config: SmartFieldConfig<TConfig>,
): TFormValues => {
  let intgrValues: Partial<TFormValues> = integrationConfigParser(
    config,
  ) as TFormValues

  const dateValues = dateConfigParser(config) as TFormValues
  const numberValues = numberConfigParser(config) as TFormValues

  return {
    ...intgrValues,
    ...dateValues,
    ...numberValues,
  } as TFormValues
}

/**
 * Converts flat form values to nested SmartFieldConfig
 * @param values
 * @returns
 */
export const formToConfig = <
  F extends SmartFieldAllFormValues = NoFormValues,
  C extends SmartFieldAllConfig = NoConfig,
>(
  values: SmartFieldFormValues<F>,
): SmartFieldConfig<C> => {
  let config: SmartFieldConfig = pick(values, ['label', 'type'])

  switch (values?.type) {
    case 'integration':
      config = dynamicFormToConfig(values, config)
      break
    default:
      config = standardFormToConfig(values, config)
      break
  }

  return config as SmartFieldConfig<C>
}

const standardFormToConfig = <
  F extends SmartFieldAllFormValues = NoFormValues,
  C extends SmartFieldAllConfig = NoConfig,
>(
  values: SmartFieldFormValues<F>,
  config: SmartFieldConfig,
): SmartFieldConfig<C> => {
  switch (values?.type) {
    case 'date':
      config = dateFormParser(values, config)
      break
    case 'number':
      config = numberFormParser(values, config)
      break
    case 'list':
      config = listFormParser(values, config)
      break
    case 'text':
      config = textFormParser(values, config)
      break
  }

  return config as SmartFieldConfig<C>
}

const dynamicFormToConfig = <
  F extends SmartFieldAllFormValues = NoFormValues,
  C extends SmartFieldAllConfig = NoConfig,
>(
  values: SmartFieldFormValues<F>,
  config: SmartFieldConfig,
): SmartFieldConfig<C> => {
  // apply date/number formatting to integration values
  config = integrationFormParser(values, config)
  config = dateFormParser(values, config)
  config = numberFormParser(values, config)

  return config as SmartFieldConfig<C>
}

export const formToMention = <F extends SmartFieldAllFormValues = NoFormValues>(
  formValues: SmartFieldFormValues<F>,
): SmartFieldMentionAttrs => {
  const { id, label, reuse } = formValues

  const cfg = formToConfig({ ...formValues, label })

  return {
    id,
    label,
    kind: SmartFieldKind.SMARTFIELD,
    displayMode: 'inline',
    reuse: reuse ? 'true' : 'false',
    config: JSON.stringify(cfg),
  }
}

export const formToSmartField = (
  formValues: SmartFieldFormValues,
  attrs = {},
): SmartField => {
  const { id, label, type, reuse } = formValues

  return {
    ...attrs,
    id,
    type,
    reuse,
    label,
    config: formToConfig({ ...formValues, label }),
  }
}

// GQL to...

export const gqlToInput = (smartfield: SmartFieldGQL): SmartfieldInput => {
  const { label, type, config } = smartfield

  const cfg = JSON.parse(config)

  return {
    label,
    type,
    config: JSON.stringify({ ...cfg, label, type }),
  }
}

export const gqlToMention = (
  smartfield: SmartFieldGQL,
): SmartFieldMentionAttrs => {
  const { id, label, type, config } = smartfield

  const cfg = JSON.parse(config)

  return {
    id: id as string,
    label,
    kind: SmartFieldKind.SMARTFIELD,
    displayMode: 'inline',
    reuse: 'true',
    config: JSON.stringify({ ...cfg, label, type }),
  }
}

export const gqlToSmartField = (gql: SmartFieldGQL): SmartField => {
  const config = JSON.parse(gql.config ?? {})

  return {
    id: `${gql.id}`,
    type: gql.type as SmartFieldType,
    label: gql.label,
    reuse: true,
    config,
    deletedAt: gql.deletedAt,
    updatedAt: gql.updatedAt,
    createdAt: gql.createdAt,
  }
}

// Mention to...

/**
 * @param smartfield
 * @returns
 */
export const mentionToSmartField = (
  mention: SmartFieldMentionAttrs,
): SmartField => {
  const { id, mentionAtomId, config, reuse, label } =
    mention as SmartFieldMentionAttrs

  const cfg = Str.isJSON(config) ? JSON.parse(config) : config

  return {
    id: id ?? mentionAtomId,
    label,
    type: cfg?.type,
    reuse: reuse === 'true',
    config: { ...cfg, label },
  }
}

/**
 * @param smartfield
 * @returns
 */
export const mentionToForm = <
  TFormValues extends SmartFieldAllFormValues,
  TConfig extends SmartFieldAllConfig = NoConfig,
>(
  mention: SmartFieldMentionAttrs,
): SmartFieldFormValues<TFormValues> => {
  const { id, mentionAtomId, label, config, reuse } =
    mention as SmartFieldMentionAttrs

  const cfg = Str.isJSON(config) ? JSON.parse(config) : config
  const formValues = configToForm<TConfig, TFormValues>(cfg)

  return {
    id: id ?? mentionAtomId,
    label,
    type: cfg?.type,
    reuse: reuse === 'true',
    ...formValues,
  }
}

/**
 * @param mentionAttrs
 * @param type
 * @returns
 */
export const mentionToInput = (
  mentionAttrs: SmartFieldMentionAttrs,
  type: string,
): SmartfieldInput => {
  const { label, config, ...rest } = omit(mentionAttrs, [
    'id',
    'kind',
    'reuse',
    'displayMode',
    'header',
    'name',
    'tags',
  ]) as SmartFieldMentionAttrs

  const oldCfg = JSON.parse(config)
  const cfg = JSON.stringify({ type, label, ...oldCfg, ...rest })

  return {
    label,
    type,
    config: cfg,
  }
}

// Smartfield to ...

export const smartfieldToGQL = (smartfield: SmartField): SmartFieldGQL => {
  const label = Str.startCase(smartfield.label)
  const config = JSON.stringify({ ...(smartfield.config ?? {}), label })

  return {
    __typename: 'Smartfield',
    id: smartfield.id,
    label,
    type: smartfield.type,
    config,
  }
}

export const smartfieldToForm = (
  smartfield: SmartField,
): SmartFieldFormValues => {
  const { id, label, type, reuse, config } = smartfield
  return {
    id: id,
    label,
    type,
    reuse,
    ...omit(config, ['lable', 'type']),
  }
}

export const smartfieldToMention = (
  smartfield: SmartField,
): SmartFieldMentionAttrs => {
  const { id, label, reuse, config } = smartfield

  return {
    id: id,
    label,
    kind: SmartFieldKind.SMARTFIELD,
    displayMode: 'inline',
    reuse: reuse ? 'true' : 'false',
    config: JSON.stringify({ ...config, label }),
  }
}

export const smartfieldToInput = (smartfield: SmartField): SmartfieldInput => {
  const label = Str.startCase(smartfield.label)
  const config = JSON.stringify({ ...(smartfield.config ?? {}), label })

  return {
    label,
    type: smartfield.type,
    config,
  }
}

//==========================================================================
