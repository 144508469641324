import DataError, { IsError } from 'helpers/dataError'
import * as apiService from 'lib/apollo/client'
import { omit } from 'lodash'
import {
  ChangePermissionPlanDocument,
  ChangePermissionPlanMutation,
  ChangePermissionPlanMutationVariables,
  ChangePlanDocument,
  ChangePlanMutation,
  ChangePlanMutationVariables,
  ClearPermissionsCacheDocument,
  ClearPermissionsCacheMutation,
  ClearPermissionsCacheMutationVariables,
  DefaultPermissionsDocument,
  DefaultPermissionsQuery,
  DefaultPermissionsQueryVariables,
  PermissionsByPlanDocument,
  PermissionsByPlanQuery,
  PermissionsByPlanQueryVariables,
  PlanDocument,
  PlanQuery,
  PlanQueryVariables,
  PlansDocument,
  PlansQuery,
  PlansQueryVariables,
  SavePlanDocument,
  SavePlanMutation,
  SavePlanMutationVariables,
} from 'types/graphql'

class PermissionService {
  async defaultPermissions() {
    const { defaultPermissions: resp } = await apiService.query<
      DefaultPermissionsQuery,
      DefaultPermissionsQueryVariables
    >(DefaultPermissionsDocument)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async plans() {
    const { plans: resp } = await apiService.query<PlansQuery, PlansQueryVariables>(PlansDocument)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async plan(id: number) {
    const { plan: resp } = await apiService.query<PlanQuery, PlanQueryVariables>(PlanDocument, {
      id,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return omit(resp, ['__typename'])
  }

  async permissionsByPlan(planId: number) {
    const { permissionsByPlan: resp } = await apiService.query<
      PermissionsByPlanQuery,
      PermissionsByPlanQueryVariables
    >(PermissionsByPlanDocument, {
      planId,
    })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async savePlan(payload: SavePlanMutationVariables) {
    const { savePlan: resp } = await apiService.mutate<SavePlanMutation, SavePlanMutationVariables>(
      SavePlanDocument,
      payload
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async changePermissionPlan(payload: ChangePermissionPlanMutationVariables) {
    const { changePermissionPlan: resp } = await apiService.mutate<
      ChangePermissionPlanMutation,
      ChangePermissionPlanMutationVariables
    >(ChangePermissionPlanDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async changePlan(payload: ChangePlanMutationVariables) {
    const { changePlan: resp } = await apiService.mutate<
      ChangePlanMutation,
      ChangePlanMutationVariables
    >(ChangePlanDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async clearPermissionsCache() {
    const { clearPermissionsCache: resp } = await apiService.mutate<
      ClearPermissionsCacheMutation,
      ClearPermissionsCacheMutationVariables
    >(ClearPermissionsCacheDocument, {})

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }
}

export default new PermissionService()
