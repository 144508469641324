import copy from 'copy-to-clipboard'
import { msg } from './msg'

type Options = Parameters<typeof copy>[1]

class ClipboardStore {
  onClipboard(value: string, options?: Options) {
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
    msg.info('Copied to Clipboard')
    copy(value, options)
  }

  onClipboardText(value: string) {
    this.onClipboard(value, { format: 'text/plain' })
  }

  onClipboardHTML(value: string) {
    this.onClipboard(value, { format: 'text/html' })
  }
}

export default ClipboardStore
