import { captureExceptionSilently } from "helpers/sentry";
import { makeObservable, observable } from "mobx";
import { PreciseFpAccount, PreciseFpTemplate } from "../types/graphql";
import {
  createEngagement,
  listAccounts,
  listTemplates,
} from "./services/precisefp.service";

const PRECISEFP_RECIPIENT_TYPE = {
  CLIENT: "client",
  COCLIENT: "coclient",
};

class PreciseFPStore {
  showModal = null;
  _accounts: PreciseFpAccount[] = [];
  _templates: PreciseFpTemplate[] = [];

  constructor() {
    makeObservable(this, {
      showModal: observable,
      _templates: observable,
      _accounts: observable,
    });
  }

  createEngagement = async ({
    templateId,
    accountId,
    emailReminders,
    emailCompleted,
    recipientSecondary,
    auth,
  }: {
    templateId: string;
    accountId: number;
    emailReminders: boolean;
    emailCompleted: boolean;
    recipientSecondary: any;
    auth: any;
  }) => {
    const engagementArgs = {
      template_id: String(templateId),
      account_id: Number(accountId),
      recipient: PRECISEFP_RECIPIENT_TYPE.CLIENT,
      recipient_secondary: recipientSecondary,
      email_new: false,
      email_reminders: emailReminders,
      email_completed: emailCompleted,
      auth,
    };

    return createEngagement({ engagement: engagementArgs });
  };

  listAccounts = async () => {
    try {
      global.app.loading = true;
      this._accounts = await listAccounts({});
      return this._accounts;
    } catch (err) {
      captureExceptionSilently(err, {
        message: "preciseFPListAccounts",
        data: {},
      });
      throw err;
    } finally {
      global.app.loading = false;
    }
  };

  listTemplates = async () => {
    try {
      global.app.loading = true;
      this._templates = await listTemplates();
      return this._templates;
    } catch (err) {
      captureExceptionSilently(err, {
        message: "preciseFPListTemplates",
        data: {},
      });
      throw err;
    } finally {
      global.app.loading = false;
    }
  };

  searchAccount = async (query: string): Promise<PreciseFpAccount[]> => {
    try {
      global.app.loading = true;
      const result = await listAccounts({ query });
      return result;
    } catch (err) {
      captureExceptionSilently(err, {
        message: "preciseFPListAccounts",
        data: {},
      });
      throw err;
    } finally {
      global.app.loading = false;
    }
  };
}

export default PreciseFPStore;
