import Icon from '@ant-design/icons';
import { ComponentProps } from 'react';
import {
  MdAdminPanelSettings,
  MdBusiness,
  MdHome,
  MdOutlineAccountCircle,
  MdOutlineNotInterested,
  MdOutlinePeople,
  MdWidgets,
} from 'react-icons/md';

const iconType = {
  // Wealthbox
  Organization: MdBusiness,
  Person: MdOutlinePeople,
  Household: MdHome,
  Trust: MdAdminPanelSettings,
  // Others
  company: MdBusiness,
  contact: MdOutlinePeople,
  household: MdHome,
  lead: MdOutlineAccountCircle,
  other: MdWidgets,
  trust: MdAdminPanelSettings,
  not_connected: MdOutlineNotInterested,
};

export type ContactIconType = keyof typeof iconType;

type ContactIconProps = {
  type: ContactIconType;
} & ComponentProps<typeof Icon>;

export const ContactIcon = ({ type, ...props }: ContactIconProps) => {
  const IconComponent = iconType[type] ?? iconType.contact;
  return <Icon {...props} component={IconComponent} />;
};
