import { toTaskInput } from './task'
import { NoteCreationInput, NoteInput } from 'types/graphql'
import { isNote } from '@helpers/mappers/discriminators'
import { Note } from 'types/graphql'
import { toWorkflowInput } from './workflow'
import { toTagsInput } from './tags'
import { pick } from 'lodash'
import { toTextInput } from './content'
import { toContentInput } from './content'

/**
 *
 * @param note Note creation inputs are exclusively for creating new notes
 * @returns
 */
export const toNoteCreationInput = (note: Note): NoteCreationInput => {
  if (!isNote(note)) {
    throw new Error('Note is not a Note')
  }

  return {
    ...pick(note, [
      'content',
      'due',
      'file1',
      'file2',
      'file3',
      'isAgenda',
      'isPrivate',
      'isSummary',
      'order',
      'status',
      'text',
      'topic',
    ]),
    advisorId: Number(note.advisorId),
    appointmentId: Number(note.appointmentId),
    clientId: Number(note.clientId),
    content: toContentInput(note),
    status: note.status ?? 'Pending',
    text: toTextInput(note),
    tags: toTagsInput(note.tags),
    task: toTaskInput(note.task),
    workflow: toWorkflowInput(note.workflow),
    templateIncludedId: undefined,
  }
}

/**
 * Note inputs are exclusively for updating existing notes
 * @param note
 * @returns
 */
export const toNoteInput = (note: Partial<Note> & { id: number }): NoteInput => {
  return {
    ...pick(note, [
      'due',
      'file1',
      'file2',
      'file3',
      'isAgenda',
      'isPrivate',
      'isSummary',
      'order',
      'topic',
    ]),
    advisorId: Number(note.advisorId),
    appointmentId: Number(note.appointmentId),
    clientId: Number(note.clientId),
    content: toContentInput(note),
    id: note.id,
    status: note.status ?? 'Pending',
    tags: toTagsInput(note.tags),
    task: toTaskInput(note.task),
    text: toTextInput(note),
    workflow: toWorkflowInput(note.workflow),
  }
}
