import styled, { createGlobalStyle } from 'styled-components'
import colors, { logoColors } from './colors'
import media from './media'

import { CSSProperties } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './fonts/lato/lato.css'

export const styles = {
  sideMenuWidth: 100, // width of far left dark menu when expanded
  sideMenuCollapsedWidth: 60, // width of far left dark menu when collapsed

  leftSiderWidthSm: document.documentElement.clientWidth < 1200 ? 160 : 200,
  leftSiderWidth: document.documentElement.clientWidth < 1400 ? 180 : 240,
  leftSiderWidthLg: document.documentElement.clientWidth < 1200 ? 300 : 420,
  rightSiderWidth: 320,

  headerHeight: 64,
  padding: 16,
  logoColors,
  colors,
  fonts: {
    size: {
      huge: 28,
      large: 16,
      medium: 14,
      normal: 12,
      small: 11,
    },
  },
  icons: {
    size: {
      large: 16,
      medium: 14,
      small: 10,
    },
  },
  media,
}

// @media only screen and (max-width : 1200px)
// @media only screen and (max-width : 992px)
// @media only screen and (max-width : 768px)

interface FlexRowColProps {
  justify?: CSSProperties['justifyContent']
  align?: CSSProperties['alignContent']
  direction?: 'row' | 'column' | (string & {})
  [prop: string]: any
}

export const FlexRow = styled.div<FlexRowColProps>`
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'center'};
  flex-direction: ${(p) => p.direction || 'row'};
  display: flex;
`

export const FlexCol = styled.div<FlexRowColProps>`
  justify-content: ${(props) => props.justify || 'flex-start'};
  flex-direction: column;
  display: flex;
`

export const GlobalStyle = createGlobalStyle`
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    overflow: hidden;
    color: #2e2728;
    height: 100vh;
    background: linear-gradient(${styles.colors.gray1}, ${styles.colors.gray3} 28%);

    & > div {
      height: 100%;
    }
  }

  h4 {
    font-weight: 500;
  }

  // Fill missing smartfields button animation
  .pulse {
    -webkit-animation: pulseAnimation 1s infinite;
    -moz-animation: pulseAnimation 1s infinite;
    -o-animation: pulseAnimation 1s infinite;
    animation: pulseAnimation 1s infinite;
  }
  @keyframes pulseAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .hlp-checklist-button {
    border-radius: 2px!important;
    height: 32px!important;
  }

  .remirror-theme {
    .remirror-editor-wrapper {
      padding-top: 0!important;

      .remirror-editor {
        box-shadow: none!important;

        border: 1px solid ${colors.gray5};
        border-radius: 0!important;
        cursor: text;

        &.inline {
          border: none;
          border-top: 1px solid ${colors.gray5};
          border-bottom: 1px solid ${colors.gray5};
          padding: 2px 8px !important;
        }

        &.padding {
          padding: 20px !important;
        }


        &.no-min-height {
          min-height: unset;
        }
      }
    }
  }

  .card-mode {
    border: 0;
    .remirror-editor-wrapper {
      border: 1px solid #EAEAEA;
      border-radius: 8px;
      padding: 12px !important;
      margin-top: unset;

      .remirror-editor {
        border: 0;
      }
    }
  }
}
`
