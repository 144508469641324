import { isTask, isTaskTemplate } from '@helpers/mappers/discriminators'
import { compact, pick } from 'lodash'
import { Maybe, Task, TaskInput, TaskTemplate, TaskTemplateInput } from 'types/graphql'
import { toDateString } from '../date'
import { isNumber } from 'remeda'

export const toTaskInput = (task?: Maybe<Task>): TaskInput | undefined => {
  if (!task) {
    return undefined
  }

  if (!isTask(task)) {
    throw new Error('Task is not a Task')
  }

  return {
    ...pick(task, [
      'completed',
      'completedAt',
      'createdBy',
      'customFields',
      'description',
      'name',
      'percentDone',
      'provider',
      'status',
      'updatedBy',
    ]),
    assignedTo:
      compact(task.assignedTo)
        ?.map((advisor) => advisor?.id)
        .filter(Boolean) ?? [],
    endDate: toDateString(task.endDate, true) as string,
    linkedTo:
      compact(task.linkedTo)
        ?.map((client) => client?.id)
        .filter(Boolean) ?? [],
    noteId: Number(task.noteId),
    startDate: toDateString(task.startDate, true) as string,
    status: task.status ?? 'pending',
  }
}

export const toTaskTemplateInput = (
  template?: Maybe<TaskTemplate>
): TaskTemplateInput | undefined => {
  if (!template) {
    return undefined
  }

  if (!isTaskTemplate(template)) {
    throw new Error('TaskTemplate is not a TaskTemplate')
  }

  return {
    ...pick(template, ['customFields', 'description', 'name', 'provider']),
    assignedTo: isNumber(template.assignedTo)
      ? [template.assignedTo]
      : (compact(template.assignedTo)
          ?.map((advisor) => advisor?.id)
          .filter(Boolean) ?? []),
    endDate: toDateString(template.endDate, true) as string,
    id: Number(template.id),
    startDate: toDateString(template.startDate, true) as string,
    summaryId: Number(template.summaryId),
  }
}
