import { Form, FormInstance } from 'antd'
import {
  RiskalyzeDataConfig,
  RiskalyzeQuestionaireConfig
} from 'components/drawers/Smartfields/types/integration'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConnection } from 'stores/connections/connection.hooks'
import {
  createQuestionnaire,
  findClients,
  getClient
} from 'stores/services/riskalyze.service'
import { smartfieldStore } from 'stores/smartfields'
import { RiskalyzeClient } from 'types/graphql'
import { ERRORS } from './riskalyze.errors'

export const useRiskalyzeQuestionaireData = (
  config: RiskalyzeQuestionaireConfig,
  form?: FormInstance,
  uid: string = 'uid'
) => {
  const [clients, setClients] = useState<RiskalyzeClient[]>([])
  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [generatingLink, setGeneratingLink] = useState<boolean>(false)

  const questionaireLink = Form.useWatch('questionaire_link', form)
  const questionaireLinkTitle = Form.useWatch('questionaire_link_title', form)

  const { connected } = useConnection('riskalyze')

  const onSelectClient = useCallback(() => {
    form?.setFieldValue('questionaire_invested', null)
    form?.setFieldValue('questionaire_link', null)
    setLoading(true)
  }, [clients, form])

  const onSelectInvested = useCallback(async () => {
    form?.setFieldValue('questionaire_link', null)
  }, [form, uid])

  const onGenerateLink = useCallback(async () => {
    global.bus.emit('DISABLE_SMARTFIELD_CHANGE', true)
    setGeneratingLink(true)

    const clientId = form?.getFieldValue('questionaire_contact')
    const amountInvested = form?.getFieldValue('questionaire_invested')

    const link = await createQuestionnaire({
      questionnaire: {
        amountInvested: amountInvested ? +amountInvested : undefined,
        riskalyzeClientId: clientId,
        type: config.questionaire_type
      }
    })

    global.bus.emit('DISABLE_SMARTFIELD_CHANGE', false)
    setGeneratingLink(false)

    if (link === 'skipped') {
      return
    }

    form?.setFieldValue('questionaire_link', link)
  }, [form, uid])

  useEffect(() => {
    if (questionaireLink) {
      let url = `${questionaireLink}`

      if (!isEmpty(questionaireLinkTitle)) {
        url = `<a href="${questionaireLink}" target="_blank">${questionaireLinkTitle}</a>`
      }

      // updates the remirror preview and stores values
      smartfieldStore.update({ [uid]: url })
      // sets the value to hidden antd form.item, this is used
      // to do the actual replacement when closing the modal
      form?.setFieldValue(uid, url)
    }
  }, [questionaireLink, questionaireLinkTitle, uid])

  useEffect(() => {
    if (!connected) {
      return setError(ERRORS.INTEGRATION_ERROR)
    }

    setClients([])
    setError(undefined)

    findClients()
      .then((cls) => {
        if (!cls || !cls.length) {
          setError(ERRORS.NO_CONTACTS_ERROR)
          return setClients([])
        }

        setClients(cls)
      })
      .catch(() => {
        setError(ERRORS.LOAD_CONTACTS_ERROR)
        setClients([])
      })
  }, [uid, connected])

  return useMemo(
    () => ({
      clients,
      loading,
      generatingLink,
      error,
      onSelectClient,
      onSelectInvested,
      onGenerateLink
    }),
    [
      clients,
      loading,
      generatingLink,
      error,
      onSelectClient,
      onSelectInvested,
      onGenerateLink
    ]
  )
}

export const useRiskalyzeModelData = (
  config: RiskalyzeDataConfig,
  form?: FormInstance,
  uid: string = 'uid'
) => {
  const [clients, setClients] = useState<RiskalyzeClient[]>([])
  const [client, setClient] = useState<any>({})

  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const modelContact = Form.useWatch('model_contact', form)
  const { connected } = useConnection('riskalyze')

  const setRiskalyzeError = (err: string) => {
    setError(err)
    setClients([])
  }

  useEffect(() => {
    if (!connected) {
      return setRiskalyzeError(ERRORS.INTEGRATION_ERROR)
    }

    setClients([])
    setError(undefined)
    setLoading(true)

    findClients()
      .then((cls) => {
        if (!cls || !cls.length) {
          return setRiskalyzeError(ERRORS.NO_CONTACTS_ERROR)
        }

        setClients(cls)
      })
      .catch(() => {
        return setRiskalyzeError(ERRORS.LOAD_CONTACTS_ERROR)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [uid, connected])

  useEffect(() => {
    if (modelContact) {
      global.bus.emit('DISABLE_SMARTFIELD_CHANGE', true)
      getClient(modelContact)
        .then((cli) => {
          if (!cli) {
            return setRiskalyzeError(ERRORS.NO_CLIENT_ERROR)
          }

          setClient(cli)
        })
        .catch(() => {
          return setRiskalyzeError(ERRORS.LOAD_CLIENT_ERROR)
        })
        .finally(() => {
          global.bus.emit('DISABLE_SMARTFIELD_CHANGE', false)
        })
    }
  }, [modelContact, uid, config])

  return useMemo(
    () => ({
      clients,
      client,
      loading,
      error
    }),
    [clients, client, loading, error]
  )
}
