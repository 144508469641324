import {
  ApplySchemaAttributes,
  extension,
  ExtensionPriority,
  NodeSpecOverride
} from '@remirror/core'
import { TableCellExtension } from '@remirror/react'

import { createTableNodeSchema } from './TableCustomForExport.utils'
import { TableSchemaSpec } from 'remirror/extensions'

/**
 * The extension for a table cell node.
 */
// @ts-ignore
@extension({ defaultPriority: ExtensionPriority.Low })
export class TableCellCustomForExportExtension extends TableCellExtension {
  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableCell
  }
}
