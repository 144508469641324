import { ConnectionState, STATES } from 'constants/connections'
import { filter, isEmpty } from 'lodash'
import { makeAutoObservable, observable, runInAction } from 'mobx'

import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import { Connection as GQLConnection } from 'types/graphql'
import { ConnectionProvider, connectionConfig } from './connection.config'
import { removeAdvisorSuffix, splitProviderAndAdvisor } from './connection.utils'

export type Connection = {
  provider: ConnectionProvider
  category?: string | undefined
  desc: string
  id: number
  name: string
  permissionKey: string
  shortDesc: string
  state: ConnectionState
  type: string
}

class Connections {
  connections = observable.map<string, Connection>()

  get _connections(): Connection[] {
    return Array.from(this.connections.values())
  }

  get installed() {
    return filter(this._connections, (i) => {
      let allowed = true
      if (!isEmpty(i.permissionKey)) {
        allowed = global.perms.get(i.permissionKey)
      }

      return i.state !== STATES.HIDDEN && allowed
    })
  }

  get connected() {
    return filter(this._connections, { state: STATES.CONNECTED })
  }

  get disconnected() {
    return filter(this._connections, { state: STATES.DISCONNECTED })
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  /**
   * Add connections from login response
   * @param {*} connections
   */
  setConnections(connections: GQLConnection[] = [], adminConnections: GQLConnection[] = []) {
    const allConnections: GQLConnection[] = connections.concat(adminConnections)

    const activeConnections = allConnections
      .filter((connection) => connection.state !== 'HIDDEN')
      .reduce((active, connection) => {
        const providerName = connection.identifier as string
        const { provider } = splitProviderAndAdvisor(providerName)

        if (provider in connectionConfig) {
          const provider = providerName as ConnectionProvider

          const connectionProvider: Connection = {
            provider: provider as ConnectionProvider,
            category: connection.category as string,
            desc: connection.desc as string,
            id: connection.id,
            name: String(connection.id),
            permissionKey: connection.permissionKey as string,
            shortDesc: connection.shortDesc as string,
            state: connection.state as ConnectionState,
            type: connection.type as string,
          }

          active.set(provider, connectionProvider)
        }

        return active
      }, new Map<ConnectionProvider, Connection>())

    this.connections.replace(activeConnections)
  }

  /**
   * Add connections from login response
   * @param {*} connections
   */
  updateConnection(provider: ConnectionProvider, response: GQLConnection) {
    const connection = this.getConnection(provider)

    runInAction(() => {
      Object.keys(response).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(connection, key)) {
          ;(connection as any)[key] = (response as any)[key]
        }
      })
    })
  }

  /**
   *
   */
  getConnection = (provider: ConnectionProvider | SmartFieldProvider): Connection => {
    const providerName = splitProviderAndAdvisor(provider).provider
    return this._connections.find(
      (connection) => removeAdvisorSuffix(connection.provider) === providerName
    ) as Connection
  }
}

const connectionStore = new Connections()
export { connectionStore }
