import { makeObservable, observable } from 'mobx'
import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import auditService from '../services/audit.service'

class Audit {
  logs = []
  // audit types
  TASK_SUMMARY = 'TASK_SUMMARY'
  SESSION = 'SESSION'

  constructor() {
    makeObservable(this, {
      logs: observable,
    })
  }

  /**
   * @param {*}
   */
  async log(category: any, message: any, data: any) {
    const advisor = global.data.advisors.me
    const dataStr = data ? JSON.stringify(data) : ''
    const advisorId = advisor?.id ?? 'preauth'
    try {
      await auditService.log(advisorId, dataStr, category, message)
    } catch (error) {
      console.error('Failed to log', data)
      msg.error(
        'Ups, seems there was an issue getting the updated log information',
      )
      captureExceptionSilently(error, {
        message: 'auditInfo',
        data: { advisorId },
      })
    }
  }
}

export default Audit
