// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PTG3S9F',
};

if (import.meta.env.PROD) {
  TagManager.initialize(tagManagerArgs);
}
