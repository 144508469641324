import { Form, FormInstance, Radio } from "antd";
import { SmartField } from "components/drawers/Smartfields/types";
import { isEmpty, isNumber } from "lodash";
import { useState, FC } from 'react';
import { SmartFieldDate } from "../../date";
import { SmartFieldNumber } from "../../number";
import { FormItemLabel } from "../../smartfield.styles";
import {
  SmartFieldConfig,
  SmartFieldDateConfig,
  SmartFieldNumberConfig,
} from "../../types/data";

interface SmartFieldIntegrationFormatterProps {
  form: FormInstance;
  smartfield?: SmartField;
}

type Format = "none" | "number" | "date";

const initialValue = (smartfield?: SmartField): Format => {
  if (!smartfield) return "none";

  const numConfig =
    smartfield.config as SmartFieldConfig<SmartFieldNumberConfig>;
  const dateConfig =
    smartfield.config as SmartFieldConfig<SmartFieldDateConfig>;

  switch (true) {
    case !isEmpty(numConfig.format) || isNumber(numConfig?.places):
      return "number";
    case !isEmpty(dateConfig.date):
      return "date";
    default:
      return "none";
  }
};

export const SmartFieldIntegrationFormatter: FC<
  SmartFieldIntegrationFormatterProps
> = ({ form, smartfield }) => {
  const [format, setFormat] = useState<Format>(initialValue(smartfield));

  return (
    <>
      <Form.Item
        name="integration_format_type"
        label={<FormItemLabel label="Format Options" />}
        initialValue={initialValue(smartfield)}
        preserve={false}
      >
        <Radio.Group onChange={(event) => setFormat(event.target.value)}>
          <Radio value="none">None</Radio>
          <Radio value="number">Number</Radio>
          <Radio value="date">Date</Radio>
        </Radio.Group>
      </Form.Item>

      {format === "date" && (
        <SmartFieldDate
          smartfield={smartfield}
          disableContainer
          preserve={false}
        />
      )}
      {format === "number" && (
        <SmartFieldNumber
          form={form}
          smartfield={smartfield}
          disableContainer
          preserve={false}
        />
      )}
    </>
  );
};
