import { useAuth } from "@clerk/clerk-react";
import { SIGNIN } from "constants/auth";
import { Navigate, useLocation } from "react-router-dom";
import { FC, ReactNode } from "react";

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { pathname, search } = useLocation();
  const { isLoaded, isSignedIn } = useAuth();

  // If Clerk has initialised and we're not signed in,
  // Redirect to the sign-in page
  if (isLoaded && !isSignedIn) {
    return (
      <Navigate
        to={SIGNIN}
        replace
        state={{
          redirectTo: `${pathname}${search ? search : ""}`,
        }}
      />
    );
  }

  return <div style={{ height: "100%" }}>{children}</div>;
};
