import { UploadFile } from 'antd'
import { getExtension } from 'constants/mimeFormats'
import { DelayedPromiseCreator } from 'remirror'
import { ImageAttributes } from 'remirror/extensions'
import { v4 as uuidv4 } from 'uuid'

interface FileWithProgress {
  file: File
  progress: SetProgress
}

type SetProgress = (progress: number) => void

type DelayedImage = DelayedPromiseCreator<ImageAttributes>

export function imageUploadHandler(files: FileWithProgress[]): DelayedImage[] {
  const promises: Array<DelayedPromiseCreator<ImageAttributes>> = []

  for (const { file } of files) {
    promises.push(
      () =>
        new Promise<ImageAttributes>(async (resolve, reject) => {
          const extension = getExtension(file.type)
          const fileName = `${uuidv4()}.${extension}`
          const fileData = new File([file], fileName, {
            type: file.type,
            lastModified: file.lastModified
          })
          const img = await global.images.upload(
            fileData as unknown as UploadFile
          )
          if (img) {
            resolve({ src: img.url, fileName: img.name || '' })
          }
          reject('An error occurred while uploading the file.')
        })
    )
  }

  return promises
}
