import { RiskalyzeConfig } from 'components/drawers/Smartfields/types/integration'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'
import { RiskalyzeDataSmartFields } from './types/data/data.replacer'
import { RiskalyzeQuestionaireSmartFields } from './types/questionaire/questionaire.replacer'
import { FC } from 'react'

export const RiskalyzeReplacer: FC<
  ProviderSmartFieldsReplacerProps<RiskalyzeConfig>
> = (props) => (
  <>
    {props.config.dataType === 'questionaire' ? (
      <RiskalyzeQuestionaireSmartFields {...props} />
    ) : (
      <RiskalyzeDataSmartFields {...props} />
    )}
  </>
)
