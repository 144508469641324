import { FC } from 'react';
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { SmartFieldIntegrationGenericBuilder } from '../genericData/generic.builder'
import { contactFields } from './precisefp.constants'

export const PreciseFPBuilder: FC<ProviderSmartFieldsBuilderProps> = (
  props,
) => {
  return (
    <SmartFieldIntegrationGenericBuilder
      {...props}
      provider="precisefp"
      providerTitle="PreciseFP"
      dataFields={{
        ...contactFields,
      }}
    />
  )
}
