import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import Messages from 'components/base/Alert/messages';
import { makeAutoObservable } from 'mobx';
import { Note } from 'types/graphql';

notification.config({
  bottom: 6,
  maxCount: 1,
});

class App {
  showAddTag: { item: Note; model: string } | false = false;
  appName = 'Pulse360';
  enterLicense = false;
  leftNavCollapsed = false;
  leftSiderCollapsed = false;
  _loading = false;
  _waiting = false;
  fromEula = false;
  phoneNum = '951-254-2373';
  supportEmail = 'support@pulse360.com';
  passwordRequirements = 'A combination of at least 8 characters, symbols and numbers.';

  get isProd() {
    return import.meta.env.PROD;
  }

  get homePage() {
    if (this.fromEula) {
      return '/onboard';
    }
    return '/contacts';
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * I have disabled this, as there were just too many of these popping up all over the place, looked erratic!
   *
   * If a main data load operation requires a 'loader' use 'wait' function below
   */
  // eslint-disable-next-line accessor-pairs
  set loading(value: boolean | string | undefined) {}

  get loading() {
    return this._loading;
  }

  set wait(value: boolean | string | undefined) {
    if (!!value && !this._waiting) {
      this._waiting = true;
      notification.open({
        message: <Messages msg={typeof value === 'string' && value} />,
        placement: 'bottom',
        duration: 0,
        key: 'app_wait',
        icon: <LoadingOutlined />,
      });
    } else if (!value && this._waiting) {
      notification.destroy('app_wait');
      this._waiting = false;
    }
  }

  get wait() {
    return this._waiting;
  }
}

export default App;
