import { FC } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { formatTooltip } from 'components/drawers/Smartfields/smartfield.utils'
import { PiRecycleFill } from 'react-icons/pi'
import styled from 'styled-components'
import { SmartFieldAtomAttrs } from '../../component'
import { useSmartFieldAtom } from './atom.hooks'
import { SmartFieldAtomIcons } from './atom.icons'

const style = { fontSize: 10, marginRight: 2 }

const SmartFieldAtomContainer = styled.div`
  display: inline-flex;
  min-height: 21px;
  align-items: center;
`

const CompactTooltip = styled(Tooltip)`
  display: inline-flex;
  min-height: 11px;
  align-items: center;
`

export const SmartFieldAtom: FC<SmartFieldAtomAttrs> = (atom) => {
  const { smartfield, connection } = useSmartFieldAtom(atom)

  return (
    <SmartFieldAtomContainer data-testid="smartfield">
      <CompactTooltip title={formatTooltip(smartfield)} showArrow={false}>
        {!smartfield ? (
          <LoadingOutlined style={{ ...style, marginRight: 2 }} data-testid="atom-loading-icon" />
        ) : (
          <SmartFieldAtomIcons type={smartfield?.type} connection={connection} />
        )}

        {/* Fall back to atom.label if smartfield hasn't loaded yet */}
        <span data-testid="atom-label">{smartfield?.label ?? atom.label}</span>

        {/* Add reusable icon if is a reusable smartfield */}
        {smartfield?.reuse && (
          <PiRecycleFill style={{ fontSize: 10, marginLeft: 2, marginBottom: 4 }} data-testid="atom-reuse-icon" />
        )}
      </CompactTooltip>
    </SmartFieldAtomContainer>
  )
}

/**
 * A version of `SmartFieldAtom` with the bare essentials, to be rendered replacing old Flexible Fields.
 */
export const smartFieldAtomReduced = ({ label }: { label: string }) => {
  return `<span class="remirror-mention-atom remirror-mention-atom-command">${label}</span>`
}
