import { useCommands } from '@remirror/react'
import { autorun } from 'mobx'
import { useEffect } from 'react'
import {
  EditorState,
  Fragment,
  ProsemirrorNode,
  RemirrorManager,
  htmlToProsemirrorNode,
} from 'remirror'
import { useSubTopics } from 'stores/subTopics/subTopic.hooks'
import { MergeFieldContent } from './NoteReplacer.interfaces'
import { PlaceholderData } from 'components/legacy/PlaceholdersSignatures.helpers'

function fixUnwrappedText(fragment: Fragment, state: Readonly<EditorState>) {
  if (fragment.childCount === 1 && fragment.firstChild?.type.name === 'text') {
    return state.schema.nodes.paragraph.create(null, fragment)
  }

  return fragment
}

function getMergeFieldContent(note: MergeFieldContent, state: Readonly<EditorState>) {
  return {
    ...note,
    content: fixUnwrappedText(
      htmlToProsemirrorNode({
        content: note.text!,
        schema: state.schema,
        fragment: true,
      }),
      state
    ),
  }
}

function processPlaceholder(placeholder: PlaceholderData, state: Readonly<EditorState>) {
  const { type, nodeData } = placeholder
  const processedContent = nodeData
    .map(({ nodeType, content, attrs }) => {
      if (nodeType === 'RemirrorJSON') {
        return state.schema.nodeFromJSON(content)
      } else if (nodeType === 'text' && content?.length > 0) {
        return state.schema.text(content)
      } else if (nodeType === 'image') {
        return state.schema.nodes['paragraph'].create(
          null,
          state.schema.nodes[nodeType].create(attrs, content)
        )
      } else if (nodeType !== 'text') {
        return state.schema.nodes[nodeType].create(attrs, content)
      }
      return null
    })
    .filter((content): content is ProsemirrorNode => content !== null)

  return {
    id: 1,
    tags: [],
    type,
    // In case there is only one element, we send that only instead of the whole array - otherwise, it won't be displayed as inline.
    content: processedContent.length === 1 ? processedContent[0] : processedContent,
  }
}

const runInNextTick = (fn: () => void) => {
  setTimeout(fn, 0)
}

interface NoteReplacerComponentProps {
  enabled: boolean
  state: Readonly<EditorState>
  mergeFieldContents: Array<MergeFieldContent>
  template: string
  manager: RemirrorManager<any>
  placeholders: PlaceholderData[]
  updateContent?: () => void
}

export const NoteReplacerComponent = ({
  enabled,
  mergeFieldContents,
  state,
  template,
  manager,
  placeholders,
  updateContent,
}: NoteReplacerComponentProps) => {
  const { replaceMergeFieldsWithContent } = useCommands()
  const { subTopics } = useSubTopics()

  useEffect(() => {
    if (!enabled) {
      return
    }
    autorun(() => {
      if (enabled && mergeFieldContents) {
        const prosemirrorContent = mergeFieldContents.map((note) =>
          getMergeFieldContent(note, state)
        )
        const placeholderProcessed = placeholders.map((placeholder) =>
          processPlaceholder(placeholder, state)
        )

        const fullContent = [...prosemirrorContent, ...placeholderProcessed]

        runInNextTick(() => {
          manager.view.updateState(manager.createState({ content: template }))
          replaceMergeFieldsWithContent(fullContent, subTopics)
          runInNextTick(() => {
            updateContent?.()
          })
        })
      }
    })
  }, [
    replaceMergeFieldsWithContent,
    mergeFieldContents,
    template,
    placeholders,
    enabled,
    state,
    manager,
    subTopics,
    updateContent,
  ])

  return null
}
