import {
  Collection,
  CollectionRelation,
  NoteGenius,
  Summary,
  Task,
  TaskTemplate,
  Workflow,
  WorkflowTemplate,
} from 'types/graphql'
import { Note } from 'lib/remirror/extensions/noteReplacer/NoteReplacer.interfaces'

export type SimpleSummary = { text: string }

export const isNote = (item: any): item is Note =>
  item.__typename === 'Note' ||
  (Object.hasOwn(item, 'content') &&
    Object.hasOwn(item, 'advisorId') &&
    Object.hasOwn(item, 'appointmentId') &&
    Object.hasOwn(item, 'clientId'))

export const isNoteGenius = (item: any): item is NoteGenius =>
  item.__typename === 'NoteGenius' ||
  (!isNote(item) && Object.hasOwn(item, 'text') && Object.hasOwn(item, 'topicId'))

export const isSummary = (item: any): item is Summary => {
  return item.__typename === 'Summary' || (!isNote(item) && Object.keys(item).length > 1)
}

export const isSimpleSummary = (item: any): item is SimpleSummary =>
  Object.keys(item).length === 1 && typeof item.text === 'string'

export const isTask = (item: Task | TaskTemplate): item is Task =>
  item.__typename === 'Task' || Object.hasOwn(item, 'linkedTo') || Object.hasOwn(item, 'noteId')

export const isTaskTemplate = (item: Task | TaskTemplate): item is TaskTemplate =>
  item.__typename === 'TaskTemplate' || (!isTask(item) && Object.hasOwn(item, 'summaryId'))

export const isWorkflow = (item: Workflow | WorkflowTemplate): item is Workflow =>
  item.__typename === 'Workflow' || Object.hasOwn(item, 'linkedTo') || Object.hasOwn(item, 'noteId')

export const isWorkflowTemplate = (item: Workflow | WorkflowTemplate): item is WorkflowTemplate =>
  item.__typename === 'WorkflowTemplate' || (!isWorkflow(item) && Object.hasOwn(item, 'summaryId'))

export const isCollection = (item: Collection | CollectionRelation): item is Collection =>
  item.__typename === 'Collection' ||
  (Object.hasOwn(item, 'color') &&
    Object.hasOwn(item, 'description') &&
    Object.hasOwn(item, 'favorite') &&
    Object.hasOwn(item, 'name'))

export const isCollectionRelation = (
  item: Collection | CollectionRelation
): item is CollectionRelation => !isCollection(item) && Object.hasOwn(item, 'collectionId')
