import { FC } from 'react';
import { Form, Select } from 'antd'
import { since } from 'helpers/dateRange'
import { formats } from 'helpers/formats'
import dayjs from "dayjs"
import { SmartFieldContainer } from '../smartfield.container'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField } from '../types'
import { SmartFieldDateConfig } from '../types/data'

interface SmartFieldDateProps {
  smartfield?: SmartField
  disableContainer?: boolean
  preserve?: boolean
}

export const SmartFieldDate: FC<SmartFieldDateProps> = ({
  smartfield,
  disableContainer,
  preserve = true,
}) => {
  const sf = smartfield as SmartField<SmartFieldDateConfig>
  const now = dayjs('2000-12-31')

  return (
    <SmartFieldContainer
      title="Date Options"
      testId="smartfield-date-form"
      disableContainer={disableContainer}
    >
      <Form.Item
        name="date"
        rules={[
          {
            required: true,
            message: 'Please select a date format',
          },
        ]}
        label={
          <FormItemLabel
            label="Date Format"
            description="Select your date format"
          />
        }
        initialValue={sf?.config?.date}
        preserve={preserve}
      >
        <Select
          placeholder="Select format"
          style={{ width: '100%' }}
          size="large"
        >
          {Object.keys(formats).map((key) => {
            const format = (formats as any)[key]
            const date = dayjs(now).format(format)
            return (
              <Select.Option key={`date-${key}`} value={format} label={date}>
                {date}
              </Select.Option>
            )
          })}

          <Select.Option key={`date-since`} value={'since'} label={since(now)}>
            {since(now)}
          </Select.Option>
        </Select>
      </Form.Item>
    </SmartFieldContainer>
  )
}
