/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Client } from 'components/base/AutoComplete/ContactAutoComplete/ContactAutocomplete.pm'
import { captureExceptionSilently } from 'helpers/sentry'
import { filesToAttachments } from 'lib/data/files/file.utils'
import { MailConfig } from 'lib/data/mail/mail.config'
import { addIsClientFacing } from 'lib/data/mail/mail.utils'
import html_tmpl from 'lib/remirror/util/html_tmpl'
import { isEmpty } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { msg } from 'stores/msg'
import { EmailInput } from 'types/graphql'
import { sendEmailInvariant } from './errors'

class SendEmailPM {
  config: MailConfig
  readReceipt = false
  initialTo: Client[] = []
  invalidClientEmail = false
  bccSelectedEmail = null
  isSending = false

  loadingRecipients = false

  get initialBCCs() {
    return this.config.bcc ?? []
  }

  get initialCCs() {
    return this.config.cc ?? []
  }

  constructor(config: MailConfig) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.getInitialTo(config)
    this.config = config
  }

  /* ---------- handlers ---------- */

  /* ---------- private ---------- */

  /**
   * @param values
   */
  onSubmit = async (values: Record<string, any>) => {
    const input = await this.buildEmailInput(values)

    try {
      if (this.isSending) {
        return
      }

      this.isSending = true
      await global.mail.sendEmail(input, this.readReceipt)

      this.launchConfetti()
      global.mail.close()
    } catch (err) {
      sendEmailInvariant(err, this.config)
    } finally {
      this.isSending = false
    }
  }

  /**
   * @param selected
   */
  onSelect = (selected: string) => {
    const fileObj = global.data.docs.get(selected)

    this.config.attachments.push({
      // @ts-expect-error TS(2322): Type 'number | { (...items: ConcatArray<never>[]):... Remove this comment to see the full error message
      file: fileObj,
      missing: false,
      // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
      warning: fileObj.name,
      local: true,
    })
  }

  /**
   * @param fileId
   */
  onRemoveAttachment = (fileId: number) => {
    const attachments = this.config.attachments.filter(({ file }) => {
      return file?.id !== fileId
    })

    this.config.attachments = attachments
  }

  /**
   * @param fileObj
   */
  onUpload = async (fileObj: any) => {
    const file = await global.files.prepareFileToUpload(fileObj)
    this.config.attachments.push({
      file: file,
      missing: false,
      warning: fileObj.name,
      local: true,
    })
    return false
  }

  /* ---------- Private ---------- */

  /**
   * @param values
   * @returns
   */
  private buildEmailInput = async (values: Record<string, any>): Promise<EmailInput> => {
    const {
      to,
      cc,
      bcc,
      subject,
      markup,
      attachments,
      appointmentId,
      templateCategoryId,
      source,
      content,
      documentId,
      contactIds,
    } = this.config

    const { getFullName, me } = global.data.advisors

    const config: EmailInput = {
      from: [{ name: getFullName(me), email: me?.email }],
      to: await this.formatEmails(values.to || to),
      cc: await this.formatEmails(values.cc || cc),
      bcc: await this.formatEmails(values.bcc || bcc),
      files: filesToAttachments(attachments),
      subject: values.subject || subject,
      body: html_tmpl(markup),
      appointmentId,
      templateCategoryId:
        global.data.templates.selectedTemplate?.templateCategoryId || templateCategoryId,
      contactIds: contactIds || [],
      source: source,
      content,
      documentId,
    }

    addIsClientFacing(config)
    return config
  }

  /**
   */
  private formatEmails = async (clientIds: string | number | string[] | number[]) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    const ids = [].concat(clientIds)
    if (!ids || !ids.length) {
      return []
    }

    const { getClient, getFullName } = global.data.clients

    const formated = []

    for (const idOrEmail of ids) {
      if (typeof idOrEmail === 'string' && isNaN(idOrEmail)) {
        formated.push({ email: idOrEmail })
        continue
      }

      try {
        const client = await getClient(Number(idOrEmail))
        if (client) {
          formated.push({
            name: getFullName(client, false, false),
            email: client.email,
          })
        }
      } catch (error) {}
    }

    return formated
  }

  /**
   *
   */
  private launchConfetti = () => {
    const name = global.data.advisors.getAdvisorName()
    // @ts-expect-error TS(2554): Expected 3 arguments, but got 1.
    msg.success(`${name}, awesome job - email is on its way!`)
    // @ts-expect-error TS(7017): Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    global.confetti.start()
    setTimeout(() => {
      // @ts-expect-error TS(7017): Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
      global.confetti.stop()
    }, 2000)
  }

  /**
   * @param config
   */
  private getInitialTo = async (config: MailConfig) => {
    try {
      this.loadingRecipients = true
      const contactIds = Array.isArray(config.to) ? config.to : [config.to]

      const contactsPromises = contactIds.map((contactId) =>
        global.data.clients.getClient(Number(contactId))
      )

      const contacts = await Promise.all(contactsPromises)

      this.invalidClientEmail = contacts.some(
        (contact) => !Boolean(contact) || isEmpty(contact.email)
      )
      this.initialTo = contacts as Client[]
    } catch (error) {
      console.error('Error setting initial to', error)
      captureExceptionSilently(error, { message: 'getInitialTo', data: { config } })
    } finally {
      this.loadingRecipients = false
    }
  }
}

export default SendEmailPM
