import { Form, FormInstance, Select } from 'antd'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import { isEmpty } from 'lodash'
import { autorun } from 'mobx'
import { useCallback, useEffect, FC } from 'react';

interface RiskalyzeClientsProps {
  clients: any[]
  formName: string
  loading: boolean
  onChange?: (clientId: string) => void
  form: FormInstance
}

export const RiskalyzeClients: FC<RiskalyzeClientsProps> = ({
  clients,
  formName,
  loading,
  onChange,
  form,
}) => {
  const onChangeClient = useCallback(
    (clientId: string) => {
      global.riskalyze._clientId = clientId
      onChange?.(clientId)
    },
    [onChange],
  )

  useEffect(() => {
    return autorun(() => {
      const client = form.getFieldValue(formName)

      if (clients.length && isEmpty(client) && global.riskalyze._clientId) {
        form.setFieldValue(formName, global.riskalyze._clientId)
      }
    })
  }, [clients, form, formName])

  return (
    <Form.Item
      name={formName}
      rules={[{ required: true, message: 'Please select a client' }]}
      label={
        <FormItemLabel
          label="Client"
          description="Select the required client"
        />
      }
      initialValue={clients.length > 0 && global.riskalyze._clientId}
    >
      <Select
        onChange={onChangeClient}
        placeholder="Select client"
        style={{ width: '100%' }}
        size="large"
        loading={loading}
        showSearch
        filterOption={(input, option) =>
          String(option?.label ?? '')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {clients.map((client) => (
          <Select.Option
            value={client.id}
            label={`${client.firstName} ${client.lastName}`}
          >
            {`${client.firstName} ${client.lastName}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
