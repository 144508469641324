/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  DeleteOutlined,
  FileExclamationFilled,
  FileTextFilled,
  UploadOutlined,
} from '@ant-design/icons'
import { Alert, Button, Collapse, List, Select, Space, Tooltip, Typography, Upload } from 'antd'
import {
  FileAttachment,
  validAttachments,
  warningAttachments,
} from 'components/routes/tasks/notes/notes.utils'
import { useFilteredAttachments } from 'lib/data/files/file.hooks'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState, FC } from 'react'
import styled from 'styled-components'
import { styles, FlexRow } from 'styles'
import Presenter from './pm'

const StyledSpace = styled(Space)`
  width: 100%;

  .select-wrapper {
    width: 100%;

    .ant-space-item:first-of-type {
      width: 100%;
    }
  }

  .ant-list {
    max-height: 200px;
    overflow-y: auto;

    .ant-list-item {
      display: flex;
      padding: 12px 16px;

      .ant-list-item-meta {
        flex: 0 0;
      }

      .content {
        flex: 1;
      }

      .ant-list-item-action {
        li {
          padding: 0;

          .anticon-delete {
            color: ${styles.colors.error};
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
`

const contextId = (specific: string) => `notes_tools_sendEmail_attachment-${specific || ''}`

interface AttachmentsProps {
  pm: Presenter
}

export const Attachments: FC<AttachmentsProps> = observer(({ pm }) => {
  const [attachments, setAttachments] = useState<FileAttachment[]>([])
  const [warnings, setWarnings] = useState<FileAttachment[]>([])
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState<any>()

  const { filtered } = useFilteredAttachments(attachments)

  const onSelect = useCallback(
    (value: any) => {
      pm.onSelect(value)
      setSelected(null)
      setVisible(false)
    },
    [pm]
  )

  useEffect(() => {
    return autorun(() => {
      setAttachments(validAttachments(pm.config.attachments, true))
      setWarnings(warningAttachments(pm.config.attachments))
    })
  }, [pm.config.attachments])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {warnings.length > 0 && (
        <Alert
          style={{ marginBottom: 10 }}
          message={`${warnings.length} attachment/s missing.  You can still send the email.`}
          type="warning"
          showIcon
        />
      )}

      <Collapse>
        <Collapse.Panel
          key="1"
          header={
            <FlexRow justify="space-between">
              <Space size={0}>
                {attachments.length === 0 && warnings.length === 0 && (
                  <Space>
                    <Typography.Text>No attachments</Typography.Text>
                  </Space>
                )}

                {attachments.length > 0 && (
                  <Space>
                    <Typography.Text>Attached:</Typography.Text>
                    <Space size={5}>
                      {attachments.map(({ file }, index) => (
                        <Tooltip key={index} title={file.name} overlayClassName="full">
                          <FileTextFilled style={{ color: styles.colors.green, fontSize: 15 }} />
                        </Tooltip>
                      ))}
                    </Space>
                  </Space>
                )}

                {attachments.length > 0 && warnings.length > 0 && (
                  <Typography.Text style={{ marginRight: 10 }}>{``}</Typography.Text>
                )}

                {warnings.length > 0 && (
                  <Space>
                    <Typography.Text>{`Missing:`}</Typography.Text>
                    <Space size={5}>
                      {warnings.map(({ warning }, index) => (
                        <Tooltip key={index} title={warning} overlayClassName="full">
                          <FileExclamationFilled
                            style={{ color: styles.colors.error, fontSize: 15 }}
                          />
                        </Tooltip>
                      ))}
                    </Space>
                  </Space>
                )}
              </Space>
              <Typography.Text style={{ color: styles.colors.ultraLightBlack }}>
                Click to expand
              </Typography.Text>
            </FlexRow>
          }
        >
          <StyledSpace direction="vertical">
            <Space style={{ flex: 1 }} className="select-wrapper">
              <Select
                id={contextId('select')}
                onDropdownVisibleChange={setVisible}
                placeholder="Select a file to attach..."
                onSelect={onSelect}
                value={selected}
                style={{ width: '100%' }}
                size="large"
                open={visible}
              >
                {filtered.map(({ id, name }) => (
                  <Select.Option
                    id={contextId(`select_option_${id}`)}
                    key={id}
                    value={id}
                    title={name}
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select>

              <Upload
                id={contextId(`button_addLocalAttachment`)}
                accept=".csv,.xlsx,.jpg,.jpeg,.gif,.bmp,.png,.pdf,.docx,.doc"
                beforeUpload={pm.onUpload}
                showUploadList={false}
                multiple={false}
              >
                <Tooltip title="Attach a local document">
                  <Button icon={<UploadOutlined />} size="large" />
                </Tooltip>
              </Upload>
            </Space>

            {attachments.length > 0 && (
              <List
                dataSource={attachments}
                bordered
                renderItem={({ file }, i) => (
                  <List.Item
                    actions={[
                      <Tooltip title="Remove attachment from Email">
                        <DeleteOutlined
                          id={`notes_tools_exportSummary_attachment_addedAttachment_button_delete_${file.id}`}
                          onClick={() => pm.onRemoveAttachment(file.id)}
                        />
                      </Tooltip>,
                    ]}
                  >
                    <List.Item.Meta avatar={<strong>{i + 1}.</strong>} />
                    <Typography.Text ellipsis className="content">
                      {file.name}
                    </Typography.Text>
                  </List.Item>
                )}
              />
            )}
          </StyledSpace>
        </Collapse.Panel>
      </Collapse>
    </Space>
  )
})
