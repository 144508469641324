import { autorun } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { styles } from 'styles'

export const useViewport = () => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isTabletUp, setIsTabletUp] = useState(false)
  const [isTabletDown, setIsTabletDown] = useState(false)
  const [isLaptop, setIsLaptop] = useState(false)
  const [isLaptopUp, setIsLaptopUp] = useState(false)
  const [isLaptopDown, setIsLaptopDown] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isDesktopXL, setIsDesktopXL] = useState(false)

  // Remember the latest callback.
  useEffect(() => {
    autorun(() => {
      setWidth(global.view.width)
      setHeight(global.view.height)
    })
  }, [])

  useEffect(() => {
    setIsMobile(width < styles.media.points.tablet)
    setIsTablet(
      width >= styles.media.points.mobile && width < styles.media.points.laptop
    )
    setIsTabletUp(width >= styles.media.points.tablet)
    setIsTabletDown(width < styles.media.points.laptop)
    setIsLaptop(
      width >= styles.media.points.laptop && width < styles.media.points.desktop
    )
    setIsLaptopUp(width >= styles.media.points.laptop)
    setIsLaptopDown(width < styles.media.points.desktop)
    setIsDesktop(
      width >= styles.media.points.desktop &&
        width < styles.media.points.large_desktop
    )
    setIsDesktopXL(width >= styles.media.points.large_desktop)
  }, [width, height])

  return useMemo(
    () => ({
      width,
      height,
      isMobile,
      isTablet,
      isTabletDown,
      isTabletUp,
      isLaptop,
      isLaptopDown,
      isLaptopUp,
      isDesktop,
      isDesktopXL
    }),
    [
      height,
      isDesktop,
      isDesktopXL,
      isLaptop,
      isLaptopDown,
      isLaptopUp,
      isMobile,
      isTablet,
      isTabletDown,
      isTabletUp,
      width
    ]
  )
}
