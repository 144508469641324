/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Input, Tooltip } from 'antd'
import { useCallback, useState, FC } from 'react';
import styled from 'styled-components'
import { FlexRow, styles } from 'styles'
import { SmartFieldContainer } from '../smartfield.container'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField } from '../types'
import { SmartFieldListConfig, SmartFieldListItem } from '../types/data'

const EmptyItem = (index: number): SmartFieldListItem => ({
  label: '',
  value: `listItem${index}`
})

interface SmartFieldListProps {
  smartfield?: SmartField
  form: FormInstance
}

const WideFormItem = styled(Form.Item)`
  .ant-form-item-required {
    width: 100%;
  }
`

export const SmartFieldList: FC<SmartFieldListProps> = ({
  smartfield,
  form
}) => {
  const sf = smartfield as SmartField<SmartFieldListConfig>

  const [items, setItems] = useState<SmartFieldListItem[]>(
    sf?.config?.list?.slice() ?? [EmptyItem(1), EmptyItem(2)]
  )

  const onAddItem = useCallback(() => {
    const newItems = [...items, EmptyItem(items.length + 1)]
    newItems.forEach((item, idx) =>
      form.setFieldValue(`listItem${idx + 1}`, item.label)
    )
    setItems(newItems)
  }, [items])

  const onRemoveItem = useCallback(
    (itemIndex: number) => {
      const newItems = items.filter((i, idx) => idx !== itemIndex)
      newItems.forEach((item, idx) =>
        form.setFieldValue(`listItem${idx + 1}`, item.label)
      )
      setItems(newItems)
    },
    [items, form]
  )

  return (
    <SmartFieldContainer title='List Options' testId='smartfield-list-form'>
      {items.map((item, index, arr) => (
        <WideFormItem
          name={`listItem${index + 1}`}
          rules={[{ required: true, message: 'This item needs a value' }]}
          initialValue={items?.[index]?.label}
          key={`list-${item.value}`}
          label={
            <FlexRow
              justify='space-between'
              align='flex-start'
              style={{ flex: 1 }}
            >
              <FormItemLabel label={`Option ${index + 1}`} description='' />
              {arr.length > 2 && (
                <Tooltip title='Remove list item'>
                  <DeleteOutlined
                    onClick={() => onRemoveItem(index)}
                    style={{ color: styles.colors.red, marginTop: 4 }}
                  />
                </Tooltip>
              )}
            </FlexRow>
          }
        >
          <Input
            style={{ width: '100%' }}
            size='large'
            autoFocus={index === arr.length - 1}
            onChange={(e) => {
              items[index].label = e.target.value
              items[index].value = e.target.value
            }}
          />
        </WideFormItem>
      ))}

      <Form.Item>
        <Button onClick={onAddItem} size='large' icon={<PlusOutlined />}>
          Add Option
        </Button>
      </Form.Item>
    </SmartFieldContainer>
  )
}
