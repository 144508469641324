/* eslint-disable */
import * as Sentry from '@sentry/browser'
import initHelpHero from 'helphero'
import { get, uniqueId } from 'lodash'

const chunkLoadError = 'ChunkLoadError'
let hlp: any = undefined

const HelpHeroTours = {
  AddNotesHelp: {
    tourId: 'idRElTR0Jd',
    startingStep: 'cku32jc0k007r3a5tbcfa57u5'
  },
  EmptyContact: {
    tourId: 'esR2ll4Qdhn',
    startingStep: 'ckn9kub1100363b5s81gyfosl'
  },
  EmptyQuickNotes: {
    tourId: '6FmlTCr9r4d',
    startingStep: 'ckn8f61zp09jy3b5s9eiv0pic'
  },
  EmptyEvent: {
    tourId: 'dTpDdDONCWd',
    startingStep: 'ckn9mboju0r8d3b5sopv3zy3h'
  },
  EmptyEventNotes: {
    tourId: '6FmlTCr9r4d',
    startingStep: 'ckn8f61zp09jy3b5s9eiv0pic'
  },
  EmptyAllNotes: {
    tourId: 'JYaLZyMDWxX',
    startingStep: 'ckn9n1lqd16vq3b5s3d6vxkp1'
  }
}

/**
 * Capture an event and send it to Sentry, with extra data
 * @param {{message:!String, data:Object}} eventData
 */
export function captureEvent({ message, data }: any) {
  Sentry.addBreadcrumb({ message, data: { ...data } })
  Sentry.captureEvent({ message })
}

export function captureExceptionSilently(
  error: any,
  { message, data = {} }: any
) {
  Sentry.addBreadcrumb({ message, data: { ...data } })

  Sentry.withScope((scope) => {
    scope.setExtra('silent', true)
    Sentry.captureException(error)
  })
}

const update = (name: any, pool: any, user: any) => {
  {
    const scope = Sentry.getCurrentScope()
    scope.setTag('workspace', name)
    scope.setTag('pool', pool)
    scope.setUser(user)
  }

  if (!hlp) {
    return
  }

  if (user) {
    hlp.identify(user.email, {
      workspace: name,
      email: user.email,
      nickname: user.nickName
    })
  } else {
    hlp.anonymous()
  }
}

export const identifyHelpHero = (advisor: any, workspaceName: any) => {
  if (!hlp) {
    return
  }
  if (advisor) {
    hlp.identify(advisor.email, {
      workspace: workspaceName,
      email: advisor.email,
      nickname: advisor.nickName
    })
  } else {
    hlp.anonymous()
  }
}

export const updateHelpHero = (params: any) => {
  if (!hlp) {
    return
  }
  if (params && typeof params === 'object') {
    hlp.update(params)
  }
}

export const startHelpHeroTour = (tourName: any, skipIfAlreadySeen = false) => {
  if (!hlp) {
    return
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const tour = HelpHeroTours[tourName]
  if (tour) {
    console.info('Help Hero Tour', tourName, JSON.stringify(tour))
    hlp.startTour(tour.tourId, {
      skipIfAlreadySeen,
      stepId: tour.startingStep
    })
  } else {
    console.error('Invalid tour', tourName, tour)
  }
}

/**
 * @param {*} advisorId
 */
export default () => {
  if (import.meta.env.DEV || import.meta.env.PROD) {
    Sentry.init({
      environment: import.meta.env.DEV ? 'development' : 'production',
      dsn: import.meta.env.VITE_SENTRY_ENDPOINT,
      release: import.meta.env.REACT_APP_APP_VERSION,
      // @ts-expect-error TS(2345): Argument of type '{ environment: string; dsn: stri... Remove this comment to see the full error message
      enableJavaScript: true,
      enableNative: true,
      maxBreadcrumbs: 20,
      tracesSampleRate: 0.5,
      ignoreErrors: [
        /ResizeObserver/,
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications'
      ],
      tracePropagationTargets: [
        'https://app.dev.pulse360.com',
        'https://app.pulse360.com'
      ],

      beforeSend(event) {
        // if it's a ChunkLoadError, reload page and don't send event
        const errorType = get(event, 'exception.values[0].type', 'none')
        if (errorType === chunkLoadError) {
          window.location.reload()
          return null
        }
        const isSilent = get(event, 'extra.silent', false)

        if (IsFroalaError(event)) {
          return null
        }

        // Check if it is an exception, if so, show the report dialog
        // Note that this only will work in the renderer process, it's a noop on the main process
        if (event.exception && !isSilent) {
          console.error('Exception', JSON.stringify(event.exception))
          Sentry.showReportDialog()
        }
        return event
      }
    })
    Sentry.getCurrentScope().setUser({ id: uniqueId(), user: null })

    hlp = initHelpHero('lBGBLDwISJX')
  }
}

export function _buildTrackNote(
  summary: any,
  topic: any,
  creationContext: any
) {
  const tags = Array.isArray(summary.tags) ? summary.tags : []
  const noteText = typeof summary.text === 'string' ? summary.text : ''
  return {
    creationContext,
    destinationContext: global.data.appt?.isGlobal ? 'Global Appt' : 'Appt',
    Topic: topic.id + ':' + topic.name,
    MicroTemplateId: summary.id,
    hasFlexFields: noteText.includes('{{'),
    tags: tags.map((tag: any) => tag?.name).filter(Boolean),
    numberOfTasksAttached: Number(!!summary.taskTemplate),
    numberOfWorkflowsAttached: Number(!!summary.workflowTemplate),
    numberOfTagsAttached: tags.length,
    numberOfFilesAttached: [summary.file1, summary.file2, summary.file3].filter(
      Boolean
    ).length
  }
}

function IsFroalaError(event: any) {
  const errorType = get(event, 'exception.values[0].type', null)
  const errorValue = get(event, 'exception.values[0].value', null)

  if (!errorType || !errorValue) {
    return false
  }

  return !!event.extra?.arguments?.some((arg: any) =>
    arg.target.includes('fr-wrapper')
  )
}
