import { Modal } from 'antd'
import styled from 'styled-components'
import { styles } from 'styles'

export const SmartFieldModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px;

    > .ant-alert {
      margin: 16px 24px 12px;
    }

    .note-preview {
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 20px;

      // disable cursor over editor
      cursor: not-allowed;

      .remirror-editor-wrapper {
        pointer-events: none;
      }

      .note-preview--title {
        position: absolute;
        font-size: 8px;
        color: ${styles.colors.gray7};
        text-transform: uppercase;
        top: 3px;
        right: 7px;
      }

      .remirror-style-wrapper {
        max-height: 150px;
        overflow: auto;
      }
    }
  }
`
