import confetti from '../static/js/confetti.min.js';
import { configure } from 'mobx';
import './dayjs';
import './gtm';
import './posthog';

// @ts-expect-error
global.confetti = confetti;

configure({ enforceActions: 'never' });
