import { Tag } from 'types/graphql'

export const TAG = {
  AGENDA: 'agenda',
  AUDIT: 'audit',
  CLIENT: 'client',
  FUTURE: 'future',
  DEFAULT: 'default',
  FAVORITE: 'favorite',
  PRIVATE: 'private',
  SUMMARY: 'summary',
  NEXT_MEETING: 'next_meeting',
  ADD_TO_MEETING: 'add_to_meeting',
  AGENDA_LEGACY: 'agenda_legacy',
  SUMMARY_LEGACY: 'summary_legacy',
}

export const TagTypes = {
  SYSTEM: 'SYSTEM',
  USER_SYSTEM: 'USER_SYSTEM',
  USER: 'USER',
  SUBTOPIC: 'SUBTOPIC',
}

export const TagTypesNames = {
  [TagTypes.SYSTEM]: 'System',
  [TagTypes.USER_SYSTEM]: 'System Tag',
  [TagTypes.USER]: 'User Tag',
  [TagTypes.SUBTOPIC]: 'Subtopic',
}

/**
 * Internal Tag item for "None", intended for a subtopic list. Requires special handling when selected.
 */
export const NONE_ITEM = {
  name: 'None',
  value: 'NONE_INTERNAL_ITEM',
}

const TAG_INVERSE = Object.entries(TAG).reduce(
  (acum, [name, value]) => ({ ...acum, [value]: name }),
  {},
)

export function isTaggedAs(tags: Tag[] = [], value = '') {
  if (!Array.isArray(tags)) {
    return false
  }
  return tags.some((tag) => tag && tag.value === value)
}

function findTag(tags: Tag[] = [], value = '') {
  if (!Array.isArray(tags)) {
    return undefined
  }
  return tags.find((tag) => tag && tag.value === value)
}

export function userTags(tags: Tag[] = []) {
  if (!Array.isArray(tags)) {
    return []
  }
  return tags.filter(global.tags.userTagsFilter)
}
