import { delay, get } from 'lodash'
import { msg } from 'stores/msg'

const logUserOut = (errorMessage: any) => {
  // @ts-expect-error TS(2339): Property 'then' does not exist on type 'void'.
  global.auth.signOut().then(() => {
    global.router.goto()
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    delay(() => msg.error(errorMessage, 10), 500)
    delay(() => !document.querySelector('.header') && window.location.reload(), 7000)
  })
}

export function errorHandlers(code: any) {
  const errorHandlers = {
    disabled_practice: () =>
      logUserOut(
        `Your practice is disabled or under maintenance, please try to login later or reach out to the support team`,
      ),
    not_authorized: () => logUserOut(`Your access has been disabled. Please contact your admin.`),
    default: () => {
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      msg.error(`Well this is embarrassing - something went wrong. Tech Support have been notified 😕`, 10)
    },
  }
  if (get(code, 'message', '') === 'Connection refused!') {
    return global.auth.signOut()
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return code && code in errorHandlers ? errorHandlers[code]() : errorHandlers.default()
}
