export const STATES = {
  // Integration states
  // 1. Hidden - not visible to the app
  HIDDEN: 'HIDDEN',
  // 2. Disabled - visible but not turned on
  DISABLE: 'DISABLE',
  // 3. Enabled - connected but not initial sync not complete
  ENABLE: 'ENABLE',
  // 4. Syncing - initially syncd and performing regular sync
  SYNCING: 'SYNCING',
  // 5. Enabled - but is not a syncing integration
  NON_SYNCING: 'NON_SYNCING'
}

export type IntegrationState = keyof typeof STATES

export const isEnabled = (state: IntegrationState = 'DISABLE') => {
  return state !== 'HIDDEN' && state !== 'DISABLE' && state !== 'NON_SYNCING'
}
