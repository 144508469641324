import {
  Collection,
  CollectionInput,
  CollectionRelation,
  CollectionRelationInput,
  Maybe,
} from 'types/graphql'
import { pick } from 'lodash'
import { isCollection, isCollectionRelation } from '@helpers/mappers/discriminators'

export const toCollectionInput = (collection: Collection): CollectionInput | undefined => {
  if (!collection) return {} as CollectionInput

  if (!isCollection(collection)) {
    throw new Error('Collection is not a Collection')
  }

  return { ...pick(collection, ['color', 'description', 'favorite', 'name']) }
}

export const toCollectionRelationInput = (
  relation?: Maybe<CollectionRelation>
): CollectionRelationInput => {
  if (!relation) return {} as CollectionRelationInput

  if (!isCollectionRelation(relation)) {
    throw new Error('CollectionRelation is not a CollectionRelation')
  }

  return { ...pick(relation, ['collectionId', 'itemId', 'order']) }
}
