import { FileObject } from 'types/graphql'
import { proxy } from 'valtio'
import { compact } from 'lodash'
import filesService from 'stores/services/files.service'
import { proxyMap } from 'valtio/utils'

type FileState = {
  bucket: string
  rootFolder: string
  files: Map<string, FileObject>
  selected: Map<string, FileObject>
  loading: boolean
  loaded: boolean
  loadAll: () => Promise<void>
}

export const fileState = proxy<FileState>({
  bucket: '',
  rootFolder: '',
  files: proxyMap([]),
  selected: proxyMap([]),
  loading: true,
  loaded: false,

  async loadAll() {
    this.loading = true

    const files = compact(await filesService.loadFiles('')) as FileObject[]

    files.forEach((file) => {
      fileState.files.set(file.key, file)
    })

    this.loading = false
    this.loaded = true
  },
})
