import {
  DefaultOptions,
  DocumentNode,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  TypedDocumentNode,
  WatchQueryOptions,
} from '@apollo/client'
import DataError from 'helpers/dataError'
import { get } from 'lodash'
import { initializeApollo } from './client'

/**
 * Utility to execute queries
 */
export async function query<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options: Omit<QueryOptions, 'query' | 'variables'> = {},
) {
  const client = initializeApollo()

  try {
    const res = await client.query<TData, TVariables>({
      query,
      variables,
      ...options,
    })

    return res.data
  } catch (err) {
    networkErrorResponseFormatter(query, err)
  }
}

/**
 * Utility to mutate queries
 */
export async function mutate<TData = any, TVariables extends OperationVariables = OperationVariables>(
  mutation: any,
  variables: TVariables,
  options: Omit<MutationOptions, 'mutation' | 'variables'> = {},
) {
  const client = initializeApollo()
  try {
    const res = await client.mutate<TData, TVariables>({
      mutation,
      variables,
      ...options,
    })

    if (!res.data) {
      throw new DataError({
        message: 'No data returned from mutation',
        error: JSON.stringify(res),
      })
    }

    return res.data
  } catch (err) {
    if (err instanceof DataError) {
      throw err
    }
    networkErrorResponseFormatter(mutation, err)
  }
}

/**
 * Utility to watch queries
 */
function watchQuery(options: WatchQueryOptions) {
  const client = initializeApollo()
  return client.watchQuery(options)
}

function networkErrorResponseFormatter(request: any, err: any): never {
  const message = get(
    err,
    'networkError.result.errors[0].message',
    get(err, 'message', 'Ups, something went wrong - Network Error'),
  )
  const query = get(request, 'definitions[0].name.value', 'unknown_query')

  console.log(JSON.stringify(err, null, 2))

  throw new DataError({
    message,
    error: query,
  })
}

export const defaultOptions: DefaultOptions =
  process.env.NODE_ENV === 'test'
    ? {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
        mutate: {
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
      }
    : {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      }
