import dayjs, { isDayjs } from 'dayjs'
import { isNumber } from 'remeda'
import { isNullish } from 'remeda'
import { Dayjs } from 'dayjs'
import { getCurrentRoundedTime, getRoundedTime } from 'helpers/time'
import { Appointment, Maybe } from 'types/graphql'
import { integrationStore } from 'stores/integrations'

/**
 * @param date
 * @returns
 */
export const toDayJS = (date: Dayjs | string | Maybe<string> | number | undefined, now = false) => {
  if (isNullish(date)) return now ? dayjs() : undefined
  if (isDayjs(date)) return date

  const dateJS = dayjs(date)
  if (dateJS.isValid()) return dateJS

  return now ? dayjs() : undefined
}

/**
 * @param date
 * @returns
 */
export const toDateString = (date: Dayjs | string | Maybe<string> | undefined, now = false) => {
  if (isNullish(date)) return now ? dayjs().toISOString() : undefined
  if (isDayjs(date)) return date.toISOString()

  const dateJS = dayjs(date)
  if (dateJS.isValid()) return dateJS.toISOString()

  return now ? dayjs().toISOString() : undefined
}

/**
 * @param date
 * @param prop
 * @returns
 */
export const toDatePropString = (
  date: Dayjs | string | undefined,
  prop: 'startDate' | 'endDate'
) => {
  const dateStr = toDateString(date)

  if (!isNullish(dateStr)) return { [prop]: dateStr }

  return { [prop]: undefined }
}

/**
 * @param startDate
 * @param endDate
 * @param customFields
 * @param relatedAppointment
 * @returns
 */
export const toCrmDates = (
  startDate: string | number | Dayjs | null | undefined,
  endDate: string | number | Dayjs | null | undefined,
  customFields:
    | {
        allDay?: boolean
        endFromEvent?: boolean
        startFromEvent?: boolean
      }
    | null
    | undefined,
  relatedAppointment?: Partial<Appointment> | null | undefined
): [string, string] => {
  // Extract settings with defaults
  const { allDay = true, endFromEvent = false, startFromEvent = false } = customFields || {}

  const { global: globalAppointment = false, date: apptDate = new Date() } =
    relatedAppointment || {}

  // Determine if we should use event date as reference
  const useEvent = (endFromEvent || startFromEvent) && !globalAppointment
  const referenceDate = useEvent ? dayjs(apptDate) : dayjs(getCurrentRoundedTime(30, true))

  let startDateObj: Dayjs
  let endDateObj: Dayjs

  // Process start date
  if (!isNullish(startDate)) {
    // If start date exists
    if (isNumber(startDate)) {
      // If it's a number, add days to reference date
      startDateObj = referenceDate.add(startDate, 'day')
    } else {
      // Otherwise parse it directly
      startDateObj = dayjs(startDate)
    }

    // Process end date based on start date
    if (isNumber(endDate)) {
      // If end date is a number, add days to start date
      endDateObj = startDateObj.add(endDate, 'day')
    } else {
      // Otherwise parse it directly
      endDateObj = dayjs(endDate)
    }
  } else {
    // If no start date, calculate based on end date first
    if (isNumber(endDate)) {
      // Add days to reference date
      endDateObj = referenceDate.add(endDate, 'day')
    } else {
      // Parse directly
      endDateObj = dayjs(endDate)
    }

    // Use end date as start date when no start date is provided
    startDateObj = dayjs(endDateObj)
  }

  // Handle special case for RedTail CRM when allDay is true
  if (integrationStore.enabledCRM.includes('RedTail') && allDay) {
    // Set both dates to noon for all-day events
    startDateObj = getRoundedTime(startDateObj.hour(12))
    endDateObj = getRoundedTime(endDateObj.hour(12))
  }

  // Return ISO strings
  return [startDateObj.toISOString(), endDateObj.toISOString()]
}
