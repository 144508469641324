import { isObjectLike } from 'lodash'
import Str from 'stores/util/str.util'
import { BaseContactType, ContactFactory } from './generic.factory'
import { WealthboxCustomField } from 'types/graphql'

export interface CascaderNode {
  value: string
  label: string
  children?: CascaderNode[]
}

const parseCustomFields = (fields: WealthboxCustomField[]): CascaderNode[] =>
  fields.map((field) => {
    return {
      value: String(field.id ?? ''),
      label: field.name ?? '',
    }
  })

/**
 * @param fields
 * @param extra
 * @returns
 */
const parseObject = (fields: any): CascaderNode[] => {
  return Object.keys(fields)
    .map((key) => {
      let field = (fields as any)[key]
      let isArray = Array.isArray(field)
      let isString = typeof field === 'string'

      const label = isString ? field : Str.startCase(key)
      const node: CascaderNode = {
        value: key,
        label: label,
      }

      // If custom fields, map out the fields to nodes
      if (key === 'custom_fields') {
        node.children = parseCustomFields(field)
        return node
        // if an array, use the first child object to construct the node with.
        // we also need to add a prop to stipulate that when parsing the actual
        // data, we treat this node as an array not an object
      } else if (isArray) {
        field = field?.[0] ?? {}
      }

      if (isObjectLike(field)) {
        node.children = parseObject(field)
      }

      return node
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })
}

/**
 * @param type
 * @returns
 */
export const cascaderOptions = (contactFields: any) => {
  return parseObject(contactFields)
}

/**
 * @param field
 * @returns
 */
export const parseDataFields = (field: any) => {
  if (Array.isArray(field)) {
    return field
  }

  if (Str.isJSON(field)) {
    return JSON.parse(field)
  }

  return null
}

export const modelName = (model: any) => {
  return Str.startCase(model.title ?? model.name ?? model.slug ?? model.kind)
}

export type ModelSelects = {
  title: string
  help?: string
  data?: any
}

export const parseRequiredSelects = async <
  TContactType extends BaseContactType,
>(
  client: any,
  factory: ContactFactory<TContactType>,
  smartFieldDataFields: any,
  indices: number[],
  onUpdate: (val: any) => void,
) => {
  if (!client?.id) return []

  const allFields = parseDataFields(smartFieldDataFields) // 4
  const fields = [...allFields]

  // handle custom parsing of the fields
  const result = factory.customFieldParser?.(client, allFields, onUpdate)
  if (result) return result

  let selects: ModelSelects[] = []
  let currentObj = client

  while (currentObj) {
    const currentIndex = allFields.length - fields.length
    const currentField = fields.shift() // 3

    let fieldName = currentField.replace('[]', '')
    let nextObj = currentObj[fieldName]

    if (Array.isArray(nextObj)) {
      selects.push({
        title: Str.startCase(fieldName),
        data: nextObj,
      })

      if (indices[currentIndex] !== undefined) {
        currentObj = nextObj[indices[currentIndex]]
      } else {
        currentObj = undefined
      }
    } else if (isObjectLike(nextObj)) {
      currentObj = nextObj
    } else {
      currentObj = undefined

      if (nextObj && factory.contactIdFields?.includes(fieldName)) {
        const contact = await factory.loadContact(nextObj)
        nextObj = factory.contactNameFunc(contact)
      }

      onUpdate(nextObj)

      if (!selects.length && !nextObj) {
        return [
          {
            title: Str.startCase(fieldName),
            data: [],
          },
        ]
      }
    }
  }

  return selects
}
