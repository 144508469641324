import { IntegrationState, isEnabled } from 'constants/integrations'
import { updateHelpHero } from 'helpers/sentry'
import { get } from 'lodash'
import { makeAutoObservable, observable, observe } from 'mobx'
import { Integration as GQLIntegration, IntegrationSync } from 'types/graphql'
import { IntegrationProvider } from './integration.config'

export type Integration = {
  provider: IntegrationProvider
  category?: string | undefined
  createdAt?: string | undefined
  desc: string
  id: number
  name: string
  shortDesc: string
  state: IntegrationState
  sync?: string | undefined
  type: string
}

class Integrations {
  integrations = observable.map<string, Integration>()
  integrationSync: IntegrationSync[] = []

  get _integrations(): Integration[] {
    return Array.from(this.integrations.values())
  }

  get installed() {
    return this._integrations.filter((i) => i.state !== 'HIDDEN')
  }
  get enabled() {
    return this._integrations.filter((i) => isEnabled(i.state))
  }
  get disabled() {
    return this._integrations.filter((i) => i.state !== 'DISABLE')
  }

  get enabledCRM() {
    return get(this.enabled, '[0].name', '')
  }

  get hasCRM() {
    return Boolean(this.enabledCRM)
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    observe(
      this,
      'enabledCRM',
      (c) => updateHelpHero({ enabledCRM: c.newValue }),
      true,
    )
  }

  /**
   * Add integrations from login response
   * @param {*} integrations
   */
  setIntegrations(
    integrations: GQLIntegration[] = [],
    syncs: IntegrationSync[] = [],
  ) {
    const activeIntegrations = (integrations ?? []).reduce(
      (active, integration) => {
        const provider = integration.identifier as IntegrationProvider

        const integrationProvider: Integration = {
          provider,
          category: integration.category as string,
          createdAt: integration.createdAt as string,
          desc: integration.desc as string,
          id: integration.id as number,
          name: integration.name as string,
          shortDesc: integration.shortDesc as string,
          state: integration.state as IntegrationState,
          type: integration.type as string,
        }

        active.set(provider, integrationProvider)
        return active
      },
      new Map<IntegrationProvider, Integration>(),
    )

    this.integrations.replace(activeIntegrations)
    this.integrationSync = syncs
  }

  /**
   *
   */
  getIntegration = (provider: IntegrationProvider): Integration =>
    this._integrations.find(
      (integration) => integration.provider === provider,
    ) as Integration
}

const integrationStore = new Integrations()
export { integrationStore }
