import { PreciseFPConfig } from 'components/drawers/Smartfields/types/integration'
import { GenericReplacer } from '../genericData/generic.replacer'
import { ProviderSmartFieldsReplacerProps } from '../provider.interfaces'
import { FC } from 'react'

export const PreciseFPReplacer: FC<
  ProviderSmartFieldsReplacerProps<PreciseFPConfig>
> = (props) => (
  <GenericReplacer<PreciseFPConfig> {...props} provider={'precisefp'} />
)
