import { pick } from 'lodash'
import { Tag, TagInput } from 'types/graphql'

export const toTagInput = (tag: Tag): TagInput => {
  return pick(tag, [
    'color',
    'createdAt',
    'createdBy',
    'deletedAt',
    'description',
    'name',
    'type',
    'updatedBy',
    'value',
  ])
}

export const toTagsInput = (tags?: Tag[] | null): TagInput[] => {
  return tags?.map(toTagInput) ?? []
}
