import { FileAttachment } from 'components/routes/tasks/notes/notes.utils'
import { CommunicationSource } from 'constants/communications'
import { compact } from 'lodash'
import { practiceStore } from '@state/practice/practice.state'

export interface MailConfig {
  to: string[]
  from?: string
  cc?: string[]
  bcc?: string[]
  subject: string
  markup: string
  content: string
  attachments: FileAttachment[]
  modalTitle: string
  modalWidth: number
  showPassworkField: boolean
  showSubjectField: boolean
  showCCField: boolean
  showBCCField: boolean
  showAttachmentField: boolean
  showPasswordSave: boolean
  closeOnError: boolean
  templateCategoryId?: number
  appointmentId?: number
  contactIds?: number[]
  source?: string
  onSendEmail?: (config: MailConfig) => void
  documentId?: number
}

export const mailConfig = (withBcc = false): MailConfig => {
  return {
    to: [global.data.advisors?.me?.email as string],
    cc: getDefaultCCs(),
    bcc: withBcc ? getDefaultBCCs() : undefined,
    subject: '',
    markup: '',
    content: '',
    attachments: [],
    modalTitle: 'Send Email',
    modalWidth: 600,
    showPassworkField: true,
    showSubjectField: true,
    showCCField: true,
    showBCCField: true,
    showAttachmentField: true,
    showPasswordSave: true,
    closeOnError: false,
    source: CommunicationSource.ORIGINAL,
  }
}

export const getDefaultBCCs = () => {
  return compact([practiceStore?.defaultBcc, global.data.advisors?.me?.defaultBcc])
}

export const getDefaultCCs = (): string[] => {
  return compact([practiceStore?.defaultCc, global.data.advisors?.me?.defaultCc])
}
