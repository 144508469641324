export const DATA_ATTRIB_ID = 'data-mention-atom-id'
export const DATA_ATTRIB_NAME = 'data-mention-atom-name'
const DATA_ATTRIB_LABEL = 'data-label'
export const DATA_ATTRIB_CONFIG = 'data-config'
export const DATA_ATTRIB_REUSE = 'data-reuse'
const DATA_ATTRIB_KIND = 'data-kind'

const ATTRS = {
  id: DATA_ATTRIB_ID,
  name: DATA_ATTRIB_NAME,
  label: DATA_ATTRIB_LABEL,
  reuse: DATA_ATTRIB_REUSE,
  config: DATA_ATTRIB_CONFIG,
  kind: DATA_ATTRIB_KIND,
}

export const DATA_ATTRS = {
  [DATA_ATTRIB_ID]: 'id',
  [DATA_ATTRIB_NAME]: 'name',
  [DATA_ATTRIB_LABEL]: 'label',
  [DATA_ATTRIB_REUSE]: 'reuse',
  [DATA_ATTRIB_CONFIG]: 'config',
  [DATA_ATTRIB_KIND]: 'kind',
}

type AtomAttrType = keyof typeof ATTRS
type AtomDataAttrType = keyof typeof DATA_ATTRS
export type AtomAttrs = Record<AtomAttrType | AtomDataAttrType, any>
