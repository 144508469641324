import { upperFirst } from 'lodash'
import startCaseNoSplitNumbers from './startCase.util'
const slashes = /^\/+|\/+$/g
function stringGuard(s: any) {
  if (typeof s === 'string' || s instanceof String) {
    return s
  }
  return ''
}

const titleize = (s = '') => upperFirst((s || '').toLowerCase())

const startCase = (string = '') => startCaseNoSplitNumbers(string)

const capitalize = (s = '') => {
  const str = stringGuard(s)
  if (str && str[0] && str[0].toUpperCase() === str[0]) {
    return str
  } // if the string is capitalized, respect the string format
  return str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase()) // otherwise make upper case every word's first letter
}

const capitalizeFirstLetter = (s = '') => {
  return stringGuard(s).substr(0, 1).toUpperCase()
}

const prependSlash = (s = '') => `/${s.replace(slashes, '')}`

const noSlashes = (s = '') => s.replace(slashes, '')

const parseJson = (json: string) => {
  let parsed
  try {
    parsed = JSON.parse(json)
  } catch (e) {
    parsed = JSON.parse(JSON.stringify(json))
  }

  return parsed
}

const isJSON = (str: any) => {
  try {
    var obj = JSON.parse(str)
    if (obj && typeof obj === 'object') {
      return true
    }
  } catch (err) {}
  return false
}

const addUrlProtocol = (url: string, protocol = 'https') => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `${protocol}://${url}`
  }
  return url
}

const toBool = (input?: string) => {
  if (!input) {
    return false
  }

  return Boolean(JSON.parse(input))
}

const Str = {
  addUrlProtocol,
  capitalize,
  capitalizeFirstLetter,
  startCase,
  isJSON,
  noSlashes,
  parseJson,
  prependSlash,
  stringGuard,
  titleize,
  toBool,
}

export default Str
