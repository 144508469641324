export const removeReadonly = <T extends any>(arr: T[] | readonly T[]): T[] => arr as T[]

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}

export type DeepReadonly<T> = {
  [P in keyof T]?: T[P] extends object ? DeepReadonly<T[P]> : T[P]
}
