import { FC } from 'react';
import IconBxText from 'lib/remirror/components/icons/BxText.icon'
import IconCircleFill from 'lib/remirror/components/icons/CircleFill.icon'
import IconNounInlineArrow from 'lib/remirror/components/icons/NounInlineArrow.icon'
import IconNumber1 from 'lib/remirror/components/icons/Number1.icon'
import IconParagraph from 'lib/remirror/components/icons/Paragraph.icon'
import IconSquare from 'lib/remirror/components/icons/Square.icon'
import IconTextSlash from 'lib/remirror/components/icons/TextSlash.icon'
import styled from 'styled-components'
import { SmartFieldAtomAttrs } from '../../component'
import { DISPLAY_MODE } from '../../utils/displayMode.constant'

const MentionAtomContainer = styled.span`
  display: inline-flex;
  align-items: center;
`

const DisplayModeIcon: { [key: string]: JSX.Element | null } = {
  [DISPLAY_MODE.PARAGRAPH]: <IconParagraph />,
  [DISPLAY_MODE.INLINE]: <IconNounInlineArrow width="12" height="12" />,
  [DISPLAY_MODE.OLLIST]: <IconNumber1 />,
  [DISPLAY_MODE.ULLIST]: <IconCircleFill viewBox="-5 -5 25 25" width="10" height="10" />,
  [DISPLAY_MODE.CHECKLIST]: <IconSquare viewBox="-5 -5 25 25" width="12" height="12" />,
  default: null,
}

export const MentionAtom: FC<SmartFieldAtomAttrs> = (atom) => {
  const { label, header, displayMode } = atom

  const useHeader = header === 'add' || header === '' || header === 'always'
  const TitleIconComponent = useHeader ? <IconBxText height="14" width="14" /> : <IconTextSlash />
  const IconComponent = DisplayModeIcon[displayMode || 'default']

  return (
    <MentionAtomContainer>
      {label}
      {' - '}
      {IconComponent ? IconComponent : null}
      {TitleIconComponent}
    </MentionAtomContainer>
  )
}
