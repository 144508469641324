import { FC } from 'react';
import { Alert, Form } from 'antd'
import { ConnectionBuilder } from '../connection.builder'
import { useConnection } from '../connection.hooks'

export const PreciseFPBuilder: FC = () => {
  const { connection } = useConnection('precisefp')

  return (
    <ConnectionBuilder
      provider="precisefp"
      title="PreciseFP"
      learnMoreLink="https://precisefp.com/"
      connection={connection}
      key1="dummy-pulse360"
      key2="dummy"
      renderSettings={(form, submit) => (
        <Form onFinish={submit} form={form}>
          <Alert
            message="Click the 'Connect' button to connect your account"
            type="info"
            showIcon
            // style={{ marginBottom: 16 }}
          />
          <Form.Item name="key1" hidden>
            <div />
          </Form.Item>
          <Form.Item name="key2" hidden>
            <div />
          </Form.Item>
        </Form>
      )}
    />
  )
}
