const states = {
  PENDING: "PENDING",
  RESET: "RESET",
  ACTIVE: "ACTIVE",
  LOCKED: "LOCKED",
  DISABLED: "DISABLED",
};

export default states;

export const AUTHENTICATION_PROVIDERS = {
  GOOGLE: "google",
  MICROSOFT: "microsoft",
  EXCHANGE: "ews",
  ICLOUD: "icloud",
  IMAP: "imap",
  // TODO: missing providers in the list
} as const;

export type AuthenticationProvider =
  (typeof AUTHENTICATION_PROVIDERS)[keyof typeof AUTHENTICATION_PROVIDERS];

export const ENABLED_AUTHENTICATION_PROVIDERS: AuthenticationProvider[] = [
  AUTHENTICATION_PROVIDERS.GOOGLE,
  AUTHENTICATION_PROVIDERS.MICROSOFT,
  AUTHENTICATION_PROVIDERS.ICLOUD,
  AUTHENTICATION_PROVIDERS.EXCHANGE,
];
