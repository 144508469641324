import { App } from 'components'
import { CustomRouter } from 'components/routes/router'
import { createRoot } from 'react-dom/client'
import { createStores } from 'stores'
import { StrictMode } from 'react'
import './lib'

function render() {
  const container = document.getElementById('app')
  const root = createRoot(container!)

  root.render(
    <StrictMode>
      <CustomRouter>
        <App />
      </CustomRouter>
    </StrictMode>
  )
}

createStores()
render()
