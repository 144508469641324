import { FC } from 'react';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SmartFieldIntegrationGenericBuilder } from '../genericData/generic.builder'
import { ProviderSmartFieldsBuilderProps } from '../provider.interfaces'
import { contactFields } from './wealthbox.constants'
import { useWealthboxCustomFields } from './wealthbox.hooks'

export const WealthboxBuilder: FC<ProviderSmartFieldsBuilderProps> = (
  props,
) => {
  const { customFields } = useWealthboxCustomFields()
  if (!customFields.length) return null

  return (
    <SmartFieldIntegrationGenericBuilder
      {...props}
      provider="wealthbox-connector"
      providerTitle="Wealthbox"
      dataFields={{
        ...contactFields,
        custom_fields: customFields,
      }}
    />
  )
}
