import { since } from 'helpers/dateRange';
import { SmartField } from '../types';
import { SmartFieldConfig, SmartFieldDateConfig } from '../types/data';
import dayjs, { isDayjs, Dayjs } from 'dayjs';

export const dateFormatter = (smartfield: SmartField, value: unknown) => {
  const config = smartfield.config as SmartFieldConfig<SmartFieldDateConfig>;
  const { date } = config;

  try {
    if (isDayjs(value)) {
      if (date) {
        const dayjsValue = value as Dayjs;

        value = date === 'since' ? since(dayjsValue.toDate()) : dayjsValue.format(date);
      }
    } else if (typeof value === 'string' && dayjs(value).isValid()) {
      value = date === 'since' ? since(value) : dayjs(value).format(date);
    }
  } catch (err) {
    console.log('Unable to format as date!');
  }

  return value;
};
