import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import { EntityId } from 'types/entity.interface'
import { Maybe } from 'types/graphql'

export type BaseContactType = {
  id?: Maybe<string | EntityId>
  [key: string]: any
}

/**
 * The parser should return the following values:
 * - undefined: denotes that the parser has not performed any relevant parsing
 * - []: denotes that parsering was successful and the smartfield updated with a value
 * - [{title: string, data: any[]}][]: denotes that parsering was unsuccessful and should show the manual input
 */
type FieldParserReturnType = { title: string; data: any[] }[] | undefined
export type FieldParserType = (
  client: any,
  allFields: string[],
  onUpdate: (val: any) => void
) => FieldParserReturnType

export abstract class ContactFactory<TContactType extends BaseContactType> {
  abstract selectedContactId: number
  abstract dataFields: string[]
  abstract contactIdFields?: string[]
  contacts: TContactType[] = []
  abstract customFieldParser?: FieldParserType
  abstract loadContacts(name?: string, reset?: boolean): Promise<TContactType[]>
  abstract loadContact(contactId: number): Promise<TContactType | undefined>
  abstract onSelectContact(contactId: number): void
  abstract contactNameFunc(contact?: TContactType): string | undefined
  abstract sortNameFunc(contacts: TContactType[]): TContactType[]

  constructor(readonly provider: SmartFieldProvider) {}
}
