import { FC, ReactNode } from 'react';
import { Form, FormItemProps, Space } from 'antd'
import { ImageIcon } from 'lib/remirror/components/icons/ImageIcon'
import styled from 'styled-components'

const Label = styled.span`
  font-weight: 600;
  font-family: Lato;
`

const Description = styled.div`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
`

const SelectOptionIcon = styled(ImageIcon)`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
`

interface ItemProps extends FormItemProps {
  description: string
  children: ReactNode
}

const FormItem: FC<ItemProps> = ({
  label,
  description,
  children,
  ...formProps
}) => (
  <Form.Item {...formProps}>
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      <Label>{label}</Label>
      <Description>{description}</Description>
      {children}
    </Space>
  </Form.Item>
)

interface FormItemLabelProps {
  label: string
  description?: string
  direction?: 'vertical' | 'horizontal'
}

export const FormItemLabel: FC<FormItemLabelProps> = ({
  label,
  description,
  direction = 'vertical',
}) => (
  <Space direction={direction} style={{ width: '100%' }} size={0}>
    <Label data-testid="form-item-label">{label}</Label>
    {description && (
      <Description data-testid="form-item-desc">{description}</Description>
    )}
  </Space>
)
