/**
 * IMPORTANT
 *
 * please, if you change anything here, update styles.css too.
 */

import { AllStyledComponent } from '@remirror/styles/styled-components'
import { RemirrorThemeType } from 'remirror'
import styled from 'styled-components'
import { styles } from 'styles'

export const theme: RemirrorThemeType = {
  fontFamily: {
    default: "'Lato', Arial, Helvetica, sans-serif",
  },
  lineHeight: {
    default: 1.5,
    heading: 1.5,
  },
  fontWeight: {
    default: '400',
    heading: '700',
    bold: '700',
  },
  fontSize: {
    default: 14,
    0: 9.38,
    /* h5 */
    1: 11.62,
    /* h4 */
    2: 14,
    /* h3 */
    3: 16.38,
    /* h2 */
    4: 21,
    /* h1 */
    5: 28,

    6: 48,
    7: 64,
    8: 96,
  },
}

interface RemirrorProps {
  $bordered?: boolean
}

export const EditorWrapper = styled(AllStyledComponent)<RemirrorProps>`
  /**
 * IMPORTANT
 *
 * css only needed in the manipulation of the editor. They are not included in the email 👇
 */

  a {
    color: #0000ee;
    text-decoration: none;
  }

  p {
    color: #000000;
  }

  &.preview-mode {
    .ProseMirror {
      ${(p) => !p.$bordered && `border: 0 !important;`}
    }
  }

  &.edit-mode {
    ${(p) =>
      p.$bordered &&
      `

      border-left: 1px solid ${styles.colors.gray5};
      border-right: 1px solid ${styles.colors.gray5};

      .toolbar {
        border-top: 1px solid ${styles.colors.gray5};
      }
  `}
  }

  .remirror-editor {
    overflow-y: auto !important;
    padding: 0 !important;
  }

  &.card-mode {
    border: 0;
    .remirror-editor-wrapper {
      border: 1px solid #eaeaea;
      border-radius: 8px;
      padding: 16px;
      margin-top: 8px;
    }
  }

  .remirror-floating-popover {
    z-index: 102;
  }

  .remirror-table-tbody-with-controllers > tr:nth-of-type(1) th:nth-of-type(n + 2) {
    z-index: 9;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2)
    div.remirror-table-controller-trigger-area {
    z-index: 8;
  }

  /* MENTION ATOM: change styles */
  .remirror-mention-atom {
    color: #000000;
    line-height: var(--rmr-line-height-default);
    margin: 0 0.2em;
    padding: 0.2em 0.4em 0.1em;
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
    padding-inline: 0.4em;
    padding-block: 0.2em 0.1em;
    white-space: break-spaces;
    display: inline-block;
  }

  .remirror-mention-atom.active {
    border: 1px solid #16ac19;
    animation: smartfieldPulse 2s infinite;
  }

  @keyframes smartfieldPulse {
    0% {
      box-shadow: 0 0 3px 0 rgba(22, 172, 25, 1);
    }
    50% {
      box-shadow: 0 0 3px 0 rgba(22, 172, 25, 0);
    }
    100% {
      box-shadow: 0 0 3px 0 rgba(22, 172, 25, 1);
    }
  }

  .remirror-mention-atom.remirror-mention-atom-command {
    font-weight: normal;
  }

  .remirror-mention-atom.remirror-mention-atom-bold {
    font-weight: bolder;
  }

  .remirror-mention-atom.remirror-mention-atom-text-highlight {
    background: transparent;
    border: 1px solid rgba(100, 100, 100, 0.2);
  }

  .remirror-mention-atom.remirror-mention-atom-text-color {
    color: inherit;
  }

  .remirror-mention-atom.remirror-mention-atom-italic {
    font-style: italic;
  }

  /* FONT: show the font in the font selector */

  .ant-dropdown-menu-item[data-menu-id*='Arimo'] {
    font-family: 'Arimo', sans-serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Crimson Text'] {
    font-family: 'Crimson Text', serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Dancing Script'] {
    font-family: 'Dancing Script', cursive;
  }

  .ant-dropdown-menu-item[data-menu-id*='EB Garamond'] {
    font-family: 'EB Garamond', serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Lato'] {
    font-family: 'Lato', sans-serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Montserrat'] {
    font-family: 'Montserrat', sans-serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Old Standard TT'] {
    font-family: 'Old Standard TT', serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Open Sans'] {
    font-family: 'Open Sans', sans-serif;
  }

  .ant-dropdown-menu-item[data-menu-id*='Roboto'] {
    font-family: 'Roboto', sans-serif;
  }

  /**
 * IMPORTANT
 *
 * changes in the document styles here. These should be reflected in styles.css 👇
 */

  mark {
    color: #000000;
    padding: inherit;
    /* background: inherit; */
  }

  a mark {
    color: #0000ee;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 0;
  }

  .remirror-list-item-checkbox {
    vertical-align: middle;
  }

  img {
    max-width: 100%;
    min-width: 20px;
    object-fit: contain;
  }
`
