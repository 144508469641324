import { isConnected } from 'constants/connections'
import { isEnabled } from 'constants/integrations'
import posthog from 'posthog-js'

export const identifyUser = ({
  name,
  user,
  practice,
  connections,
  integrations,
  advisorsCount,
  appointmentsCount,
  clientsCount,
  notesCount
}: any) => {
  const identifier = `${name}:${user.email}`
  const emailProvider = user?.creds?.authentication

  const crmData =
    integrations && Array.isArray(integrations)
      ? integrations.find((integration) => isEnabled(integration.state))
      : null

  const hasRiskalyzeActive = Boolean(
    connections.find(
      (connection: any) => connection.identifier === 'riskalyze' && isConnected(connection.state),
    ),
  )

  const hasPreciseFpActive = Boolean(
    connections.find(
      (connection: any) => connection.identifier.includes('precisefp') && isConnected(connection.state),
    ),
  )

  const hasHolistiplanActive = Boolean(
    connections.find(
      (connection: any) => connection.identifier.includes('holistiplan') &&
      isConnected(connection.state),
    ),
  )

  const hasWealthboxDataActive = Boolean(
    connections.find(
      (connection: any) => connection.identifier.includes('wealthbox-connector') &&
      isConnected(connection.state),
    ),
  )

  const lastLogin = new Date().toISOString()

  const valuesToSet = {
    riskalyzeActive: hasRiskalyzeActive,
    precisefpActive: hasPreciseFpActive,
    holistiplanActive: hasHolistiplanActive,
    wealthboxDataActive: hasWealthboxDataActive,
    numOfAppointments: appointmentsCount,
    numOfAdvisors: advisorsCount,
    numOfClients: clientsCount,
    numOfNotes: notesCount,
    crm: crmData?.identifier,
    lastLogin,
  }

  const valuesToSetOnce = {
    workspace: name,
    email: user.email,
    emailProvider,
    nickname: user.nickName,
    gender: user.gender,
    city: practice.city,
    country: practice.country,
    timezone: practice.tz,
    firmType: practice.type,
  }
  posthog.identify(identifier, valuesToSet, valuesToSetOnce)
  const toolbarJSON = new URLSearchParams(
    window.location.hash.substring(1),
  ).get('__posthog')
  if (toolbarJSON) {
    posthog.loadToolbar(JSON.parse(toolbarJSON))
  }
}

export function trackEvent(name: any, data = {}) {
  posthog.capture(name, data)

  // @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
  window.Intercom('trackEvent', name, data)
}
