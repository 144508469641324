import {
  ApplySchemaAttributes,
  extension,
  ExtensionPriority,
  NodeSpecOverride
} from '@remirror/core'
import {
  TableControllerCellExtension,
  TableHeaderCellExtension,
  TableRowExtension
} from '@remirror/react'
import { TableCellCustomExtension } from './TableCellCustom.extension'

import { createTableNodeSchema } from './TableCustom.utils'
import { TableSchemaSpec } from 'remirror/extensions'

// @ts-ignore
@extension({ defaultPriority: ExtensionPriority.Low })
export class TableRowCustomExtension extends TableRowExtension {
  /**
   * Automatically create the `TableCellExtension` and
   * `TableHeaderCellExtension`. This is placed here so that this extension can
   * be tested independently from the `TableExtension`.
   */
  createExtensions(): Array<
    | TableCellCustomExtension
    | TableHeaderCellExtension
    | TableControllerCellExtension
  > {
    return [
      new TableCellCustomExtension({ priority: ExtensionPriority.Low }),
      new TableHeaderCellExtension({ priority: ExtensionPriority.Low })
    ]
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): TableSchemaSpec {
    return createTableNodeSchema(extra, override).tableRow
  }
}
