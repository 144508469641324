import { PreciseFpClient } from 'types/graphql'
import { ContactFactory } from '../genericData/generic.factory'
import { listAccounts, loadAccount } from 'stores/services/precisefp.service'
import { compact, isEmpty } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { contactFields } from './precisefp.constants'

export class PreciseFPContactFactory extends ContactFactory<PreciseFpClient> {
  dataFields: any = contactFields
  contactIdFields?: string[] = []
  selectedContactId: number
  customFieldParser = undefined
  contacts = observable.array<PreciseFpClient>([])

  constructor() {
    super('precisefp')
    makeObservable(this, {
      selectedContactId: observable,
      contacts: observable,
    })
  }

  loadContacts = async (name: string = '', reset: boolean = false): Promise<PreciseFpClient[]> => {
    if (isEmpty(name) && this.contacts.length && !reset) {
      return this.contacts
    }

    const accounts = await listAccounts({ query: name })
    const contacts = compact(accounts?.map((account) => account.client))

    this.contacts.replace(this.sortNameFunc(contacts))
    return this.contacts
  }

  loadContact = async (contactId: number): Promise<PreciseFpClient> => {
    const account = await loadAccount(String(contactId))
    return account?.client as PreciseFpClient
  }

  onSelectContact = (contactId: number): void => {
    this.selectedContactId = contactId
  }

  contactNameFunc = (contact: PreciseFpClient): string | undefined => {
    return 'Unknown contact'
  }

  sortNameFunc = (contacts: PreciseFpClient[]): PreciseFpClient[] => {
    return [...contacts].sort((a, b) => {
      const aName = a?.first_name as string
      const bName = b?.last_name as string

      if (!aName) return 1
      if (!bName) return -1
      return aName.localeCompare(bName)
    })
  }
}
