import { Mark } from 'remirror'

export function emptyMergeFieldContent(
  mergeFieldType: string,
  pos: number,
  marks: readonly Mark[]
) {
  return {
    type: mergeFieldType,
    pos: pos,
    content: [],
    contentSize: 0,
    marks
  }
}
