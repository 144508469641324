/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Modal } from 'antd'
import { msg } from 'stores/msg'
import { ButtonType } from 'antd/lib/button'
import { MailConfig } from 'lib/data/mail/mail.config'

/**
 * @param err
 * @param config
 */
export const sendEmailInvariant = (err: any, config?: MailConfig) => {
  const connectModalConfig = {
    title: 'The send this email, you need to link your email account to Pulse 360',
    content: `Press Link to open a new tab to complete this. When you're done, come back here and you will be to send the email.`,
    okText: 'Link',
    cancelText: 'No, later',
    okType: 'danger' as ButtonType,
    maskClosable: true,
    onOk: async () => global.mail.openConnectEmailUrl(),
  }
  if (err.message && err.message === 'Could not verify access credential.') {
    Modal.confirm({
      ...connectModalConfig,
      title: 'The connection to your email needs reauthentication.',
      content: `Press Reauthenticate to open a new tab to complete this. When you're done, come back here and you will be to send the email.`,
      okText: 'Reauthenticate',
    })
  } else if (err.code === 'credentials_error') {
    Modal.confirm(connectModalConfig)
  } else {
    msg.error(`Email Error`, err.message)
  }

  if (config?.closeOnError) {
    global.mail.close()
  }
}
