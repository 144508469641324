import { FC } from 'react';
import { WarningOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { getProviderConfig } from 'components/drawers/Smartfields/integration/providers/provider.config'
import { FormItemLabel } from 'components/drawers/Smartfields/smartfield.styles'
import {
  SmartField,
  SmartFieldProvider,
} from 'components/drawers/Smartfields/types'
import {
  SmartFieldConfig,
  SmartFieldDateConfig,
  SmartFieldIntegrationConfig,
  SmartFieldListConfig,
} from 'components/drawers/Smartfields/types/data'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'
import RemirrorPreviewEditor from 'lib/remirror/PreviewEditor'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react';
import { smartfieldStore as sfStore } from 'stores/smartfields'
import { highlightActive } from 'stores/smartfields/utils'
import styled from 'styled-components'
import { styles } from 'styles'
import { Note } from 'types/graphql'
import { useReplaceSmartfieldsModal } from './hooks'
import { SmartFieldModal } from './index.styles'
import { formatDesc, formatLabel } from './utils'

const NextButton = styled(Button)`
  &:hover,
  &:focus {
    background: ${styles.colors.primary} !important;
    color: white !important;
    outline: none;
  }
`

type IntegrationProviderProps = {
  provider: SmartFieldProvider
  form: FormInstance
}

const IntegrationProvider: FC<IntegrationProviderProps> = ({
  provider,
  form,
}) => {
  const providerConfig = getProviderConfig(provider)
  if (!providerConfig) return <></>

  const { smartfieldReplacer: ReplacerComponent, permissionKey } =
    providerConfig

  if (!global.perms.isAvailable(permissionKey!)) return <></>

  return (
    <ReplacerComponent
      uid={sfStore.smartfield?.id}
      note={sfStore.note as Note}
      form={form}
      config={
        sfStore.smartfield?.config as SmartFieldConfig<
          SmartFieldIntegrationConfig<HolistiplanConfig>
        >
      }
    />
  )
}

export const ReplaceSmartFields: FC = observer(() => {
  const [disable, setDisable] = useState<boolean>(false)
  const [editorRef, setEditorRef] = useState<HTMLElement>()
  const { visible } = useReplaceSmartfieldsModal()
  const [form] = Form.useForm()

  const onAction = useCallback(
    (action: () => void) => {
      action?.()
      form.resetFields()
    },
    [form],
  )

  useEffect(() => {
    const fn = global.bus.on('DISABLE_SMARTFIELD_CHANGE', setDisable)
    return () => {
      global.bus.off('DISABLE_SMARTFIELD_CHANGE', fn)
    }
  }, [])

  useEffect(() => {
    return autorun(() => {
      if (sfStore.smartfield && editorRef) {
        const selector = `[data-mention-atom-id="${sfStore.smartfield.id}"]`
        const node = editorRef?.querySelector(selector)

        // scroll the active smartfield into view
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        // highlight the active smartfield
        highlightActive(editorRef, sfStore.smartfield)
      }
    })
    // leave - this needs to rerender when sfStore.note changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, form, sfStore.note])

  return (
    <SmartFieldModal
      title={
        <Space>
          <WarningOutlined
            style={{
              color: styles.colors.red,
              fontSize: 20,
            }}
          />
          <Typography.Text style={{ fontSize: 18 }}>
            {sfStore.modalTitle}
          </Typography.Text>
        </Space>
      }
      onCancel={() => sfStore.hideReplaceSmartFieldModal(true)}
      footer={null}
      okText="Replace"
      open={visible}
      destroyOnClose
      maskClosable={false}
      width={'55vw'}
    >
      <Space
        direction="vertical"
        style={{ paddingTop: 12, width: '100%' }}
        size={16}
      >
        <Alert
          message="Enter values for the required SmartFields below"
          type="info"
          showIcon
        />

        <Form
          form={form}
          onValuesChange={sfStore.update}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item>
            <Tooltip title="SmartField replacement preview">
              <div className="note-preview">
                <Typography.Text className="note-preview--title">
                  Preview
                </Typography.Text>
                <RemirrorPreviewEditor
                  content={sfStore.text}
                  classNames={['no-min-height']}
                  disableAtomSync
                  onDOMNode={setEditorRef}
                />
              </div>
            </Tooltip>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Typography.Title level={5}>{sfStore.noteTitle}</Typography.Title>
          </Form.Item>

          {sfStore.smartfield?.config?.type === 'date' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel
                  label={formatLabel(sfStore.smartfield)}
                  description={formatDesc(sfStore.smartfield)}
                />
              }
              initialValue={sfStore.currentValue}
              data-testid="replace-smartfield-date"
            >
              <DatePicker
                size="large"
                style={{ width: '100%' }}
                format={
                  (sfStore.smartfield as SmartField<SmartFieldDateConfig>)
                    ?.config?.date
                }
                placeholder="Select a date"
              />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'list' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel
                  label={formatLabel(sfStore.smartfield)}
                  description={formatDesc(sfStore.smartfield)}
                />
              }
              initialValue={sfStore.currentValue}
              data-testid="replace-smartfield-list"
            >
              <Select
                options={(
                  sfStore.smartfield
                    ?.config as SmartFieldConfig<SmartFieldListConfig>
                ).list
                  ?.slice()
                  ?.sort((a, b) => a?.label?.localeCompare(b.label))}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select an option"
                optionFilterProp="children"
                size="large"
                showSearch
              />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'number' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel
                  label={formatLabel(sfStore.smartfield)}
                  description={formatDesc(sfStore.smartfield)}
                />
              }
              initialValue={sfStore.currentValue}
              data-testid="replace-smartfield-number"
            >
              <InputNumber
                autoFocus
                size="large"
                style={{ width: '100%' }}
                placeholder="Input a number"
              />
            </Form.Item>
          )}

          {sfStore.smartfield?.config?.type === 'text' && (
            <Form.Item
              name={sfStore.smartfield?.id}
              label={
                <FormItemLabel
                  label={formatLabel(sfStore.smartfield)}
                  description={formatDesc(sfStore.smartfield)}
                />
              }
              initialValue={sfStore.currentValue}
              data-testid="replace-smartfield-text"
            >
              <Input autoFocus size="large" placeholder="Input your text" />
            </Form.Item>
          )}

          {/* Integrations */}
          {sfStore.smartfield?.config?.type === 'integration' && (
            <div data-testid="replace-smartfield-integration">
              <IntegrationProvider
                form={form}
                provider={
                  (
                    sfStore.smartfield
                      ?.config as SmartFieldConfig<SmartFieldIntegrationConfig>
                  ).provider
                }
              />
            </div>
          )}

          {sfStore.note?.isMirrored ? (
            <Alert
              message="Updating this SmartField will convert this Mirrored note into a regular note"
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
            />
          ) : null}

          <Form.Item>
            <Row style={{ width: '100%' }} justify="space-between">
              <Button
                size="large"
                onClick={() =>
                  onAction(() => {
                    sfStore.hideReplaceSmartFieldModal(true)
                    setDisable(false)
                  })
                }
                data-testid="cancel-button"
                tabIndex={2}
              >
                Cancel
              </Button>

              <Space>
                {!sfStore.firstField && (
                  <Button
                    size="large"
                    onClick={() =>
                      onAction(() => {
                        sfStore.previousSmartfield()
                      })
                    }
                    data-testid="previous-smartfield-button"
                    tabIndex={1}
                    disabled={disable}
                  >
                    Previous
                  </Button>
                )}

                {/* Further smartfields to replace, move to next one */}
                {!sfStore.lastField ? (
                  <NextButton
                    type="primary"
                    size="large"
                    ghost
                    onClick={() =>
                      onAction(() => {
                        sfStore.nextSmartfield()
                      })
                    }
                    data-testid="next-smartfield-button"
                    tabIndex={0}
                    disabled={disable}
                    loading={disable}
                  >
                    Next SmartField
                  </NextButton>
                ) : (
                  <NextButton
                    type="primary"
                    size="large"
                    ghost
                    onClick={() =>
                      onAction(() => {
                        sfStore.finish()
                      })
                    }
                    data-testid="finish-button"
                    tabIndex={0}
                    disabled={disable}
                    loading={disable}
                  >
                    Finish
                  </NextButton>
                )}
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Space>
    </SmartFieldModal>
  )
})
