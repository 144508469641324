import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import {
  leadingTrailingBackSlashes as backSlashes,
  leadingTrailingSlashes as slashes,
} from 'constants/regex'
import { isEqual, merge } from 'lodash'
import { useMemo } from 'react'
import { authLink, errorLink, logLink, uploadLink, wsLink } from './links'
import { defaultOptions } from './utils'

const URL = import.meta.env.VITE_APP_API_ENDPOINT || 'https://api.dev.pulse360.com/graphql'
const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

/**
 * Splits operations between standard and subscription links
 * @param url
 * @returns
 */
const splitLink = (url: string): ApolloLink => {
  return ApolloLink.split(
    (op: Operation): boolean => {
      const definition = getMainDefinition(op.query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    ApolloLink.from([authLink(), errorLink(), wsLink(url)]),
    ApolloLink.from([authLink(), errorLink(), uploadLink(url)])
  )
}

let apolloClient: ApolloClient<NormalizedCacheObject>

/**
 * Creates initial apollo client instance
 * @returns
 */
const createApolloClient = () => {
  const url = URL.replace(slashes, '').replace(backSlashes, '')
  console.log('GraphQLEndpoint: ' + url)

  const links: ApolloLink[] = [splitLink(url)]
  if (process.env.NODE_ENV !== 'test') {
    links.unshift(logLink())
  }

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache(),
    link: ApolloLink.from(links),
    defaultOptions: defaultOptions,
  })
}

/**
 * Initializes the apollo client instance with SSR data
 * @param initialState
 * @returns
 */
export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient()

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((d: any) => sourceArray.every((s: any) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return _apolloClient
}

/**
 * @param client
 * @param pageProps
 * @returns
 */
function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

/**
 * Use apollo client in react function components
 * @param pageProps
 * @returns
 */
export function useApollo(pageProps?: any) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME] ?? {}
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}

export * from './utils'
