import { FC } from 'react';
import { Form, Input } from 'antd'
import { ConnectionBuilder } from '../connection.builder'
import { useConnection } from '../connection.hooks'

export const ZohoBuilder: FC = () => {
  const { connection } = useConnection('zoho')

  return (
    <ConnectionBuilder
      provider="zoho"
      title="Zoho"
      learnMoreLink="zoho.com"
      connection={connection}
      renderSettings={(form, submit) => (
        <Form onFinish={submit} form={form}>
          <Form.Item
            label="Client ID"
            name="key1"
            rules={[{ required: true, message: 'Enter Client ID!' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Client Secret"
            name="key2"
            rules={[{ required: true, message: 'Enter Client Secret!' }]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
      )}
    />
  )
}
