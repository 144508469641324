import { Maybe, Summary, SummaryInput } from 'types/graphql'
import { isSummary } from '@helpers/mappers/discriminators'
import { compact } from 'lodash'
import { toCollectionRelationInput } from './collection'
import { toTagInput } from './tags'
import { toTaskTemplateInput } from './task'
import { toWorkflowTemplateInput } from './workflow'
import { toContentInput, toTextInput } from './content'

export const toSummaryInput = (summary: Maybe<Summary>): SummaryInput => {
  if (!summary) {
    return {} as SummaryInput
  }

  if (!isSummary(summary)) {
    throw new Error('Summary is not a Summary')
  }

  const input = {
    collections: compact(summary.collections)?.map((collection) =>
      toCollectionRelationInput(collection.relation)
    ),
    content: toContentInput(summary),
    dueDays: summary.dueDays ?? 0,
    file1: summary.file1 ?? undefined,
    file2: summary.file2 ?? undefined,
    file3: summary.file3 ?? undefined,
    isAgenda: summary.isAgenda ?? false,
    isDefault: summary.isDefault ?? false,
    isFavourite: summary.isFavourite ?? false,
    isPrivate: summary.isPrivate ?? false,
    isSummary: summary.isSummary ?? false,
    tags: compact(summary.tags)?.map((tag) => toTagInput(tag)),
    text: toTextInput(summary),
    topicId: summary.topicId,
    taskTemplate: toTaskTemplateInput(summary.taskTemplate),
    workflowTemplate: toWorkflowTemplateInput(summary.workflowTemplate),
  }

  return {
    collections: compact(summary.collections)?.map((collection) =>
      toCollectionRelationInput(collection.relation)
    ),
    content: toContentInput(summary),
    dueDays: summary.dueDays ?? 0,
    file1: summary.file1 ?? undefined,
    file2: summary.file2 ?? undefined,
    file3: summary.file3 ?? undefined,
    isAgenda: summary.isAgenda ?? false,
    isDefault: summary.isDefault ?? false,
    isFavourite: summary.isFavourite ?? false,
    isPrivate: summary.isPrivate ?? false,
    isSummary: summary.isSummary ?? false,
    tags: compact(summary.tags)?.map((tag) => toTagInput(tag)),
    text: toTextInput(summary),
    topicId: summary.topicId!,
    taskTemplate: toTaskTemplateInput(summary.taskTemplate),
    workflowTemplate: toWorkflowTemplateInput(summary.workflowTemplate),
  }
}
