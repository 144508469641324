import { isWorkflow, isWorkflowTemplate } from '@helpers/mappers/discriminators'
import {
  Maybe,
  Workflow,
  WorkflowInput,
  WorkflowTemplate,
  WorkflowTemplateInput,
} from 'types/graphql'
import { compact, pick } from 'lodash'
import { toDateString } from '../date'
import { isNumber } from 'remeda'

export const toWorkflowInput = (workflow?: Maybe<Workflow>): WorkflowInput | undefined => {
  if (!workflow) {
    return undefined
  }

  if (!isWorkflow(workflow)) {
    throw new Error('Workflow is not a Workflow')
  }

  return {
    ...pick(workflow, [
      'completed',
      'completedAt',
      'createdBy',
      'customFields',
      'name',
      'provider',
      'updatedBy',
    ]),
    endDate: toDateString(workflow?.endDate, true) as string,
    linkedTo: isNumber(workflow.linkedTo)
      ? [workflow.linkedTo]
      : (compact(workflow.linkedTo)
          ?.map((client) => client?.id)
          .filter(Boolean) ?? []),
    noteId: Number(workflow.noteId),
    startDate: toDateString(workflow?.startDate, true) as string,
  }
}

export const toWorkflowTemplateInput = (
  template?: Maybe<WorkflowTemplate>
): WorkflowTemplateInput | undefined => {
  if (!template) {
    return undefined
  }

  if (!isWorkflowTemplate(template)) {
    throw new Error('WorkflowTemplate is not a WorkflowTemplate')
  }

  return {
    ...pick(template, ['customFields', 'name', 'provider', 'summaryId']),
    endDate: toDateString(template.endDate, true) as string,
    id: Number(template.id),
    startDate: toDateString(template.startDate, true) as string,
  }
}
