import { FC } from 'react';
import { ConnectionIcon } from '../connection.interfaces'

export const RecallZoomIcon: FC<ConnectionIcon> = () => (
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <g fill-rule="nonzero">
      <path
        d="M100 50c0 5.347-.43 10.59-1.251 15.702-2.718 16.923-16.124 30.329-33.047 33.047A98.964 98.964 0 0150 100c-5.346 0-10.589-.43-15.702-1.251C17.375 96.031 3.969 82.625 1.251 65.702A98.955 98.955 0 010 50c0-5.347.43-10.59 1.251-15.702C3.969 17.375 17.375 3.969 34.298 1.251A98.973 98.973 0 0150 0c5.347 0 10.589.43 15.702 1.251 16.923 2.718 30.329 16.124 33.047 33.047A98.955 98.955 0 01100 50z"
        fill="url(#prefix___Linear1)"
        transform="matrix(5.11 0 0 5.11 .709 .331)"
      />
      <path
        d="M153.304 294.958h-63.38a10.928 10.928 0 01-10.122-6.765 10.923 10.923 0 012.376-11.937l43.9-43.9H94.615c-8.646 0-15.651-7.006-15.651-15.652h58.448c4.45 0 8.421 2.652 10.123 6.76a10.931 10.931 0 01-2.371 11.942l-43.906 43.9h36.394c8.646 0 15.652 7.006 15.652 15.652zm280.15-48.908c0-16.827-13.69-30.522-30.516-30.522-9.004 0-17.104 3.92-22.694 10.139-5.595-6.22-13.695-10.139-22.693-10.139-16.833 0-30.523 13.69-30.523 30.522v48.908c8.647 0 15.652-7.006 15.652-15.652V246.05c0-8.201 6.669-14.87 14.87-14.87 8.197 0 14.866 6.669 14.866 14.87v33.256c0 8.646 7.005 15.652 15.651 15.652V246.05c0-8.201 6.669-14.87 14.87-14.87 8.197 0 14.866 6.669 14.866 14.87v33.256c0 8.646 7.01 15.652 15.651 15.652V246.05zm-114.249 9.781c0 22.26-18.043 40.303-40.302 40.303-22.26 0-40.303-18.044-40.303-40.303 0-22.26 18.043-40.303 40.303-40.303 22.259 0 40.302 18.044 40.302 40.303zm-15.652 0c0-13.613-11.037-24.65-24.65-24.65-13.614 0-24.651 11.037-24.651 24.65 0 13.613 11.037 24.65 24.65 24.65 13.614 0 24.651-11.037 24.651-24.65zm-71.213 0c0 22.26-18.043 40.303-40.297 40.303-22.26 0-40.303-18.044-40.303-40.303 0-22.26 18.044-40.303 40.303-40.303 22.254 0 40.297 18.044 40.297 40.303zm-15.652 0c0-13.613-11.032-24.65-24.645-24.65-13.618 0-24.65 11.037-24.65 24.65 0 13.613 11.032 24.65 24.65 24.65 13.613 0 24.645-11.037 24.645-24.65z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix___Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-60 94.635 27.31) scale(105.3358)"
      >
        <stop offset="0" stop-color="#0845bf" />
        <stop offset=".6" stop-color="#0b5cff" />
        <stop offset="1" stop-color="#4f90ee" />
      </linearGradient>
    </defs>
  </svg>
)
