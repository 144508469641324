import { TAG, TagTypes } from 'constants/tags'
import { trackEvent } from 'helpers/posthog'
import { computed, makeObservable, observable, toJS } from 'mobx'
import { captureExceptionSilently } from '../helpers/sentry'
import { msg } from './msg'
import tagsService from './services/tags.service'
import { Tag } from 'types/graphql'
import { toTagInput } from '@helpers/mappers/inputs/tags'

const lc = (str = '') => (str ? str.toLowerCase() : '')

class Tags {
  _tags = observable.map<string, Tag>()

  userTagsFilter = (tag: Tag) =>
    tag.type === TagTypes.USER ||
    [TAG.ADD_TO_MEETING, TAG.AGENDA_LEGACY, TAG.SUMMARY_LEGACY].includes(tag.value!)

  constructor() {
    makeObservable(this, {
      _tags: observable,
      tags: computed,
      deletedTags: computed,
      allTags: computed,
      rawTags: computed,
      userTags: computed,
      subtopics: computed,
    })
  }

  get tags(): Tag[] {
    return Array.from(this._tags.values()).filter((t) => !t.deletedAt)
  }

  get deletedTags(): Tag[] {
    return Array.from(this._tags.values()).filter((t) => t.deletedAt)
  }

  get allTags(): Tag[] {
    return Array.from(this._tags.values())
  }

  get rawTags(): Tag[] {
    return toJS(this.tags, { recurseEverything: true })
  }

  get userTags(): Tag[] {
    return this.tags.filter(this.userTagsFilter).sort((a, b) => a.name!.localeCompare(b.name!))
  }

  get subtopics(): Tag[] {
    return this.tags.filter((t) => t.type === TagTypes.SUBTOPIC)
  }

  /**
   * @param {*} tags
   */
  setTags = (tags: Tag[]) => {
    this._tags.replace(tags.map((t) => [t.value!, t]))
  }

  /**
   * @param {*} item
   * @param {*} tagValue
   */
  hasTag = (item: any, tagValue: any): boolean => {
    return this._tags.has(tagValue)
  }

  /**
   * @param {*} tag
   */
  isMutable = (tag: Tag): boolean => {
    return !tag.type!.includes('SYSTEM')
  }

  /**
   * Find a tag by value
   * @param {*} value
   */
  get = (value: any): Tag => {
    return this._tags.get(lc(value))!
  }

  /**
   * Adds a tag to a model
   * @param {*} item The item object to add a tag to
   * @param {*} model The nodejs model type, ie. 'note'
   * @param {*} tagValue Value of the tag to add, ie. 'client'
   */
  addTag = async (item: any, model: any, tagValue: any) => {
    const args = { id: item.id, model, tagValue }
    try {
      const tagAdded = await tagsService.addTag(args)
      item.tags = [...item.tags, tagAdded].sort((a, b) => a.name.localeCompare(b.name))

      trackEvent('Tag Added', args)

      this.updateRelatedDataStore(model, [item], {})
      return tagAdded
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'addTag', data: { args } })
      msg.error(err.message)
    }
  }

  /**
   * Add tags to multiple models
   * @param {*} items The item objects to add a tag to
   * @param {*} model The nodejs model type, ie. 'note'
   * @param {*} tagValue Value of the tag to add, ie. 'client'
   */
  addTags = async (items: any, model: any, tagValue: any) => {
    const args = { ids: items.map((i: any) => i.id), model, tagValue }
    try {
      const tagAdded = await tagsService.addTags(args)

      items.forEach((item: any) => {
        trackEvent('Tag Added', { id: item.id, model, tagValue })
        item.tags = [...item.tags, tagAdded]
      })

      this.updateRelatedDataStore(model, items, {})
      return tagAdded
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'addTags', data: { args } })
      msg.error(err.message)
    }
  }

  /**
   * Removes a tag from a model
   * @param {*} item The item object to add a tag to
   * @param {*} model The nodejs model type, ie. 'note'
   * @param {*} tagValue Value of the tag to remove, ie. 'client'
   */
  removeTag = async (item: any, model: any, tagValue: any) => {
    const args = { id: item.id, model, tagValue }
    try {
      const removedTag = await tagsService.removeTag(args)
      if (removedTag) {
        item.tags = item.tags.filter((t: any) => t.value !== tagValue)
        this.updateRelatedDataStore(model, [item], {})
      }

      return removedTag
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'removeTag', data: { args } })
      msg.error(err.message)
    }
  }

  /**
   * Removes a tag from multiple models
   * @param {*} items The item objects to add a tag to
   * @param {*} model The nodejs model type, ie. 'note'
   * @param {*} tagValue Value of the tag to add, ie. 'client'
   */
  removeTags = async (items: any, model: any, tagValue: any) => {
    const args = { ids: items.map((i: any) => i.id), model, tagValue }
    try {
      const removedTags = await tagsService.removeTags(args)
      if (removedTags) {
        items.forEach((item: any) => {
          item.tags = item.tags.filter((t: any) => t.value !== tagValue)
        })
        this.updateRelatedDataStore(model, items, {})
      }
      return removedTags
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'removeTags', data: { args } })
      msg.error(err.message)
    }
  }

  /**
   * @param {*} values
   */
  createTag = async (values: any) => {
    try {
      const createdTag = await tagsService.createTag(values)
      trackEvent('Tag Created', { ...values })

      if (createdTag) {
        this._tags.set(createdTag.value!, createdTag)
      }

      return createdTag
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'createTag', data: { values } })
      msg.error(err.message)
    }
  }

  /**
   * @param {*} tag
   * @param {*} values
   */
  updateTag = async (tag: Tag, values: Partial<Tag>) => {
    try {
      const updatedTag = await tagsService.updateTag(tag.value!, {
        ...toTagInput({ ...tag, ...values }),
      })

      Object.keys(values).forEach((key) => {
        ;(tag as any)[key] = (values as any)[key]
      })

      const toUpdate = this._tags.get(tag.value!)
      if (toUpdate && updatedTag) {
        tag = { ...tag, ...updatedTag }
        this._tags.set(tag.value!, updatedTag)
      }

      return updatedTag
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'updateTag', data: { tag } })
      msg.error(err.message)
    }
  }

  /**
   * @param {*} tag
   */
  destroyTag = async (tag: any, replacementValue: any) => {
    try {
      const destroyedTag = await tagsService.destroyTag(tag.value, replacementValue)
      if (destroyedTag) {
        trackEvent('Tag Deleted', { value: tag.value })
        tag.deletedAt = new Date()
        return destroyedTag
      }
    } catch (err) {
      captureExceptionSilently(err, { message: 'destroyTag', data: { tag } })
      msg.error(['tag', 'deleting'])
    }
  }

  /**
   * @param {*} tag
   */
  restoreTag = async (tag: any) => {
    try {
      const restoredTag = await tagsService.restoreTag(tag.value)
      if (restoredTag) {
        delete tag.deletedAt
        return restoredTag
      }
    } catch (err) {
      captureExceptionSilently(err, { message: 'restoreTag', data: { tag } })
      msg.error(['tag', 'restoring'])
    }
  }

  /**
   * @param {*} resp
   */
  validationError = (resp: any, error: any) => {
    if (resp.error) {
      const { message, rawError } = resp.error
      if (
        message &&
        message.includes('Validation error') &&
        rawError.includes('SequelizeUniqueConstraintError')
      ) {
        msg.error(`There's already a tag with that name. Please try with another name!`)
      } else {
        msg.error(error)
      }
    }
  }

  /**
   * Used to update the related data store to maintain consistency
   * @param {*} model
   * @param {*} items
   * @param {*} payload
   */
  updateRelatedDataStore(model: any, items: any, payload: any) {
    const itemsToUpdate = items.map((item: any) =>
      isNaN(item) ? item : { id: item, tags: payload }
    )
    if (model === 'note') {
      itemsToUpdate.map(({ id, tags }: any) => global.data.appt.updateNoteLocally(id, { tags }))
    }
  }
}

export default Tags
