import { captureExceptionSilently } from 'helpers/sentry'
import { makeAutoObservable } from 'mobx'
import { msg } from 'stores/msg'
import clientsService from 'stores/services/clients.service'

export type Client = Omit<
  Awaited<ReturnType<(typeof clientsService)['searchClients']>>[number] & {
    fullname?: string
  },
  '__typename'
>

export interface IContactAutocompletePM {
  clients: Client[]
  loading: boolean
  onSearch: (value: string, includeArchived?: boolean) => void
  clearClients: () => void
  loadingContacts: boolean
}

class ContactAutocompletePM implements IContactAutocompletePM {
  clients: Client[] = []
  loading = false
  loadingContacts = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onSearch(value: string, includeArchived?: boolean) {
    this.searchClients(value, includeArchived)
  }

  async searchClients(name: string, includeArchived?: boolean) {
    try {
      this.loading = true
      if (!name.trim()) {
        this.clients = []
        return
      }
      const clients = await clientsService.searchClients(name, includeArchived)

      this.clients = this.addFullname(clients)
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'searchClients',
        data: { name },
      })
      msg.error(
        'There was an error getting the results. Please try again later',
        undefined,
      )
    } finally {
      this.loading = false
    }
  }

  clearClients() {
    this.clients = []
  }

  setClients(clients: Client[]) {
    this.clients = this.addFullname(clients)
  }

  addFullname(clients: Client[]) {
    const clientsWithFullname: Client[] = []
    for (const client of clients) {
      const fullname: string =
        global.data.clients.getClientNameWithAlias(client)
      clientsWithFullname.push({ ...client, fullname })
    }

    return clientsWithFullname
  }

  async setContactsByIds(contactIds: number[]) {
    try {
      this.loadingContacts = true
      const clientsPromises = contactIds.map((contactId) => {
        return clientsService.getClient(contactId)
      })

      const clients = await Promise.all(clientsPromises)
      this.setClients(clients)
    } catch (error) {
    } finally {
      this.loadingContacts = false
    }
  }
}

export default ContactAutocompletePM
