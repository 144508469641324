import {
  EditorComponent,
  OnChangeHTML,
  OnChangeJSON,
  Remirror,
  ThemeProvider,
  useRemirror,
} from '@remirror/react'
import { observer } from 'mobx-react'
import { useCallback, useEffect, FC } from 'react';
import { RemirrorContentType, RemirrorJSON, getRemirrorJSON } from 'remirror'
import { MentionAtomCustom } from './extensions/smartFieldAtom/component'
import { NoteReplacerComponent } from './extensions/noteReplacer/NoteReplacer.component'
import { MergeFieldContent } from './extensions/noteReplacer/NoteReplacer.interfaces'
import { CONFIG_NAMES } from './presets/configs'
import { ExternalHelpers, pulsePreset } from './presets/pulse.preset'
import { EditorWrapper, theme } from './styles/theme'

interface RemirrorPreviewEditorProps {
  content?: RemirrorContentType
  placeholder?: string
  template?: any
  notes?: Array<MergeFieldContent>
  placeholdersData?: Array<any>
  onChangeJSON?: (json: RemirrorJSON) => void
  onChangeHTML?: (html: string) => void
  classNames?: string[]
  bordered?: boolean
  disableAtomSync?: boolean
  /**
   * Sets if Merge Fields should be replaced or not. On `true`, it will replace if `template` and `notes` are provided, after the first render of the editor.
   */
  mergeFieldReplacementEnable?: boolean
  /**
   * Function to set the `mergeFieldReplacementEnable` prop passed. Useful for generating a one-time merge field replacement. For example, when a greeting is modified
   * it rebuilds the whole document with the new greeting, and then it can be modified manually by the user.
   */
  setMergeFieldReplacementEnable?: (value: boolean) => void
  onDOMNode?: (node: any) => void
  externalHelpers?: ExternalHelpers
}

const RemirrorPreviewEditor: FC<RemirrorPreviewEditorProps> = ({
  content,
  template,
  notes,
  placeholdersData = [],
  onChangeJSON,
  onChangeHTML,
  classNames = [],
  bordered = false,
  mergeFieldReplacementEnable,
  setMergeFieldReplacementEnable,
  disableAtomSync = false,
  onDOMNode,
  externalHelpers,
}) => {
  const extensions = useCallback(
    () => [...pulsePreset(CONFIG_NAMES.PREVIEW, externalHelpers)],
    [],
  )

  const initialContent = mergeFieldReplacementEnable ? template : content

  const { manager, state, onChange } = useRemirror({
    extensions,
    content: initialContent,
    stringHandler: 'html',
    nodeViewComponents: {
      mentionAtom: (props) => (
        <MentionAtomCustom
          id={props.node.attrs.id}
          label={props.node.attrs.label}
          kind={props.node.attrs.kind}
          displayMode={props.node.attrs.displayMode}
          reuse={props.node.attrs.reuse}
          header={props.node.attrs.header}
          config={props.node.attrs.config}
          disableAtomSync={disableAtomSync}
        />
      ),
    },
  })

  useEffect(() => {
    // INFO: This only updates the entire content, by changing `content` prop with a ProsemirrorJSON or HTML-string.
    manager.view.updateState(manager.createState({ content }))
  }, [content, manager, placeholdersData])

  useEffect(() => {
    // INFO: the updateState does not trigger changes in the extensions or helpers so we must force it
    setTimeout(() => {
      manager.view.focus()
      const json = getRemirrorJSON(manager.view.state)
      onChangeJSON?.(json)
    }, 0)

    // updates parent with rendered dom node
    if (manager.view.dom) {
      onDOMNode?.(manager.view.dom)
    }
  }, [notes, content, manager.view, onChangeJSON])

  const triggerUpdateContent = () => {
    if (onChangeJSON) {
      const json = getRemirrorJSON(manager.view.state)
      onChangeJSON?.(json)
    }
    setMergeFieldReplacementEnable?.(false)
  }

  return (
    <EditorWrapper
      $bordered={bordered}
      className="preview-mode remirror-style-wrapper"
    >
      <ThemeProvider theme={theme}>
        <Remirror
          editable={false}
          manager={manager}
          initialContent={state}
          state={state}
          onChange={onChange}
          classNames={classNames}
        >
          {onChangeJSON && <OnChangeJSON onChange={onChangeJSON} />}
          {onChangeHTML && <OnChangeHTML onChange={onChangeHTML} />}
          <EditorComponent />
          {mergeFieldReplacementEnable && (
            <NoteReplacerComponent
              enabled={mergeFieldReplacementEnable!}
              state={state}
              mergeFieldContents={notes!}
              template={template}
              manager={manager}
              placeholders={placeholdersData}
              updateContent={triggerUpdateContent}
            />
          )}
        </Remirror>
      </ThemeProvider>
    </EditorWrapper>
  )
}

export default observer(RemirrorPreviewEditor)
