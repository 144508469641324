import sentry from 'helpers/sentry'
import { assign, set } from 'lodash'
import App from './app'
import { authStore } from './auth/index'
import { bus } from './bus'
import Clipboard from './clipboard'
import Data from './data'
import Files from './files'
import HolistiplanStore from './holistiplan'
import Images from './images'
import Mail from './mail'
// import Notes from './notes.xxx'
import Perms from './perms'
import PreciseFPStore from './precisefp'
import RiskalyzeStore from './riskalyze'
import Router from './router'
import Tags from './tags'
// import Tasks from './tasks.xxx'
import View from './viewport'
import WealthboxStore from './wealthbox'

/**
 * Convenience for adding stores
 * @param {*} path
 * @param {*} value
 */
function add(path: any, value: any) {
  if (typeof path === 'object') {
    assign(global.stores, path)
  } else {
    set(global, path, value)
  }
}

/**
 * Synchronous store creation
 */
function init() {
  add('app', new App())
  add('perms', new Perms())
  add('auth', authStore)
  add('view', new View())
  add('mail', new Mail())
  add('files', new Files())
  // add('notes', new Notes())
  add('images', new Images())
  add('data', new Data())
  add('tags', new Tags())
  // add('tasks', new Tasks())
  add('riskalyze', new RiskalyzeStore())
  add('wealthbox', new WealthboxStore())
  add('holistiplan', new HolistiplanStore())
  add('precisefp', new PreciseFPStore())
  add('clipboard', new Clipboard())
  return global.stores
}

/**
 * Configure all stores - async
 */
export const createStores = () => {
  add('bus', bus)
  add('router', new Router())
  sentry()
  init()
}
