import {
  ApplySchemaAttributes,
  extension,
  getMarkRanges,
  getTextSelection,
  Helper,
  helper,
  MarkExtensionSpec,
  MarkSpecOverride,
  PrimitiveSelection,
  Static,
  ExtensionPriority,
  isElementDomNode,
  isString,
  joinStyles,
  Mark,
  omitExtraAttributes,
} from '@remirror/core'
import { Palette, palette } from '@remirror/extension-text-color'
import { TextHighlightExtension } from '@remirror/extension-text-highlight'

interface TextHighlightAttributes {
  /**
   * The highlight color.
   *
   * @defaultValue ''
   */
  highlight?: string
}

interface TextHighlightOptions {
  /**
   * The default highlight value.
   *
   * @default ''
   */
  defaultHighlight?: Static<string>

  /**
   * The color palette which is a function that returns a list of colors and
   * labels for help with ui. It is completely optional and you are free to use
   * use whatever colors you choose.
   */
  palette?: Palette
}

const TEXT_HIGHLIGHT_ATTRIBUTE = 'data-text-highlight-mark'

/**
 * Add a highlight color to the selected text (or text within a specified
 * range).
 */
// @ts-ignore
@extension<TextHighlightOptions>({
  defaultOptions: {
    defaultHighlight: '',
    palette,
  },
  staticKeys: ['defaultHighlight'],
})
export class TextHighlightCustomExtension extends TextHighlightExtension {
  createMarkSpec(
    extra: ApplySchemaAttributes,
    override: MarkSpecOverride,
  ): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        highlight: { default: this.options.defaultHighlight },
      },
      parseDOM: [
        {
          tag: `span[${TEXT_HIGHLIGHT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null
            }

            const highlight = dom.getAttribute(TEXT_HIGHLIGHT_ATTRIBUTE)

            if (!highlight) {
              return null
            }

            return { ...extra.parse(dom), highlight }
          },
        },
        {
          tag: `span[${TEXT_HIGHLIGHT_ATTRIBUTE}]`,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return null
            }

            const highlight = dom.getAttribute(TEXT_HIGHLIGHT_ATTRIBUTE)

            if (!highlight) {
              return null
            }

            return { ...extra.parse(dom), highlight }
          },
        },
        {
          // Get the color from the css style property. This is useful for pasted content.
          style: 'background-color',
          priority: ExtensionPriority.Low,
          getAttrs: (highlight) => {
            if (!isString(highlight)) {
              return null
            }

            return { highlight }
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark: Mark) => {
        const { highlight, ...other } =
          omitExtraAttributes<TextHighlightAttributes>(mark.attrs, extra)
        const extraAttrs = extra.dom(mark)
        let style = extraAttrs.style

        if (highlight) {
          style = joinStyles({ backgroundColor: highlight }, style)
        }

        // Uses 'span' instead of 'mark' to include compatibility for HTML4 Email clients, such as Outlook.
        return [
          'span',
          {
            ...other,
            ...extraAttrs,
            style,
            [TEXT_HIGHLIGHT_ATTRIBUTE]: highlight,
          },
          0,
        ]
      },
    }
  }

  /**
   * Get the Text Color at the current selection (or provided custom selection).
   * Returns the text color in the non-empty selection
   */
  @helper()
  getTextHighlightForSelection(
    position?: PrimitiveSelection,
  ): Helper<string | null> {
    const state = this.store.getState()
    const selection = getTextSelection(position ?? state.selection, state.doc)
    const [range] = getMarkRanges(selection, this.type)

    if (range) {
      return range.mark.attrs.highlight
    }

    return null
  }
}
