/* eslint-disable @typescript-eslint/no-empty-function */
import { ImageExtension, ImageOptions } from '@remirror/extension-image'
import { EditorView } from '@remirror/pm/view'
import {
  extension,
  ExtensionImageTheme,
  NodeViewMethod,
  ProsemirrorNode,
} from 'remirror'
import { imageUploadHandler } from './ImageUpload.handler'
import { ResizableImageView } from './ResizableImageView'

// @ts-ignore
@extension<ImageOptions>({
  defaultOptions: {
    createPlaceholder,
    updatePlaceholder: () => {},
    destroyPlaceholder: () => {},
    uploadHandler: imageUploadHandler,
    enableResizing: false,
    preferPastedTextContent: true,
  },
})
export class ImageCustomExtension extends ImageExtension {
  createNodeViews(): NodeViewMethod | Record<string, NodeViewMethod> {
    if (this.options.enableResizing) {
      return (
        node: ProsemirrorNode,
        view: EditorView,
        getPos: () => number | undefined,
      ) => {
        return new ResizableImageView(node, view, getPos as () => number)
      }
    }

    return {}
  }
}

function createPlaceholder(_: EditorView, __: number): HTMLElement {
  const element = document.createElement('div')
  element.classList.add(ExtensionImageTheme.IMAGE_LOADER)

  return element
}
