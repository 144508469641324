const mimeToExtensionTable = [
  { ext: 'csv', mime: 'text/csv' },
  { ext: 'png', mime: 'image/png' },
  { ext: 'jpg', mime: 'image/jpeg' },
  { ext: 'jpeg', mime: 'image/jpeg' },
  { ext: 'gif', mime: 'image/gif' },
  { ext: 'bpm', mime: 'image/bmp' },
  { ext: 'pdf', mime: 'application/pdf' },
  { ext: 'doc', mime: 'application/msword' },
  { ext: 'xls', mime: 'application/vnd.ms-excel' },
  { ext: 'ppt', mime: 'application/vnd.ms-powerpoint' },
  { ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { ext: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.pesentation' },
  { ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
]

export function getExtension(mime: any) {
  if (!mime) {
    mime = 'image/png'
  }
  const extension = mimeToExtensionTable.find((t) => t.mime === mime)

  return extension?.ext || mimeToExtensionTable[1].ext
}
