import {
  htmlToProsemirrorNode,
  ProsemirrorNode,
  prosemirrorNodeToHtml,
  RemirrorJSON,
  RemirrorManager,
} from 'remirror'
import Str from 'stores/util/str.util'
import { EmptyExtension } from '../extensions/empty/Empty.extension'
import { AREA_TYPES, AREA_TYPES_LIST } from '../extensions/noteReplacer/NoteReplacer.constants'
import { getMentionAtoms } from '../extensions/noteReplacer/NoteReplacer.extension'
import { MergeFieldToReplace, NoteTag } from '../extensions/noteReplacer/NoteReplacer.interfaces'
import { getDeepListMergeFields } from '../extensions/noteReplacer/NoteReplacerList.helpers'
import { CONFIG_NAMES, EDITOR_CONFIG } from '../presets/configs'
import { pulsePreset } from '../presets/pulse.preset'

export const EMPTY_PARAGRAPH: RemirrorJSON = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
}

function parseJSONToRemirrorAndValidate(value: string | RemirrorJSON): RemirrorJSON {
  if (typeof value === 'string') {
    if (!Str.isJSON(value)) {
      throw new Error('Remirror: is not a valid json')
    }
    return JSON.parse(value)
  }

  return value
}

export function parseJSONToRemirror(value: string) {
  return Str.isJSON(value) ? JSON.parse(value) : value
}

export function htmlToRemirrorNode(html: string) {
  const schema = RemirrorManager.create(() => [...pulsePreset(CONFIG_NAMES.FULL)]).schema
  const node = htmlToProsemirrorNode({ content: html, schema: schema })

  return node
}

export function htmlToRemirrorFragment(html: string) {
  const schema = RemirrorManager.create(() => [...pulsePreset(CONFIG_NAMES.FULL)]).schema
  const node = htmlToProsemirrorNode({
    content: html,
    schema: schema,
    fragment: true,
  })

  return node
}

export function jsonToRemirrorNode(
  json: string | RemirrorJSON,
  preset: EDITOR_CONFIG = CONFIG_NAMES.FULL
) {
  const schema = RemirrorManager.create(() => [...pulsePreset(preset)]).schema
  const node = schema.nodeFromJSON(parseJSONToRemirrorAndValidate(json))

  return node
}

export function htmlToRemirrorJSON(html: string) {
  const node = htmlToRemirrorNode(html)
  const jsonObject = node.toJSON()

  return JSON.stringify(jsonObject)
}

export function htmlToRemirrorJSONObject(html: string): RemirrorJSON {
  const node = htmlToRemirrorNode(html)
  const jsonObject = node.toJSON()

  return jsonObject
}

export function remirrorJSONToHtml(json: string | RemirrorJSON, preset?: string) {
  const node = jsonToRemirrorNode(json, preset as EDITOR_CONFIG)

  const html = prosemirrorNodeToHtml(node)

  return html
}

export function isRemirrorJSONEmpty(json: string | RemirrorJSON) {
  const node = jsonToRemirrorNode(json)

  return EmptyExtension.isDocEmpty(node)
}

export function remirrorNodeToHtml(node: ProsemirrorNode): string {
  return prosemirrorNodeToHtml(node)
}

export function remirrorJSONToFragment(json: string | RemirrorJSON, preset?: string) {
  const node = jsonToRemirrorNode(json, preset as EDITOR_CONFIG)

  return node.content
}

/**
 * Useful util to put Fragments into a Node that can be used as `content` in an Editor context.
 */
export function putNodesIntoDoc(nodes: ProsemirrorNode[]): ProsemirrorNode {
  const schema = RemirrorManager.create(() => [...pulsePreset(CONFIG_NAMES.FULL)]).schema
  return schema.nodes.doc.create(null, nodes)
}

export function getSmartFields(
  doc: RemirrorJSON,
  subtopics: NoteTag[]
): { kind: string; mergeFieldType: string; tags: string | NoteTag[] }[] {
  const manager = RemirrorManager.create(() => [...pulsePreset(CONFIG_NAMES.FULL)])

  const state = manager.createState({
    content: doc,
  })

  const mentionsAtomsAreas = getMentionAtoms(state.tr, subtopics)

  const placeholders = []

  for (const mentionAtomArea of mentionsAtomsAreas) {
    if (AREA_TYPES_LIST.includes(mentionAtomArea.areaType)) {
      getDeepListMergeFields(mentionAtomArea.node, mentionAtomArea.pos, subtopics).forEach(
        (mentionAtom) => {
          const { kind, mergeFieldType, tags } = mentionAtom

          placeholders.push({ kind, mergeFieldType, tags })
        }
      )
    } else if (mentionAtomArea.areaType === AREA_TYPES.INLINE) {
      const [placeholder] = mentionAtomArea.items
      const [mergeFieldToReplace] = placeholder.children! as [MergeFieldToReplace]

      const { kind, mergeFieldType, tags } = mergeFieldToReplace

      placeholders.push({ kind, mergeFieldType, tags })
    }
  }

  return placeholders
}
