import {
  command,
  CommandFunction,
  CommandFunctionProps,
  DelayedCommand,
  DelayedPromiseCreator,
  extension,
  ExtensionPriority,
  nonChainable,
  NonChainableCommandFunction,
  PlainExtension,
  RemirrorJSON,
} from '@remirror/core'
import {
  htmlToRemirrorJSONObject,
  putNodesIntoDoc,
} from '../../util/remirror.util'

interface RephraseOptions {
  onPrompt: (
    input: RemirrorJSON,
    prompt: string,
    tone: string,
  ) => Promise<string | undefined>
}

interface DelayedFormatCodeBlockProps<Value> {
  /**
   * A function that returns a promise.
   */
  promise: DelayedPromiseCreator<Value>

  /**
   * Called when the promise succeeds and the formatted code is available. If
   * formatting fails, the failure handler is called instead.
   */
  onSuccess: (value: Value, commandProps: CommandFunctionProps) => boolean

  /**
   * Called when a failure is encountered.
   */
  onFailure?: CommandFunction<{ error: unknown }>
}

@extension<RephraseOptions>({
  defaultOptions: {
    onPrompt: async (input: RemirrorJSON, prompt: string, tone: string) => {
      return prompt
    },
  },
  defaultPriority: ExtensionPriority.Medium,
})
export class RephraseExtension extends PlainExtension<RephraseOptions> {
  get name() {
    return 'rephrase' as const
  }

  // @ts-expect-error TS(2345): Argument of type 'TypedPropertyDescriptor<(prompt:... Remove this comment to see the full error message
  @command({ disableChaining: true, submenu: true })
  rephrase(prompt: string): NonChainableCommandFunction {
    const [tone, action] = prompt.split(':')
    const command = this.createRephraseTest({
      promise: (props) => {
        const { tr, dispatch, state } = props
        this.store.view.setProps({ editable: () => false })

        return new Promise(async (res, rej) => {
          try {
            const wholeDocumentSelection =
              tr.selection.from - tr.selection.to === 0
            const input = wholeDocumentSelection
              ? tr.doc.toJSON()
              : (putNodesIntoDoc(
                  // @ts-expect-error TS(2339): Property 'content' does not exist on type 'Fragmen... Remove this comment to see the full error message
                  tr.selection.content().content.content,
                ).toJSON() as RemirrorJSON)
            const result = await this.options.onPrompt(input, action, tone)
            if (!result) {
              rej('No result')
            }
            const newData = state.schema.nodeFromJSON(
              htmlToRemirrorJSONObject(result!),
            )
            if (wholeDocumentSelection) {
              tr.replaceWith(0, tr.doc.nodeSize - 2, newData)
            } else {
              tr.replaceWith(tr.selection.from - 1, tr.selection.to, newData)
            }
            if (dispatch) {
              dispatch(tr)
            }
            res(true)
          } catch (err) {
            console.error('error', err)
            rej(err)
          } finally {
            this.store.view.setProps({ editable: () => true })
          }
        })
      },
      onSuccess: (value, { tr }) => {
        return true
      },
    }).generateCommand()

    return nonChainable(command)
  }

  private readonly createRephraseTest = <Value>(
    props: DelayedFormatCodeBlockProps<Value>,
  ): DelayedCommand<Value> => {
    const { promise, onSuccess, onFailure } = props
    return new DelayedCommand(promise)
      .validate(({ tr }) => {
        return true
      })
      .success((props) => {
        const { value, ...commandProps } = props
        return onSuccess(value, commandProps)
      })
      .failure((props) => {
        return onFailure?.(props) ?? false
      })
  }
}
