export const STATES = {
  // Connection states
  // 1. Hidden - not visible to the app
  HIDDEN: 'HIDDEN',
  // 2. Disconnected
  DISCONNECTED: 'DISCONNECTED',
  // 3. Re-authenticate the connection
  REAUTHENTICATE: 'REAUTHENTICATE',
  // 4. Connected
  CONNECTED: 'CONNECTED',
} as const

export type ConnectionState = keyof typeof STATES

const TYPES = {
  CRM: 'CRM',
  INSIGHT: 'INSIGHT',
}

const CATEGORIES = {
  CRM: 'Customer Relationship Management (CRM)',
  INSIGHT: 'Insight',
}

export const isConnected = (state: ConnectionState = 'HIDDEN'): boolean =>
  state === 'CONNECTED'

export const isDisconnected = (state: ConnectionState = 'HIDDEN'): boolean =>
  state === 'DISCONNECTED'

export const requiresAuth = (state: ConnectionState = 'HIDDEN'): boolean =>
  state === 'REAUTHENTICATE'
