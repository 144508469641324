export const TEMPLATE_CATEGORY = {
  SUMMARY: 1,
  AGENDA: 2,
} as const

export const TEMPLATE_CATEGORY_NAME = {
  [TEMPLATE_CATEGORY.SUMMARY]: 'summary',
  [TEMPLATE_CATEGORY.AGENDA]: 'agenda',
} as const

export const CATEGORY_ITEM_PROP = {
  summary: 'isSummary',
  agenda: 'isAgenda',
} as const

const GROUPING_OPTIONS = {
  SHOW_TITLE: 'Show Title',
  HIDE_TITLE: 'Hide Title',
}

// FIXME: it is very difficult to remove all this code without generating side effects. This variable is being used as an easy fix
export const EMPTY_PREVIEW = 'EMPTY_PREVIEW'

// FIXME: added as `Client to-do` and regular tags collide with each other in notes.
const ALLOWED_NOTE_TAGS = ['Client']
