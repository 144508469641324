export type DocumentFormat = Record<string, number[]>
export type DocumentOrientation = 'portrait' | 'landscape'

export const formats: DocumentFormat = {
  a4: [8.3, 11.7],
  letter: [8.5, 11],
}

export const DocumentFormats = Object.keys(formats)

export const orientations: DocumentOrientation[] = ['portrait', 'landscape']
