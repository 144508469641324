import { FC } from 'react';
import { ConnectionIcon } from '../connection.interfaces'

export const NitrogenIcon: FC<ConnectionIcon> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49" fill="none">
    <g clip-path="url(#clip0_536_2779)">
      <path
        d="M26.5913 9.02973V3.01025L21.3783 0.000488281L16.1653 3.01025V9.02973L21.3783 6.01997L26.5913 9.02973Z"
        fill="#6941C7"
      />
      <path
        d="M16.1653 39.1572V45.1767L21.3783 48.1864L26.5913 45.1767V39.1572L21.3783 42.1669L16.1653 39.1572Z"
        fill="#6941C7"
      />
      <path
        d="M5.72595 15.0605L10.939 12.0508L5.72595 9.04102L0.512939 12.0508V18.0703L5.72595 21.08V15.0605Z"
        fill="#6941C7"
      />
      <path
        d="M37.0294 33.1333L31.8164 36.143L37.0294 39.1528L42.2424 36.143L42.2425 30.1235L37.0294 27.1138V33.1333Z"
        fill="#6941C7"
      />
      <path
        d="M5.72864 27.1157L0.515625 30.1255V36.145L5.72864 39.1547L10.9417 36.145L5.72864 33.1352V27.1157Z"
        fill="#6941C7"
      />
      <path
        d="M37.0324 9.04297L31.8193 12.0527L37.0324 15.0625L37.0324 21.0819L42.2454 18.0722V12.0527L37.0324 9.04297Z"
        fill="#6941C7"
      />
      <path
        d="M16.1653 27.1084V33.1279L21.3783 36.1376L26.5913 33.1279V27.1084L21.3783 30.1182L16.1653 27.1084Z"
        fill="#6941C7"
      />
      <path
        d="M21.3784 18.0781L26.5914 21.0878V27.1074L31.8045 24.0976V18.0781L26.5914 15.0684L21.3784 18.0781Z"
        fill="#6941C7"
      />
      <path
        d="M21.3785 18.0781L16.1654 15.0684L10.9524 18.0781L10.9524 24.0976L16.1654 27.1074V21.0878L21.3785 18.0781Z"
        fill="#6941C7"
      />
    </g>
    <defs>
      <clipPath id="clip0_536_2779">
        <rect
          width="48.1851"
          height="48.1851"
          fill="white"
          transform="translate(0.512939)"
        />
      </clipPath>
    </defs>
  </svg>
)
