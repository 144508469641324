import ADVISORS from 'constants/advisors'
import ROLES, { Level } from 'constants/roles'
import { identifyHelpHero, updateHelpHero } from 'helpers/sentry'
import { sanitizeUrl } from 'helpers/url'
import { isNil, omit, sample } from 'lodash'
import { action, computed, makeObservable, observable, observe, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { msg } from 'stores/msg'
import Str from 'stores/util/str.util'
import { EntityId } from 'types/entity.interface'
import { Advisor, AdvisorInput } from 'types/graphql'
import { MAX_OPTION_COUNT } from '../../constants/autocomplete'
import { clearImagesURLOnBody } from '../../helpers/file'
import advisorsService from '../services/advisors.service'
import { UserPreferences } from './types'
import { practiceStore } from '@state/practice/practice.state'
import { updateMember } from '@state/practice/practice.actions'

const OMIT_FIELDS = [
  'id',
  'goals',
  'createdAt',
  'updatedAt',
  'checklist',
  'external_assets',
  'future_strategies',
  'strategies',
  'assets',
  'social_media',
  '__typename',
]

class Advisors {
  me!: Advisor

  constructor() {
    makeObservable(this, {
      me: observable,
      isAdmin: computed,
      emailSign: computed,
      wetSign: computed,
      role: computed,
      creds: computed,
      initials: computed,
      myId: computed,
      acceptedEula: computed,
      emailConnected: computed,
      setMe: action.bound,
      fetchCRMUsers: action.bound,
    })
    observe(
      this,
      'me',
      (c) => {
        if ((c.newValue as Advisor)?.createdAt) {
          identifyHelpHero(c.newValue, practiceStore.name)
          updateHelpHero({
            createdAt: dayjs((c.newValue as Advisor)?.createdAt).toJSON(),
            isNewUser: dayjs((c.newValue as Advisor)?.createdAt).isAfter(
              dayjs().subtract(1, 'month')
            ),
          })
        }
      },
      true
    )
    observe(this, 'emailConnected', (c) => updateHelpHero({ emailConnected: c.newValue }), true)
  }

  get isAdmin() {
    if (this.me) {
      return this.me.isAdmin
    }
    return false
  }

  get emailSign() {
    if (this.me) {
      return this.me.emailSign
    }
    return null
  }

  get wetSign() {
    if (this.me) {
      return this.me.wetSign
    }
    return null
  }

  get role() {
    if (this.me) {
      return this.me.role
    }
    return ROLES.USER
  }

  get creds() {
    let creds = null
    if (this.me && this.me.creds) {
      creds = this.me.creds
    }
    return creds
  }

  get initials() {
    if (this.me) {
      const { firstName = '', lastName = '' } = this.me
      return `${Str.capitalizeFirstLetter(firstName!)}${Str.capitalizeFirstLetter(lastName!)}`
    }
    return ''
  }

  get myId() {
    if (this.me) {
      return this.me.id
    }
    return 0
  }

  get acceptedEula() {
    if (this.me) {
      return this.me.acceptedEula
    }
    return false
  }

  get emailConnected() {
    return !isNil(this.creds)
  }

  get preferences() {
    if (this.me?.preferences) {
      return JSON.parse(this.me.preferences) as UserPreferences
    }

    return {} as UserPreferences
  }

  /**
   *
   */
  setMe(advisor: Advisor) {
    this.me = advisor
    // this.me.acceptedEula = false // test onboard redirect
  }

  /**
   * Disable an advisor from a practice
   * @param {*} advisor
   */
  async disable(id: EntityId, replacerId: EntityId) {
    const idDeleted = await advisorsService.deleteAdvisor(id, replacerId)
    if (idDeleted) {
      updateMember(id, { status: ADVISORS.DISABLED })
    }

    return idDeleted
  }

  /**
   * @param {*} advisorData
   */
  _clearSignature(advisorData: AdvisorInput) {
    if (advisorData.wetSign) {
      const wetSign = Str.parseJson(advisorData.wetSign)
      if (wetSign && wetSign.url) {
        wetSign.url = sanitizeUrl(wetSign.url)
      }

      // FIXME: chaki -> this no longer exists as html
      /* if (wetSign && wetSign.signDetails) {
        wetSign.signDetails = clearImagesURLOnBody(wetSign.signDetails)
      } */
      advisorData.wetSign = JSON.stringify(wetSign)
    }
    if (advisorData.emailSign) {
      advisorData.emailSign = clearImagesURLOnBody(advisorData.emailSign)
    }
    return advisorData
  }

  /**
   * Update an advisor from a practice
   * @param {*} advisor
   */
  async update(fields: AdvisorInput, id = this.myId, replacerId?: EntityId) {
    const data = this._clearSignature(omit(fields, OMIT_FIELDS))
    const idUpdated = await advisorsService.updateAdvisor(Number(id), data, replacerId)

    if (idUpdated) {
      if (this.me && id === this.myId) {
        runInAction(() => {
          ;(Object.keys(fields) as (keyof AdvisorInput)[]).forEach(
            (key) => ((this.me! as any)[key] = fields[key])
          )
          updateMember(id, fields)
        })
      } else {
        updateMember(id, fields)
      }
    }
    return idUpdated
  }

  async fetchCRMUsers() {
    const crmUsers = await advisorsService.fetchCRMUsers()
    if (!Array.isArray(crmUsers)) {
      msg.error(['CRM users', 'getting'], '', 10)
      return []
    }
    return crmUsers.map((user) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
    }))
  }

  /**
   * Update the status of a user
   */
  setStatus = (id: EntityId, status: AdvisorInput['status']) => {
    return this.update({ status }, id)
  }

  /**
   * @param {*} advisorId
   */
  getAdvisor = (id = this.myId) => {
    return practiceStore.members.get(Number(id))
  }

  /**
   * @param {*} advisorId
   */
  getAdvisorName = (id = this.myId) => {
    const advisor = this.getAdvisor(id)
    if (!advisor) {
      return 'Admin'
    }
    return advisor.nickName
  }

  /**
   * @param {*} advisorId
   */
  getFullName = (advisor = this.me): string => {
    const { firstName, lastName } = advisor!
    return `${firstName} ${lastName}`
  }

  /**
   * @param {*} advisorId
   */
  getFullNameFromID = (id: EntityId) => {
    const advisor = this.getAdvisor(id)
    if (!advisor) {
      return 'Admin'
    }
    const { firstName, lastName } = advisor
    return `${firstName} ${lastName}`
  }

  get activeAdvisors() {
    return Array.from(practiceStore.members.values()).filter(
      (advisor) => advisor.status === ADVISORS.ACTIVE
    )
  }

  get inactiveAdvisors() {
    return Array.from(practiceStore.members.values()).filter(
      (advisor) => advisor.status === ADVISORS.DISABLED
    )
  }

  get pendingAdvisors() {
    return Array.from(practiceStore.members.values()).filter(
      (advisor) => advisor.status === ADVISORS.PENDING
    )
  }

  randomAdvisor = () => {
    return sample(Array.from(practiceStore.members.values()))
  }

  filteredAdvisors = ({
    hideDisabled = true,
    hidePulseAdmins = true,
    hideMe = false,
  } = {}): Advisor[] => {
    const isDisabled = ({ status }: { status: AdvisorInput['status'] }) =>
      status === ADVISORS.DISABLED || status === ADVISORS.LOCKED

    const isPulse360Admin = ({ role }: { role: Level }) => role === ROLES.PULSE_ADMIN
    const isMe = ({ id }: { id: number }) => id === this.myId

    return Array.from(practiceStore.members.values()).filter((advisor) => {
      if (hideDisabled && isDisabled(advisor)) {
        return false
      }
      if (hidePulseAdmins && isPulse360Admin(advisor)) {
        return false
      }
      if (hideMe && isMe({ id: Number(advisor.id) })) {
        return false
      }
      return true
    })
  }

  findByName(stringToFind: string, options: { filter?: () => boolean; limit?: number } = {}) {
    const { filter = null, limit = MAX_OPTION_COUNT } = options
    const advisors = Array.from(practiceStore.members.values())
    const filteredAdvisors = filter ? advisors.filter(filter) : advisors
    const result = []

    for (const advisor of filteredAdvisors) {
      const name = this.getFullName(advisor)
      if (name.toLowerCase().indexOf(stringToFind.toLowerCase()) >= 0) {
        result.push({ fullName: name, ...advisor })
        if (result.length === limit) {
          break
        }
      }
    }

    return result
  }
}

export default Advisors
