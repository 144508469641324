/* eslint-disable prefer-promise-reject-errors */
import { EMPTY_PREVIEW } from 'components/routes/tasks/notes/document/constants/DocumentEditor.constants'
import { filterValidFiles } from 'components/routes/tasks/notes/notes.utils'
import { DocumentOrientation, formats } from 'constants/formats'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import communicationService from '../services/communication.service'
import { Advisor, AppointmentReport, Note } from 'types/graphql'
import { LocalTemplate } from './templates'
import { compact } from 'lodash'

type HTMLOptions = {
  height: number
  width: number
  margins: string
  orientation: DocumentOrientation
  format: 'letter' | 'a4'
  html: string
}

class Html {
  margins = '1in 1in 1in 1in'
  options: HTMLOptions = {
    height: formats.letter[1],
    width: formats.letter[0],
    margins: '1in 1in 1in 1in',
    orientation: 'portrait',
    format: 'letter',
    html: EMPTY_PREVIEW,
  }
  html = EMPTY_PREVIEW
  preview: string = ''
  docTitle = ''
  attachments = {}
  attachmentsNotes = {}
  warnings = []
  template: LocalTemplate | undefined = undefined
  advisor: Advisor | undefined = undefined
  alias: string | undefined = undefined
  report360view: AppointmentReport[] = []
  htmlChanges = -1

  constructor() {
    makeObservable(this, {
      margins: observable.ref,
      options: observable.ref,
      warnings: observable.ref,
      html: observable,
      preview: observable,
      docTitle: observable.ref,
      report360view: observable.ref,
      htmlChanges: observable,
      attachments: observable,
      attachmentsNotes: observable,
      addAttachment: action,
      removeAttachment: action,
      loadReport360viewRange: action.bound,
      addDocumentState: action.bound,
      buildDocument: action.bound,
      setBlankHtml: action,
      setEmptyHtml: action,
      template: observable,
      advisor: observable,
      alias: observable,
    })
  }

  /**
   *
   */
  addAttachment = (file: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    this.attachments[file.id] = file
  }

  /**
   *
   */
  removeAttachment = (fileId: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete this.attachments[fileId]
  }

  /**
   *
   */
  setBlankHtml = () => {
    this.docTitle = 'Blank'
    this.html = EMPTY_PREVIEW
    this.attachments = {}
    this.warnings = []
    this.attachmentsNotes = {}
    this.template = undefined
    this.htmlChanges = -1
    this.preview = ''
  }

  /**
   *
   */
  setEmptyHtml = () => {
    this.docTitle = 'Empty'
    this.attachments = {}
    this.html = EMPTY_PREVIEW
    this.warnings = []
    this.attachmentsNotes = {}
    this.template = undefined
    this.htmlChanges = -1
    this.preview = ''
  }

  /**
   * @param {*}
   */
  async loadReport360viewRange(range: any) {
    if (!range) {
      throw new Error('Appts: No range provided!')
    }
    try {
      const documents = await communicationService.load360View(range)
      // @ts-ignore
      this.report360view = compact(documents)
      return documents
    } catch (err) {
      msg.error(['360view', 'fetching'])
      captureExceptionSilently(err, {
        message: 'loadReport360viewRange',
        data: { range },
      })
    }
  }

  /**
   * @param {*}
   */
  async loadFullDocument(id: any) {
    global.app.loading = true
    try {
      const document = await communicationService.loadCommunication(id)
      return document
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'loadFullDocument',
        data: { id },
      })
      throw err
    }
  }

  /* ---------- export handlers ----------- */
  /* ---------- private ---------- */

  /**
   *
   */
  buildDocument = (
    html: string,
    selectedTemplate: LocalTemplate | undefined,
    appliedNotes: Note[],
    advisor: Advisor,
    alias: string
  ) => {
    runInAction(() => {
      this.template = selectedTemplate
      this.advisor = advisor
      this.alias = alias
      const options = this.getOptions(selectedTemplate, html)
      // @ts-expect-error TS(2339): Property 'name' does not exist on type '{ height: ... Remove this comment to see the full error message
      const { margins, name } = options
      this.options = options
      this.margins = margins
      this.docTitle = name
      this.attachments = {}
      this.attachmentsNotes = {}
      this.warnings = []
      this.processAttachments(appliedNotes)
      this.htmlChanges = -1
    })
  }

  /**
   * Add 1 to the current htmlChanges counter.
   *
   * The logic of this, is that visually setting the HTML in the editor takes 2 changes:
   * The one from the global.data.html.html, and one extra change that froala makes to set it.
   * The idea is that the initial state is 0, but we start from -1 to account for froala's initial change.
   *
   * In the future, this function might work to have a "history" of changes.
   */
  addDocumentState(_html: any) {
    this.htmlChanges = this.htmlChanges + 1
  }

  /**
   * Retrieves the required window size, converting to pixels
   */
  getOptions = (tmpl: any, html: any): HTMLOptions => {
    const format = (tmpl && tmpl.format) || 'letter'
    const tation = (tmpl && tmpl.orientation) || 'portrait'

    const dims = formats[format]
    const wIndex = tation === 'portrait' ? 0 : 1
    const hIndex = tation === 'portrait' ? 1 : 0

    return {
      // height: Math.floor(dims[hIndex] * PixelsPerCentimenter),
      // width: Math.floor(dims[wIndex] * PixelsPerCentimenter),
      height: dims[hIndex],
      width: dims[wIndex],
      margins: tmpl?.margins || '1in 1in 1in 1in',
      orientation: tation,
      format,
      html,
    }
  }

  processAttachments(appliedNotes: any) {
    const filesOfNotes = appliedNotes.reduce((acum: any, note: any) => {
      const files = filterValidFiles(note)
      files.forEach((fileId) => {
        if (fileId) {
          // skip fileId === 0
          if (!acum[fileId]) {
            acum[fileId] = { id: fileId, notes: [note] }
          } else {
            acum[fileId].notes.push(note)
          }
        }
      })
      return acum
    }, {})

    const attachments = Object.values(filesOfNotes).map((relatedFile) => ({
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...relatedFile,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      file: global.data.docs.get(relatedFile.id),
    }))

    if (attachments) {
      this.warnings = attachments.reduce((acum, { file, notes }) => {
        if (file && file.id) {
          this.addAttachment(file)
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          notes.map((note: any) => (this.attachmentsNotes[file.id] = note))
        } else {
          notes.forEach((note: any) => {
            const tagStrippedWords = note.text.replace(/<[^>]*>?/gm, '')
            const words =
              note.text.length > 20
                ? tagStrippedWords.substring(0, 17) + '...'
                : tagStrippedWords.substring(0, 20)
            acum.push({
              text: `The text with words “${words}” has an attachment that is missing.`,
            })
          })
        }
        return acum
      }, [])
    }
  }
}

export default Html
