import { SignIn as ClerkSignIn, useAuth } from "@clerk/clerk-react";
import { ClerkLayout } from "components/layouts/ClerkLayout";
import { ROOT } from "constants/auth";
import { elements } from "lib/clerk/elements";
import { Navigate, useLocation } from "react-router-dom";

export const SignIn = () => {
  const { isSignedIn } = useAuth();
  const { state } = useLocation();

  if (isSignedIn) {
    const to = state?.redirectTo ?? ROOT;
    return <Navigate to={to} replace />;
  }

  return (
    <>
      <ClerkLayout>
        <ClerkSignIn
          fallbackRedirectUrl={state?.redirectTo}
          transferable={false}
          appearance={{
            elements: {
              ...elements,
            },
          }}
        />
      </ClerkLayout>
    </>
  );
};
