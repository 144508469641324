import { ThemeConfig } from 'antd'

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#16ac19',
    colorLink: '#1677ff',
    fontFamily:
      'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
  },
  components: {
    Layout: {
      bodyBg: 'transparent',
      headerBg: 'rgba(255, 255, 255, 0.6)',
      siderBg: 'transparent',
      triggerBg: 'rgba(255, 255, 255, 0.6)',
    },
    Checkbox: {
      borderRadius: 4,
    },
  },
}
