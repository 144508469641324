import { atLeast, Level } from 'constants/roles'
import { action, makeObservable, observable } from 'mobx'
import { get } from 'lodash'
import { Permission } from 'types/graphql'

export interface PermissionStore {
  get: (key: string, defaultValue?: any) => any
  hasRole: (role: Level) => boolean
  isAvailable: (key: string) => string | number | boolean
  setUpgradeModalVisible: (show: boolean) => void
  showUpgradeModal: boolean
  showAssistantV2: boolean
  setPermissions: (permissions: Permission[]) => void
}

class Permissions implements PermissionStore {
  private permissions = observable.map<string, Permission>([])
  showUpgradeModal = false
  showAssistantV2 = false

  constructor() {
    makeObservable(this, {
      showUpgradeModal: observable,
      showAssistantV2: observable,
      setUpgradeModalVisible: action.bound,
      setPermissions: action.bound,
    })
  }

  setPermissions = (permissions: Permission[]) => {
    this.permissions.replace(permissions.map((perm) => [perm.key, perm]))
  }

  setUpgradeModalVisible = (show: boolean) => {
    this.showUpgradeModal = show
  }

  /* ---------- permissions ---------- */
  /**
   * @param {*} perm
   */
  get = (key: string, defaultValue = false) => {
    const perm = this.permissions.get(key)
    if (perm) {
      return this._castValue(perm)
    }
    return defaultValue
  }

  isAvailable = (key: string) => {
    const perm = this.permissions.get(key)
    if (perm) {
      return this._castAvailable(perm)
    }

    return false
  }

  /* ---------- roles ---------- */

  /**
   * myRole=ADVISOR
   * required=ADMIN_MANAGER
   * disabled=myRole<required
   */
  hasRole = (role: Level) => {
    const myRole = get(global, 'data.advisors.me.role', 'blah') as Level
    const isAtLeast = atLeast(myRole, role)
    return isAtLeast
  }

  /* ---------- private ---------- */

  /**
   *
   */
  _castValue = (perm: Permission) => {
    if (perm.type === 'boolean') {
      return Boolean(JSON.parse(perm.value))
    }
    if (perm.type === 'string') {
      return String(JSON.parse(perm.value))
    }
    if (perm.type === 'string') {
      return Number(JSON.parse(perm.value))
    }
    return perm.type
  }

  _castAvailable = (perm: Permission) => {
    if (perm.type === 'boolean') {
      return Boolean(JSON.parse(perm.available))
    }
    if (perm.type === 'string') {
      return String(JSON.parse(perm.available))
    }
    if (perm.type === 'string') {
      return Number(JSON.parse(perm.available))
    }
    return perm.type
  }
}

export default Permissions
