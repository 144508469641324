import type { LoadedClerk, SessionResource, UserResource } from '@clerk/types'
import { message } from 'antd'
import { makeAutoObservable } from 'mobx'
import { connectionStore } from 'stores/connections'
import { integrationStore } from 'stores/integrations'
import { LoginError, login } from 'stores/services/auth.service'
import { supportAndMarketing } from '../supportAndMarketing'
import { Advisor, LoginResponse, Practice, Tag } from 'types/graphql'
import { compact } from 'lodash'
import { setPractice, setStatuses, setTypes } from '@state/practice/practice.actions'

type NullResource = null | undefined

export interface Auth {
  get session(): SessionResource | NullResource
  get user(): UserResource | NullResource
  get orgName(): string | NullResource
  get signedIn(): boolean

  setClerk: (clerk: LoadedClerk) => void
  signIn: (orgId: string, orgSlug: string) => void
  signOut: () => void
  reload: () => void
  getToken: () => void
}

class ClerkAuth implements Auth {
  clerk: LoadedClerk
  loaded: boolean = false
  orgId: string
  orgSlug: string

  get session() {
    return this.clerk?.session
  }

  get user() {
    return this.clerk?.user
  }

  get orgName() {
    return this.clerk?.organization?.name
  }

  get signedIn() {
    return !!(this.orgId && this.orgSlug && this.loaded)
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  /**
   * @param clerk
   */
  setClerk = (clerk: LoadedClerk) => {
    this.clerk = clerk
  }

  /**
   * @param orgId
   * @param orgSlug
   * @returns
   */
  signIn = async (orgId: string, orgSlug: string): Promise<any> => {
    // if no org, or same as current org, abort
    if (!orgId || orgId === this.orgId) {
      return false
    }

    console.log('LoadOrgId: ' + orgId)

    this.loaded = false
    const resp = await login()

    // if error, display it and go back to signIn
    if (resp.error) {
      if (typeof resp.error === 'string') {
        message.error(resp.error, 6)
      }
      return this.signOut()
    }

    this._setInitialState(resp)
    supportAndMarketing.initializePosthog(resp as LoginResponse)
    supportAndMarketing.initializeIntercom()

    Object.assign(this, {
      loaded: true,
      orgSlug,
      orgId,
    })
  }

  /**
   * @returns
   */
  signOut = async () => {
    await this.clerk?.signOut()
    this.loaded = false
  }

  /**
   *
   */
  reload = async () => {
    await this.clerk?.organization?.reload()
    // global.router.reload()
  }

  /**
   * @returns
   */
  getToken = async () => {
    return this.session?.getToken()
  }

  /**
   * @param resp
   */
  private _setInitialState = (resp: LoginResponse | LoginError) => {
    if (resp.error) return

    const data = resp as LoginResponse
    const isAdmin = data.advisor?.isAdmin || false

    integrationStore.setIntegrations(compact(data.integrations), compact(data.integration_syncs))
    connectionStore.setConnections(
      compact(data.connections),
      isAdmin ? compact(data.adminConnections) : []
    )

    setStatuses(data.statuses)
    setTypes(data.types)
    setPractice(data.practice as Practice, data.advisor as Advisor)

    global.data.advisors.setMe(data.advisor!)
    global.tags.setTags(data.tags as Tag[])
  }
}

const authStore = new ClerkAuth()
export { authStore }
