import { isRemirrorJSON } from 'remirror'
import { htmlToRemirrorJSON, remirrorJSONToHtml } from 'lib/remirror/util/remirror.util'
import { Maybe } from 'types/graphql'
import Str from '@stores/util/str.util'
import { isEmpty } from 'remeda'

export const toContentInput = (note: {
  text?: Maybe<string> | undefined
  content?: Maybe<string> | undefined
  [key: string]: any
}): string => {
  if (Str.isJSON(note.content)) {
    return note.content as string
  }

  if (isRemirrorJSON(note.content)) {
    return JSON.stringify(note.content)
  }

  if (isEmpty(note?.content!)) {
    if (isRemirrorJSON(note.text)) {
      return note.text
    }

    return htmlToRemirrorJSON(note?.text ?? '')
  }

  return ''
}

export const toTextInput = (note: {
  text?: Maybe<string> | undefined
  content?: Maybe<string> | undefined
  [key: string]: any
}): string => {
  if (isEmpty(note.text!)) {
    if (isRemirrorJSON(note.content)) {
      return JSON.stringify(remirrorJSONToHtml(note.content))
    }
  }

  if (isRemirrorJSON(note.text)) {
    return remirrorJSONToHtml(note.text)
  }

  return note.text ?? ''
}
