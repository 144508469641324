import { ReactNode, useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { History } from 'history'

// Define an interface for our router props
interface RouterProps {
  children: ReactNode
  basename?: string
}

/**
 * Custom router component that uses the global router history
 * Updated for React 18 compatibility
 */
export const CustomRouter = ({ children, basename }: RouterProps) => {
  const history = global.router.history as History
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => {
    const unlisten = history.listen(setState)
    return unlisten
  }, [history])

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  )
}
