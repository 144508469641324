import { ClerkProvider } from '@clerk/clerk-react'
import { ConfigProvider } from 'antd'
import { SIGNIN, SIGNUP } from 'constants/auth'
import { Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from 'routes/protected'
import { SignIn } from 'routes/signin'
import { SignUp } from 'routes/signup'
import { layout, variables } from 'styles/clerk'
import { MainApp } from './App'
import { theme } from 'styles/theme'
import enUS from 'antd/locale/en_US'

export const App = () => (
  <ClerkProvider
    publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
    telemetry={false}
    appearance={{
      variables,
      layout,
    }}
  >
    <ConfigProvider locale={enUS} theme={theme}>
      <Routes>
        {/* Public Routes */}
        <Route path={`${SIGNIN}/*`} element={<SignIn />} />
        <Route path={`${SIGNUP}`} element={<SignUp />} />

        {/* Private Routes */}
        <Route
          path={'/*'}
          element={
            <ProtectedRoute>
              <MainApp />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  </ClerkProvider>
)
