import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// @ts-expect-error
import apolloLogger from 'apollo-link-logger';
import { createClient } from 'graphql-ws';
import { get } from 'lodash';
import { authStore } from 'stores/auth/index';
import { errorHandlers } from './errors';
// @ts-expect-error
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const REGEX = /(http)(s)?:\/\//;
const wsUri = (uri: any) => uri.replace(REGEX, 'ws$2://');

const authErrors = ['not_authorized', 'invalid_token', 'invalid_user', 'invalid_org_error'];

/**
 * logs graphql errors to the console
 */
export const errorLink = () => {
  return onError(({ operation, graphQLErrors, networkError }) => {
    const operationName = get(operation, 'operationName', 'unknown_operation');

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, extensions }) => {
        let stack;
        if (extensions) {
          stack = get(extensions, 'exception.stacktrace[0]', stack);
        }
        console.error(
          `[GraphQL error]: Message: ${message}, Operation: ${operationName} Stack: ${stack}`
        );
      });
    }

    if (networkError) {
      const code = get(
        networkError,
        'result.errors[0].extensions.code',
        JSON.stringify(networkError, null, 2)
      );

      // Let Clerk handle auth errors
      if (authErrors.every((err) => !code.includes(err))) {
        console.error(`[Network error]: ${networkError}`);
        errorHandlers(code);
      }
    }
  });
};

/**
 * stateless link to add headers
 */
export const authLink = () =>
  setContext(async (_, { headers }) => {
    const token = await authStore.getToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

/**
 * link for socket transport
 * @param {*} options Either SubscriptionClient or an object with three options on it to customize the behavior of the link
 */
export const wsLink = (uri: any) =>
  new GraphQLWsLink(
    createClient({
      url: wsUri(uri),
      connectionParams: async () => {
        const token = await authStore.getToken();
        return {
          authToken: token,
        };
      },
      lazy: true,
    })
  );

export const uploadLink = (uri: any): ApolloLink =>
  createUploadLink({
    uri: uri,
    headers: {
      'keep-alive': 'true',
    },
  });

export const logLink = () => ApolloLink.from([apolloLogger]);
