import dayjs from 'dayjs';

export const pause = async (duration = 1000) => {
  await new Promise((res) => setTimeout(res, duration));
};

export function calculateIntervals(
  interval = 15,
  openingHour = 7,
  openingMinute = 0,
  closingHour = 19,
  closingMinute = 0
) {
  const now = dayjs(); // Get the current time
  let startDate = dayjs().set('hour', openingHour).set('minute', openingMinute).set('second', 0);
  const closingLimit = dayjs()
    .set('hour', closingHour)
    .set('minute', closingMinute)
    .set('second', 0);

  const dates: dayjs.Dayjs[] = [];
  while (startDate.isBefore(closingLimit) || startDate.isSame(closingLimit)) {
    if (startDate.isAfter(now)) {
      dates.push(startDate); // Only add future times
    }
    startDate = startDate.add(interval, 'minute');
  }

  return dates;
}

export function getCurrentRoundedTime(interval = 15, roundUp = false) {
  return getRoundedTime(dayjs(), interval, roundUp);
}

export function getRoundedTime(otherDayjs: any, interval = 30, roundUp = false) {
  const roundDirection = roundUp ? 'ceil' : 'floor';
  const rounded = dayjs(otherDayjs)
    .minute(Math[roundDirection](dayjs().minute() / interval) * interval)
    .second(0)
    .millisecond(0);

  if (rounded.isAfter(otherDayjs, 'day')) {
    return rounded.subtract(interval, 'minutes');
  }

  return rounded;
}

export function composeDateTime(date: any, time: any) {
  const timeDayjs = dayjs(time);
  return dayjs(date).hour(timeDayjs.hour()).minute(timeDayjs.minute()).second(0).millisecond(0);
}
