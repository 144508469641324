import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionTag,
  InputRule,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  ProsemirrorNode,
  setBlockType,
  Static,
} from '@remirror/core'
import { textblockTypeInputRule } from '@remirror/pm/inputrules'
import { NodePasteRule } from '@remirror/pm/paste-rules'

interface NodeTypeOptions {
  /**
   * The available node types to select. The `value` property refers to the NodeType name, while
   * the `key` of the object is used to be referred outside the extension. Optional `attrs` can be
   * passed along.
   *
   * @defaultValue `{ p: { value: 'paragraph' },
      h1: { value: 'heading', attrs: { level: 1 } },
      h2: { value: 'heading', attrs: { level: 2 } },
      h3: { value: 'heading', attrs: { level: 3 } },
      h4: { value: 'heading', attrs: { level: 4 } },
      pre: { value: 'codeBlock' } }`
   */
  nodeTypes?: Static<{
    [key: string]: { value: string; attrs?: { [key: string]: any } }
  }>

  /**
   * The default level heading to use.
   *
   * @defaultValue 'p'
   */
  defaultNodeType?: Static<string>
}

type ChangeNodeTypeValue = string

// @ts-ignore
@extension<NodeTypeOptions>({
  defaultOptions: {
    nodeTypes: {
      p: { value: 'paragraph' },
      h1: { value: 'heading', attrs: { level: 1 } },
      h2: { value: 'heading', attrs: { level: 2 } },
      h3: { value: 'heading', attrs: { level: 3 } },
      h4: { value: 'heading', attrs: { level: 4 } },
      pre: { value: 'codeBlock' },
    },
    defaultNodeType: 'p',
  },
  staticKeys: ['defaultNodeType', 'nodeTypes'],
})
export class NodeTypeExtension extends NodeExtension<NodeTypeOptions> {
  get name() {
    return 'nodeType' as const
  }

  createTags() {
    return [
      ExtensionTag.Block,
      ExtensionTag.TextBlock,
      ExtensionTag.FormattingNode,
    ]
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride,
  ): NodeExtensionSpec {
    return {
      content: 'inline*',
      defining: true,
      draggable: false,
      ...override,
      attrs: {
        ...extra.defaults(),
        nodeTypeSelected: {
          default: this.options.defaultNodeType,
        },
      },
      parseDOM: [
        ...Object.keys(this.options.nodeTypes).map((nodeTypeKey) => {
          const nodeType = this.options.nodeTypes[nodeTypeKey]
          return {
            tag: nodeType.value,
            getAttrs: (element: string | Node) => ({
              ...extra.parse(element),
              nodeType: nodeType.value,
            }),
          }
        }),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node: ProsemirrorNode) => {
        const nodeTypeToUse =
          this.options.nodeTypes[node.attrs.nodeTypeSelected]
        if (!nodeTypeToUse) {
          const defaultNodeToUse =
            this.options.nodeTypes[this.options.defaultNodeType]
          return [defaultNodeToUse.value, extra.dom(node), 0]
        }
        return [nodeTypeToUse.value, extra.dom(node), 0]
      },
    }
  }

  /**
   * Toggle the heading for the current block. If you don't provide the
   * level it will use the options.defaultNodeType.
   */
  @command()
  changeNodeType(nodeType: ChangeNodeTypeValue = ''): CommandFunction {
    return ({ dispatch, tr, state }) => {
      if (nodeType) {
        const nodeTypeSelected = this.options.nodeTypes[nodeType]
        setBlockType(
          nodeTypeSelected.value,
          nodeTypeSelected.attrs,
          state.selection,
        )({ tr, dispatch, state })
        return true
      }
      return false
    }
  }

  createInputRules(): InputRule[] {
    return Object.keys(this.options.nodeTypes).map((nodeTypeKey) => {
      const nodeType = this.options.nodeTypes[nodeTypeKey]
      return textblockTypeInputRule(
        new RegExp(`^(#{1,${nodeType}})\\s$`),
        this.type,
        () => ({
          nodeType,
        }),
      )
    })
  }

  createPasteRules(): NodePasteRule[] {
    return Object.keys(this.options.nodeTypes).map((nodeTypeKey) => {
      const nodeType = this.options.nodeTypes[nodeTypeKey]
      return {
        type: 'node',
        nodeType: this.type,
        regexp: new RegExp(`^#{${nodeType}}\\s([\\s\\w]+)$`),
        getAttributes: () => ({ nodeType }),
        startOfTextBlock: true,
      }
    })
  }
}
