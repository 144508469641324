import { Attrs } from '@remirror/pm/model'
import { EditorState, Mark } from 'remirror'
import { MergeFieldToReplaceHeader, NoteTag } from '../../../NoteReplacer.interfaces'
import { TagTypes } from 'constants/tags'

export function TopicTitle({
  kind,
  header,
  state,
  mergeFieldType,
  placeholderTags,
  marks,
  attrs,
  onlyText,
}: {
  kind: string
  header: MergeFieldToReplaceHeader
  state: Readonly<EditorState>
  mergeFieldType: string
  placeholderTags: string | NoteTag[]
  marks: Readonly<Mark[]>
  attrs?: Readonly<Attrs>
  onlyText?: boolean
}) {
  if (!(kind === 'topic' && (header === 'always' || header === '' || header === 'add'))) {
    return null
  }

  const subtopic = Array.isArray(placeholderTags)
    ? placeholderTags.find((tag) => tag.type === TagTypes.SUBTOPIC)?.name
    : placeholderTags

  const text = state.schema.text(
    `${mergeFieldType}${subtopic ? ' - ' + subtopic : ''}`,
    removesDuplicateMarks([
      ...marks,
      state.schema.marks['bold'].create(),
      state.schema.marks['fontSize'].create({ size: '16' }),
    ])
  )

  if (onlyText) {
    return text
  }

  const noteGroupTitle = state.schema.nodes.paragraph.create(attrs, text)

  return noteGroupTitle
}

function removesDuplicateMarks(marks: Readonly<Mark[]>) {
  const uniqueMarks = new Set()

  return marks.filter((mark) =>
    uniqueMarks.has(mark.type.name) ? false : uniqueMarks.add(mark.type.name)
  )
}
