import { Form, FormInstance } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConnection } from 'stores/connections/connection.hooks'
import { smartfieldStore } from 'stores/smartfields'
import { ERRORS } from './generic.errors'
import {
  ModelSelects,
  parseDataFields,
  parseRequiredSelects,
} from './generic.utils'
import { BaseContactType, ContactFactory } from './generic.factory'

export const useGenericModelData = <TContactType extends BaseContactType>(
  factory: ContactFactory<TContactType>,
  form?: FormInstance,
  uid: string = 'uid',
) => {
  const { loadContacts, loadContact } = factory

  const [contacts, setContacts] = useState<TContactType[]>([])
  const [contact, setContact] = useState<any>()

  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const { connected } = useConnection(factory.provider)
  const contactId = Form.useWatch('contact_id', form)

  const setGenericError = (err: string) => {
    setError(err)
  }

  const onLoadContacts = useCallback(
    (name?: string) => {
      if (!connected) {
        return setGenericError(ERRORS.INTEGRATION_ERROR)
      }

      setContacts([])
      setError(undefined)
      setLoading(true)

      loadContacts(name)
        .then((cls) => {
          if (!cls || !cls.length) {
            return setGenericError(ERRORS.NO_CONTACTS_ERROR)
          }

          setContacts(cls)
        })
        .catch(() => {
          return setGenericError(ERRORS.LOAD_CONTACTS_ERROR)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [uid, connected, loadContacts],
  )

  useEffect(() => {
    onLoadContacts()
  }, [uid, connected, onLoadContacts])

  useEffect(() => {
    if (contactId) {
      global.bus.emit('DISABLE_SMARTFIELD_CHANGE', true)

      loadContact(contactId)
        .then((cli) => {
          if (!cli) {
            return setGenericError(ERRORS.NO_CONTACT_ERROR)
          }

          setContact(cli)
        })
        .catch(() => {
          return setGenericError(ERRORS.LOAD_CONTACT_ERROR)
        })
        .finally(() => {
          global.bus.emit('DISABLE_SMARTFIELD_CHANGE', false)
        })
    }
  }, [contactId, uid])

  return useMemo(
    () => ({
      contact,
      contacts,
      loading,
      error,
      onLoadContacts,
    }),
    [contact, contacts, loading, error, onLoadContacts],
  )
}

export const useModelSelects = <TContactType extends BaseContactType>(
  contact: any,
  factory: ContactFactory<TContactType>,
  smartFieldDataFields: any,
  form: FormInstance,
  uid: string,
) => {
  const [selects, setSelects] = useState<ModelSelects[]>([])
  const [indices, setIndices] = useState<number[]>([])
  const [error, setError] = useState<string | undefined>()

  const onSelectIndice = useCallback(
    (indice = 0, index: number) => {
      const newIndices = [...indices]
      newIndices[index] = indice

      setIndices(newIndices)
      setError(undefined)
    },
    [indices],
  )

  const onUpdate = useCallback(
    (val: any) => {
      smartfieldStore.update({ [uid]: val })
      // form?.setFieldValue(uid, val)
    },
    [form, uid],
  )

  useEffect(() => {
    if (contact?.id) {
      const fields = parseDataFields(smartFieldDataFields)
      setIndices(new Array(fields.length))
      setError(undefined)
      setSelects([])
    }
  }, [contact?.id, smartFieldDataFields])

  useEffect(() => {
    if (indices?.length) {
      try {
        const parseFields = async () => {
          setSelects(
            await parseRequiredSelects(
              contact,
              factory,
              smartFieldDataFields,
              indices,
              onUpdate,
            ),
          )
          setError(undefined)
        }
        parseFields()
      } catch (er) {
        setError(ERRORS.UNEXPECTED_ERROR)
      }
    }
  }, [contact?.id, indices, factory, onUpdate])

  return useMemo(
    () => ({
      selects,
      error,
      onSelectIndice,
    }),
    [selects, error, onSelectIndice],
  )
}
