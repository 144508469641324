const getPulse360SubDomain = () => {
  switch (true) {
    case import.meta.env.ENV === 'dev':
      return 'app.dev'
    case import.meta.env.ENV === 'staging':
      return 'app.staging'
    default:
      return 'app'
  }
}

export const getPulse360Domain = (canBeLocal = false) => {
  if (import.meta.env.DEV && canBeLocal) {
    return `http://localhost:9001`
  }

  return `https://${getPulse360SubDomain()}.pulse360.com`
}
