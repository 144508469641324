import { FileAttachment } from 'components/routes/tasks/notes/notes.utils'
import { autorun } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import {
  FileObject,
  LoadFilesDocument,
  LoadFilesQuery,
  LoadFilesQueryVariables,
} from 'types/graphql'
import { filterSelected, filterSystemFiles } from './file.utils'
import { useSnapshot } from 'valtio'
import { fileState } from './file.state'
import { useQuery } from '@apollo/client'

export const useLoadFiles = (path: string = '') => {
  const [files, setFiles] = useState<FileObject[]>([])

  const { data, loading, error } = useQuery<LoadFilesQuery, LoadFilesQueryVariables>(
    LoadFilesDocument,
    { variables: { path } }
  )

  useEffect(() => {
    if (!loading && data?.loadFiles.__typename === 'FileObjectsResult') {
      const loadedFiles = data.loadFiles.files.map((file) => ({ ...file, uid: `${file.id}` }))

      loadedFiles.forEach((file) => {
        fileState.files.set(file.key, file)
      })

      setFiles(loadedFiles)
    }

    fileState.loading = loading
  }, [data, loading])

  return { files, loading, error }
}

export const useVaultFiles = () => {
  const { files, loading, loaded } = useSnapshot(fileState)

  useEffect(() => {
    if (files.size === 0 || !loaded) {
      fileState.loadAll()
    }
  }, [files, loaded])

  return {
    files,
    loading,
  }
}

const useFiles = () => {
  const [files, setFiles] = useState<FileObject[]>([])
  const [attachments, setAttachments] = useState<FileObject[]>([])

  useEffect(() => {
    return autorun(() => {
      setFiles(global.data.docs.documents)
    })
  }, [])

  useEffect(() => {
    setAttachments(filterSystemFiles(files))
  }, [files])

  return useMemo(
    () => ({
      files,
      attachments,
    }),
    [files, attachments]
  )
}

/**
 * Filter all 'attachments' by files present in the 'selected' array
 * @param current
 * @param all
 * @returns
 */
export const useFilteredAttachments = (selected: FileAttachment[]) => {
  const [filtered, setFiltered] = useState<FileObject[]>([])

  const { attachments } = useFiles()

  useEffect(() => {
    setFiltered(filterSelected(selected, attachments))
  }, [attachments, selected])

  return useMemo(
    () => ({
      filtered,
    }),
    [filtered]
  )
}
