export const contactFields = {
  client: {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    phone: {
      country: 'Country',
      code: 'Code',
      number: 'Number',
    },
    status: 'Security Status',
  },
  coclient: {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    phone: {
      country: 'Country',
      code: 'Code',
      number: 'Number',
    },
    status: 'Security Status',
  },
  quality: {
    score: 'Data Quality Score (0 to 100)',
  },
  pipeline: {
    title: 'Title',
    description: 'Description',
    icon: 'Icon',
  },
  favorited: 'Favorited',
  archived: 'Archived',
  created_at: 'Created At',
  updated_at: 'Last Updated At',
}
