/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Filters } from "constants/notes";
import { isTaggedAs, TAG } from "constants/tags";
import { getQueryParams } from "helpers/href";
import { sortBy, uniqBy } from "lodash";
import { FileObject, Note } from "types/graphql";
import { NoteExtended } from "../interfaces/NoteExtended.interface";
import {
  CATEGORY_ITEM_PROP,
  TEMPLATE_CATEGORY_NAME,
} from "./document/constants/DocumentEditor.constants";
import {
  EditorPreset,
  EditorPresets,
  EditorViews,
  FilterTypes,
} from "./notes.constants";

/**
 * @param filter
 * @param onlyInclude
 * @returns
 */
export const editorPreset = (filter?: string): EditorPreset => {
  const presetName = validFilterValue(filter);
  return EditorPresets(presetName);
};

/**
 * @param editor
 * @returns
 */
export const isEditorHidden = (editor: string) => {
  return (
    editor === null || editor === undefined || editor === EditorViews.HIDDEN
  );
};

/**
 * @param editor
 * @returns
 */
export const isEditorVisible = (editor: string) => {
  return !isEditorHidden(editor);
};

/**
 * @param filter
 * @returns
 */
export const findTemplateCategory = (filter?: string) => {
  const fltr = validFilterValue(filter);

  return Object.keys(TEMPLATE_CATEGORY_NAME).find(
    (key) => (TEMPLATE_CATEGORY_NAME as any)[key] === fltr
  );
};

/**
 * @param param
 * @param paramName
 * @returns
 */
export const paramValueOrDefault = (
  paramValue: any,
  paramName: string
): unknown => {
  if (!!paramValue) {
    return paramValue;
  }

  const preset = editorPreset();
  return (preset as any)[paramName];
};

/**
 * @param param
 * @param paramName
 * @returns
 */
export const validFilterValue = (filter?: string): string => {
  const fltr = modifyApptFilter(filter ?? getQueryParams().filter);

  if (!fltr || !Object.values(Filters).some((f) => f === fltr)) {
    return FilterTypes.NOTES;
  }

  return fltr;
};

/**
 * Change filter=appts => filter=global
 * @param filter
 * @returns
 */
export const modifyApptFilter = (filter: string) => {
  if (filter === FilterTypes.APPTS) {
    return FilterTypes.NOTES;
  }

  return filter;
};

/**
 * @param template
 * @param notes
 * @returns
 */
const filterByType = (template: any, notes: Note[]) => {
  const templateId =
    template?.templateCategoryId || Number(global.router.params.category);
  const type = (TEMPLATE_CATEGORY_NAME as any)[templateId];
  const prop = (CATEGORY_ITEM_PROP as any)[type];

  return notes.filter((note) => (note as any)[prop]);
};

/**
 * @param template
 * @param filteredNotes
 * @returns
 */
export const getAppliedNotes = (filteredNotes: NoteExtended[]) => {
  return filteredNotes.filter((note) => note.included);
};

export interface FileAttachment {
  file: FileObject;
  note?: Note;
  warning: string;
  missing: boolean;
  local?: boolean;
}

/**
 * @param notes
 * @returns
 */
export const parseNoteAttachments = (notes: Note[] = []): FileAttachment[] => {
  return notes.reduce<FileAttachment[]>((attachs, note) => {
    const files = filterValidFiles(note);

    files.forEach((file) => {
      const fileObj = global.data.docs.get(file);

      attachs.push({
        // @ts-expect-error TS(2322): Type 'number | { (...items: ConcatArray<never>[]):... Remove this comment to see the full error message
        file: fileObj,
        missing: !fileObj,
        warning: getWarningText(note),
        note: note,
      });
    });

    return attachs;
  }, []);
};

export interface NoteFlags {
  allClient: boolean;
  allSummary: boolean;
  allAgenda: boolean;
  someFillable: boolean;
  disabled: boolean;
}

export const noteFlagsDefaults = {
  allClient: true,
  allSummary: true,
  allAgenda: true,
  someFillable: false,
  disabled: false,
};

/**
 * @param notes
 * @returns
 */
export const parseNoteFlags = (notes: Note[]): NoteFlags => {
  return notes.reduce(
    (tags, note) => {
      if (tags.allClient && !isTaggedAs(note.tags, TAG.CLIENT)) {
        tags.allClient = false;
      }
      if (tags.allSummary && !note.isSummary) {
        tags.allSummary = false;
      }
      if (tags.allAgenda && !note.isAgenda) {
        tags.allAgenda = false;
      }
      return tags;
    },
    { ...noteFlagsDefaults }
  );
};

/**
 * @param note
 * @returns
 */
export const filterValidFiles = (note: Note): any[] => {
  const { file1 = 0, file2 = 0, file3 = 0 } = note;
  const files = [file1, file2, file3];

  return files.filter((file) => {
    // ignore files with an id=0, as is used to specify 'no file'
    return !!file && file !== 0;
  });
};

/**
 * @param attachments
 * @returns
 */
export const warningAttachments = (
  attachments: FileAttachment[]
): FileAttachment[] => {
  return sortBy(
    attachments.filter((a) => !!a.missing),
    ["warning"]
  );
};

/**
 * @param attachments
 * @param unique
 * @returns
 */
export const validAttachments = (
  attachments: FileAttachment[],
  unique = false
): FileAttachment[] => {
  const attches = sortBy(
    attachments.filter((a) => !!a.file && !a.missing),
    (attach) => {
      return attach.file.name;
    }
  );

  if (unique) {
    return uniqBy(attches, (attach) => {
      return attach.file.id;
    });
  }

  return attches;
};

/**
 * @param note
 * @returns
 */
const getWarningText = (note: Note) => {
  const tagStrippedWords = note?.text?.replace(/<[^>]*>?/gm, "");
  const truncateAt = 40;

  const truncated = `${tagStrippedWords?.substring(0, truncateAt - 3)}...`;
  const full = tagStrippedWords?.substring(0, truncateAt);

  const length = note?.text?.length ?? 0;
  const words = length > truncateAt ? truncated : full;

  return `Note “${words}” attachment missing`;
};
