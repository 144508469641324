import dayjs from "dayjs"

// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
const end = (mmt = dayjs(), unit = 'day') => mmt.endOf(unit)
// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
const start = (mmt = dayjs(), unit = 'day') => mmt.startOf(unit)

const all = () => ({
  start: dayjs().subtract(20, 'years'),
  end: end(dayjs(), 'year'),
})

const ytd = () => ({
  start: start(dayjs(), 'year'),
  end: end(dayjs(), 'year'),
})

const q1 = () => ({
  start: start(dayjs().quarter(1), 'quarter'),
  end: end(dayjs().quarter(1), 'quarter'),
})

const q2 = () => ({
  start: start(dayjs().quarter(2), 'quarter'),
  end: end(dayjs().quarter(2), 'quarter'),
})

const q3 = () => ({
  start: start(dayjs().quarter(3), 'quarter'),
  end: end(dayjs().quarter(3), 'quarter'),
})

const q4 = () => ({
  start: start(dayjs().quarter(4), 'quarter'),
  end: end(dayjs().quarter(4), 'quarter'),
})

const lastYear = () => ({
  start: start(dayjs().subtract(1, 'year'), 'year'),
  end: end(dayjs().subtract(1, 'year'), 'year'),
})

const lastQuarter = () => {
  const current = dayjs().quarter()
  if (current === 1) {
    return {
      start: start(dayjs().subtract(1, 'year').quarter(4), 'quarter'),
      end: end(dayjs().subtract(1, 'year').quarter(4), 'quarter'),
    }
  }
  const q = current - 1
  return {
    start: start(dayjs().quarter(q), 'quarter'),
    end: end(dayjs().quarter(q), 'quarter'),
  }
}

const days = (days: any) => ({
  start: start(dayjs().subtract(days, 'days')),
  end: end()
})

const today = () => ({
  start: start(dayjs().startOf('day')),
  end: end(),
})

const earliest = (dates: any) => {
  if (!dates.length) {
    return []
  }
  return dates.reduce((pre: any, cur: any) => {
    return Date.parse(pre) > Date.parse(cur) ? cur : pre
  });
}

const latest = (dates: any) => {
  if (!dates.length) {
    return []
  }
  return dates.reduce((pre: any, cur: any) => {
    return Date.parse(pre) < Date.parse(cur) ? cur : pre
  });
}

export const since = (sinceDate: any) => {
  const now = dayjs()
  const inputDate = dayjs(sinceDate)

  if (!inputDate.isValid()) {
    throw new Error(`Invalid date format ${sinceDate}`)
  }

  const years = now.diff(inputDate, 'years')
  const months = now.diff(inputDate.add(years, 'years'), 'months')
  const days = now.diff(inputDate.add(months, 'months'), 'days')

  if (years > 0) {
    return years === 1 ? `${years} year` : `${years} years`
  } else if (months > 0) {
    return months === 1 ? `${months} month` : `${months} months`
  } else if (days > 0) {
    return days === 1 ? `${days} day` : `${days} days`
  } else {
    return 'Today'
  }
}
