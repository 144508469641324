import { Drawer } from 'antd'
import { useEffect, useState, FC } from 'react';
import styled from 'styled-components'
import { SmartFieldDrawerContent } from './smartfield'
import { useSmartFields, useSmartfieldModal } from './smartfield.hooks'

const StyledDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;

    .ant-drawer-body {
      overflow: scroll;
      padding: 0px;
    }
  }
`

type Titles = 'Create a SmartField' | 'Edit a SmartField'

export const SmartFieldDrawer: FC = () => {
  const [drawerTitle, setDrawerTitle] = useState<Titles>('Create a SmartField')

  const { visible, mode, smartfield, hideSmartFieldDrawer } = useSmartFields()
  const { modalWidth } = useSmartfieldModal()

  useEffect(() => {
    setDrawerTitle(mode === 'add' ? 'Create a SmartField' : 'Edit a SmartField')
  }, [mode])

  return (
    <StyledDrawer
      onClose={hideSmartFieldDrawer}
      title={drawerTitle}
      destroyOnClose
      width={modalWidth}
      open={visible}
    >
      <SmartFieldDrawerContent smartfield={smartfield} mode={mode} />
    </StyledDrawer>
  )
}
