import { smartFieldtypes } from 'components/drawers/Smartfields/smartfield.utils'
import {
  SmartField,
  SmartFieldMentionAttrs,
  SmartFieldType,
} from 'components/drawers/Smartfields/types'
import { removeReadonly } from 'helpers/typescript'
import { makeAutoObservable, observable, toJS } from 'mobx'
import {
  hasNewStyle,
  hasOldStyle,
  isActuallyEmpty,
  parseSmartFieldNodes,
  parseSmartFields,
  replaceNodes,
  replaceParentNodes,
  updateNote,
  updateSmartFields,
} from 'stores/smartfields/utils'
import { Note } from 'types/graphql'
import { htmlToRemirrorJSON } from '../../lib/remirror/util/remirror.util'
import { formatValue } from './formatters'
import { PERMS_KEYS } from '../../constants/perms.constants'

export type SmartFieldSettingsMode = 'edit' | 'add' | 'closed'

type ClosedSmartFieldSettings = {
  mode: 'closed'
  forceReusable?: boolean
}

export type AddSmartFieldSettings = {
  callback: (mentionAttrs: SmartFieldMentionAttrs) => void
  mode: 'add'
  forceReusable: boolean
}

export type EditSmartFieldSettings = {
  callback: (mentionAttrs: SmartFieldMentionAttrs) => void
  attrs: SmartFieldMentionAttrs
  mode: 'edit'
  forceReusable?: boolean
}

type SmartFieldSettings = ClosedSmartFieldSettings | AddSmartFieldSettings | EditSmartFieldSettings

// { [smartfieldId]: "NewValueForSmartfield" }
export type NoteReplacementValues = Record<string, unknown>

export class SmartFieldsStore {
  @observable.shallow
  settings: SmartFieldSettings = { mode: 'closed' }
  values = observable.map<Note, NoteReplacementValues>([])

  // replacer
  visible: boolean = false
  smartfieldIndex: number = 0
  notes: Note[] = []
  noteIndex: number = 0

  localUpdateNote?: (doc: Document, note: Note) => Promise<void> | undefined

  get note(): Note {
    return this.notes?.[this.noteIndex]
  }

  get noteClone(): Note | undefined {
    if (this.note) {
      const clone = observable({ ...this.note })
      clone.text = updateSmartFields(this.note)
      return clone
    }

    return undefined
  }

  get text(): string {
    return this.noteClone?.text as string
  }

  get nodes(): HTMLSpanElement[] {
    return parseSmartFieldNodes(this.note as Note)
  }

  get smartfields(): SmartField[] {
    return parseSmartFields(this.nodes)
  }

  get smartfield(): SmartField {
    return this.smartfields?.[this.smartfieldIndex]
  }

  get node(): HTMLSpanElement | undefined {
    return this.nodes[this.smartfieldIndex]
  }

  get replaceMode(): 'single' | 'multi' {
    return this.notes.length > 1 ? 'multi' : 'single'
  }

  get currentValue() {
    const values = this.getNoteValues()

    if (this.smartfield) {
      return values?.[this.smartfield.id]
    }

    return null
  }

  get currentValueFormatted() {
    const values = this.getNoteValues()

    if (this.smartfield) {
      return values?.[this.smartfield.id + '-formatted']
    }

    return null
  }

  get modalTitle(): string {
    if (this.replaceMode === 'single') {
      return `${this.smartfields?.length} SmartFields to Replace`
    }

    return `${this.notes?.length} Notes with SmartFields`
  }

  get noteTitle(): string {
    if (this.replaceMode === 'single') {
      return `SmartField ${this.smartfieldIndex + 1} of ${this.smartfields.length}`
    }

    return `SmartField ${this.smartfieldIndex + 1} of ${this.smartfields.length}, for Note ${this.noteIndex + 1}`
  }

  get moreNotes() {
    return this.noteIndex < this.notes.length - 1
  }

  get moreFields() {
    return this.smartfieldIndex < this.smartfields.length - 1
  }

  get firstField() {
    const firstSmartField = this.smartfieldIndex === 0
    const firstNote = this.noteIndex === 0

    return firstSmartField && firstNote
  }

  get lastField() {
    const lastSmartField = this.smartfieldIndex === this.smartfields.length - 1
    const isLastOne = lastSmartField && !this.moreNotes

    return isLastOne
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setSettings = <T extends SmartFieldSettings>(settings: SmartFieldSettings) => {
    this.settings = settings as T
  }

  /**
   * @param _changed
   * @param values
   */
  update = (value: NoteReplacementValues) => {
    if (this.noteClone) {
      const { id, label } = this.smartfield

      const newValue = this.saveNoteValues(value)
      const formatted = newValue[`${id}-formatted`] ?? label // fallback to existing label

      const text = replaceNodes(this.noteClone, { [id]: formatted })
      this.noteClone.text = text
    }
  }

  /**
   * @param values
   */
  finish = () => {
    // TODO: can't figure out how to mock this
    if (process.env.NODE_ENV !== 'test') {
      for (const note of this.notes) {
        const allValues = this.getNoteValues(note)

        // replace all smartfield id values with the formatted versions
        const formatted = Object.keys(allValues).reduce((all, key) => {
          if (/.*-formatted/.test(key)) {
            const smartfieldId = key.replace('-formatted', '')

            all[smartfieldId] = all[key]
            delete all[key]
          }

          return all
        }, allValues)

        const doc = replaceParentNodes(note, formatted)
        if (note.isMirrored) {
          this.createNoteFromMirrored(doc, note)
        } else {
          if (this.localUpdateNote) {
            this.localUpdateNote(doc, note)
          } else {
            updateNote?.(doc, note)
          }
        }
      }
    }

    this.hideReplaceSmartFieldModal()
  }

  /**
   * ui.route.settings.smartfields-integration
   * @param filter
   * @returns
   */
  smartFieldTypes = (filter: SmartFieldType[] | SmartFieldType = []): SmartFieldType[] => {
    const filters = ([] as string[]).concat(filter)

    const intgrsEnabled = global.perms?.get(PERMS_KEYS.SMARTFIELDS_INTEGRATION) ?? 'true'
    if (!intgrsEnabled && !filters.includes('integration')) {
      filters.push('integration')
    }

    const types: SmartFieldType[] = removeReadonly(smartFieldtypes).filter(
      (t) => !filters?.includes(t)
    )

    return types
  }

  /**
   * Move to next smartfield
   */
  nextSmartfield = () => {
    if (this.moreFields) {
      this.smartfieldIndex += 1
    } else if (this.moreNotes) {
      this.smartfieldIndex = 0
      this.noteIndex += 1
    }
  }

  /**
   * Move to the previous smartfield
   */
  previousSmartfield = () => {
    if (this.smartfieldIndex > 0) {
      this.smartfieldIndex -= 1
    } else if (this.noteIndex > 0) {
      this.noteIndex -= 1

      const previousSmartfields = parseSmartFieldNodes(this.notes[this.noteIndex])
      this.smartfieldIndex = previousSmartfields.length - 1
    }
  }

  /**
   *
   */
  incrementSmartfield = () => {
    this.smartfieldIndex += 1
  }

  /**
   * Show ReplaceSmartfieldModal for single note
   * @param note
   */
  showReplaceSmartFieldModal = (
    note: Note,
    onUpdate?: (doc: Document, note: Note) => Promise<void>
  ) => {
    this.visible = true
    this.notes = [note]
    this.smartfieldIndex = 0
    this.noteIndex = 0
    this.localUpdateNote = onUpdate
  }

  /**
   * Show ReplaceSmartfieldModal for multiple notes
   * @param notes
   * @param index
   */
  showReplaceSmartFieldsModal = (
    notes: Note[] = this.notes,
    index = 0,
    onUpdate?: (doc: Document, note: Note) => Promise<void>
  ) => {
    this.visible = true
    this.notes = notes
    this.smartfieldIndex = 0
    this.noteIndex = index
    this.localUpdateNote = onUpdate
  }

  /**
   * @param force
   * @returns
   */
  hideReplaceSmartFieldModal = (force = false) => {
    // In 'multi' mode, we check if there are more notes that need their SmartFields replacing
    // If there are, we show a new ReplaceSmartFieldsModal, with the next note in sequence
    if (this.moreNotes && !force) {
      return this.showReplaceSmartFieldsModal(this.notes, this.noteIndex + 1)
    }

    this.reset()
  }

  /**
   * @param text
   * @returns
   */
  hasSmartfield = (text = '', onlyNew: boolean = false) => {
    if (onlyNew) return hasNewStyle(text)

    return hasOldStyle(text) || hasNewStyle(text)
  }

  /**
   * @param note
   * @param values
   */
  saveNoteValues = (values: NoteReplacementValues): NoteReplacementValues => {
    const currentValues = this.values.get(this.note) as NoteReplacementValues

    // save a formatted version of the value, for preview purposes, and
    // when finally replacing the smartfield with the replaced value
    const formattedValues = Object.keys(values).reduce((all, key) => {
      const value = values[key]

      if (!isActuallyEmpty(value)) {
        return {
          ...all,
          [`${key}-formatted`]: formatValue(this.smartfield, value),
          [key]: value,
        }
      }

      delete all[`${key}-formatted`]
      return all
    }, currentValues)

    this.values.set(this.note, formattedValues)
    return formattedValues
  }

  /**
   * @param note
   * @param values
   */
  getNoteValues = (note: Note = this.note): NoteReplacementValues => {
    if (!this.values.has(note)) {
      this.values.set(this.note, {})
    }

    return toJS(this.values.get(note)) as NoteReplacementValues
  }

  /**
   *
   */
  public reset = () => {
    this.visible = false
    this.notes = []
    this.smartfieldIndex = 0
    this.noteIndex = 0
    this.values.clear()
  }

  /**
   * On fill smartfields, create new note if this one was mirrored
   * @param doc
   * @param note
   */
  createNoteFromMirrored = async (doc: Document, note: Note) => {
    const text = doc.body.innerHTML
    const content = htmlToRemirrorJSON(text)

    note.text = text
    note.content = content

    global.bus.emit('FILL_SMARTFIELD_MIRRORED_NOTE', note)
  }
}

const smartfieldStore = new SmartFieldsStore()
export { smartfieldStore }
