import { isNil } from 'lodash'
import { IsHTMLElement, Nullable } from 'types/helpers'

/**
 * Create a Document model shortcut
 * @param {*} html
 */
export const DOM = (html = '', cleanDOM = true): Document => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return cleanDOM ? (clean(doc) as Document) : doc
}

export const BODY = (html = '', cleanDOM = true): HTMLElement => {
  const doc = DOM(html, cleanDOM)

  return doc.body
}

/**
 * Shortcut to create a Header tag
 *
 */
export const Element = <T extends keyof HTMLElementTagNameMap>(
  tag: T,
  content?: Nullable<string>,
): HTMLElementTagNameMap[T] => {
  const element = document.createElement(tag)
  if (!isNil(content)) {
    element.innerHTML = content
  }
  return element
}

/**
 * Shortcut to create a Span tag
 *
 */
export const Span = (content: string) => {
  return Element('span', content)
}

/**
 * Cleans 'invisible' whitespace text nodes from the dom structure
 * https://www.sitepoint.com/removing-useless-nodes-from-the-dom/
 * @see util.test.js for exploration of this
 * @param {*} node
 */
const clean = (node: Document | HTMLElement) => {
  for (let n = 0; n < node.childNodes.length; n++) {
    const child = node.childNodes[n]
    if (isComment(child) || isEmptyText(child)) {
      node.removeChild(child)
      n--
    } else if (child.nodeType === Node.ELEMENT_NODE && IsHTMLElement(child)) {
      clean(child)
    }
  }

  return node
}

/**
 * @param {*} node
 */
const isComment = (node: Node) => {
  return node.nodeType === Node.COMMENT_NODE
}

/**
 * @param {*} node
 */
const isEmptyText = (node: Node) => {
  return (
    node.nodeType === Node.TEXT_NODE &&
    node.nodeValue &&
    !/\S/.test(node.nodeValue)
  )
}
