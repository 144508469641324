import { Node } from '@remirror/pm/model'
import { captureExceptionSilently } from 'helpers/sentry'
import type { EditorState, ResolvedPos } from 'remirror'

function needsToBeFixed(current: ResolvedPos, displayMode: string) {
  return (
    current.nodeBefore?.type.name === 'paragraph' &&
    current.nodeBefore.lastChild?.type.name === 'mentionAtom' &&
    current.nodeBefore.lastChild?.attrs.displayMode === displayMode
  )
}

function getFix(state: Readonly<EditorState>) {
  return state.schema.nodes.paragraph.create(null, state.schema.text(' '))
}

export function fixGroupingList(
  state: Readonly<EditorState>,
  pos: number,
  displayMode: string,
  cb: (node: Node) => void
) {
  try {
    const current = state.doc.resolve(pos)
    const needFix = needsToBeFixed(current, displayMode)

    if (needFix) {
      cb(getFix(state))
    }
  } catch (err) {
    // FIXME: this should use another logging exception method outside main project
    captureExceptionSilently(err, { message: 'fixGroupingList' })
  }
}
