import { Form, FormInstance, InputNumber, Row, Select, Typography } from 'antd'
import { formatNum } from 'helpers/num'
import { isEmpty } from 'lodash'
import { useCallback, useState, FC } from 'react';
import Str from '../../../../stores/util/str.util'
import { SmartFieldContainer } from '../smartfield.container'
import { FormItemLabel } from '../smartfield.styles'
import { SmartField } from '../types'
import { SmartFieldNumberConfig } from '../types/data'

interface SmartFieldNumberProps {
  smartfield?: SmartField
  form: FormInstance
  disableContainer?: boolean
  preserve?: boolean
}

const defaultDecimals = 0
const defaultDecimal = (config: SmartFieldNumberConfig): number => {
  if (config?.places && !isNaN(config?.places)) return config.places
  return defaultDecimals
}

export const SmartFieldNumber: FC<SmartFieldNumberProps> = ({
  form,
  smartfield,
  disableContainer,
  preserve = true,
}) => {
  const sf = smartfield as SmartField<SmartFieldNumberConfig>

  const [format, setFormat] = useState<string>(sf?.config?.format ?? 'auto')
  const [decimals, setDecimals] = useState<number>(defaultDecimal(sf?.config))

  const onSelectFormat = useCallback(
    (format: string) => {
      const label = form.getFieldValue('label')

      if (isEmpty(label) && !isEmpty(format)) {
        form.setFieldValue('label', Str.startCase(format))
      }

      setFormat(format)
    },
    [form],
  )

  const onChangeDecimalPlaes = useCallback((value: number | null) => {
    setDecimals(value ?? 0)
  }, [])

  return (
    <SmartFieldContainer title="Number Options" testId="smartfield-number-form" disableContainer={disableContainer}>
      <Form.Item
        name="format"
        rules={[
          {
            required: true,
            message: 'Please select a number format',
          },
        ]}
        label={<FormItemLabel label="Number Format" description="Select your number format" />}
        initialValue={sf?.config?.format ?? 'auto'}
        preserve={preserve}
      >
        <Select
          placeholder="Select format"
          style={{ width: '100%' }}
          size="large"
          onChange={onSelectFormat}
          value={format}
        >
          <Select.Option value="auto">
            <Typography.Text>None</Typography.Text>
          </Select.Option>

          <Select.Option value="percent">
            <Row justify="space-between" style={{ width: '100%' }}>
              <Typography.Text>Percent</Typography.Text>
              <Typography.Text>{formatNum(95, 'percent', decimals)}</Typography.Text>
            </Row>
          </Select.Option>

          <Select.Option value="currency">
            <Row justify="space-between" style={{ width: '100%' }}>
              <Typography.Text>Currency</Typography.Text>
              <Typography.Text>{formatNum(1000, 'currency', decimals)}</Typography.Text>
            </Row>
          </Select.Option>

          <Select.Option value="accounting">
            <Row justify="space-between" style={{ width: '100%' }}>
              <Typography.Text>Accounting</Typography.Text>
              <Typography.Text>{`$(${formatNum(1000, 'decimal', decimals)})`}</Typography.Text>
            </Row>
          </Select.Option>

          <Select.Option value="financial">
            <Row justify="space-between" style={{ width: '100%' }}>
              <Typography.Text>Financial</Typography.Text>
              <Typography.Text>{`(${formatNum(1000, 'decimal', decimals)})`}</Typography.Text>
            </Row>
          </Select.Option>
        </Select>
      </Form.Item>

      {format !== 'auto' && (
        <Form.Item
          name="places"
          rules={[
            {
              required: true,
              message: 'Please select a decimal places',
            },
          ]}
          label={<FormItemLabel label="Decimal Places" description="Number of decimal places" />}
          initialValue={defaultDecimal(sf?.config)}
          preserve={preserve}
        >
          <InputNumber size="large" max={9} min={0} onChange={onChangeDecimalPlaes} />
        </Form.Item>
      )}
    </SmartFieldContainer>
  )
}
