import * as apiService from 'lib/apollo/client'

import DataError, { IsError } from '../../helpers/dataError'
import {
  AdvisorStatsDocument,
  AdvisorStatsQuery,
  AdvisorStatsQueryVariables,
  IntercomPayloadDocument,
  IntercomPayloadQuery,
  IntercomPayloadQueryVariables,
  PracticeStatsDocument,
  PracticeStatsQuery,
  PracticeStatsQueryVariables,
  StatsCountDocument,
  StatsCountQuery,
  StatsCountQueryVariables,
} from '../../types/graphql'

class StatsService {
  async getPracticeStats() {
    const { practiceStats: resp } = await apiService.query<
      PracticeStatsQuery,
      PracticeStatsQueryVariables
    >(PracticeStatsDocument, {}, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getAdvisorStats() {
    const { advisorStats: resp } = await apiService.query<
      AdvisorStatsQuery,
      AdvisorStatsQueryVariables
    >(AdvisorStatsDocument, {}, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async intercomPayload() {
    const { intercomPayload: resp } = await apiService.query<
      IntercomPayloadQuery,
      IntercomPayloadQueryVariables
    >(IntercomPayloadDocument, {}, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async statsCount() {
    const data = await apiService.query<
      StatsCountQuery,
      StatsCountQueryVariables
    >(StatsCountDocument, {}, { fetchPolicy: 'no-cache' })

    const {
      getAdvisorsCount,
      getAppointmentsCount,
      getClientsCount,
      getNotesCount,
      getIntegrationSmartfieldStats,
    } = data
    if (IsError(getAdvisorsCount)) {
      throw new DataError(getAdvisorsCount)
    }

    if (IsError(getAppointmentsCount)) {
      throw new DataError(getAppointmentsCount)
    }

    if (IsError(getClientsCount)) {
      throw new DataError(getClientsCount)
    }

    if (IsError(getNotesCount)) {
      throw new DataError(getNotesCount)
    }

    if (IsError(getIntegrationSmartfieldStats)) {
      throw new DataError(getIntegrationSmartfieldStats)
    }

    return [
      getAdvisorsCount.count,
      getAppointmentsCount.count,
      getClientsCount.count,
      getNotesCount.count,
      getIntegrationSmartfieldStats.totalCount,
      getIntegrationSmartfieldStats.holistiplanCount,
      getIntegrationSmartfieldStats.riskalyzeCount,
      getIntegrationSmartfieldStats.wealthboxDataCount,
      getIntegrationSmartfieldStats.precisefpCount,
    ]
  }
}

export default new StatsService()
