export const STATE_VALUES = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  AskAdvisor: 'AskAdvisor',
  AskClient: 'AskClient',
  NewRequest: 'NewRequest',
  WaitingOnClient: 'WaitingOnClient',
  Complete: 'Complete',
} as const

export type NoteStatus = (typeof STATE_VALUES)[keyof typeof STATE_VALUES]

export const STATES = [
  { name: 'Pending', value: STATE_VALUES.Pending },
  { name: 'In Progress', value: STATE_VALUES.InProgress },
  { name: 'Ask Advisor', value: STATE_VALUES.AskAdvisor },
  { name: 'Ask Client', value: STATE_VALUES.AskClient },
  { name: 'Waiting for Client', value: STATE_VALUES.WaitingOnClient },
  { name: 'New Request', value: STATE_VALUES.NewRequest },
  { name: 'Complete', value: STATE_VALUES.Complete },
] as const

export const STATUS = {
  [STATE_VALUES.Pending]: 'Pending',
  [STATE_VALUES.InProgress]: 'In Progress',
  [STATE_VALUES.AskAdvisor]: 'Ask Advisor',
  [STATE_VALUES.AskClient]: 'Ask Client',
  [STATE_VALUES.WaitingOnClient]: 'Waiting for Client',
  [STATE_VALUES.NewRequest]: 'New Request',
  [STATE_VALUES.Complete]: 'Complete',
} as const

export const STATE_NAMES = {
  [STATE_VALUES.InProgress]: 'In Progress',
  [STATE_VALUES.AskAdvisor]: 'Ask Advisor',
  [STATE_VALUES.AskClient]: 'Ask Client',
  [STATE_VALUES.WaitingOnClient]: 'Waiting For Client',
  [STATE_VALUES.NewRequest]: 'New Request',
  [STATE_VALUES.Complete]: 'Complete',
  [STATE_VALUES.Pending]: 'Pending',
}

export const Filters = {
  AGENDA: 'agenda',
  SUMMARY: 'summary',
  GLOBAL: 'global',
  EVENTS: 'appts',
  ALL_NOTES: 'all',
  YEAR_TO_DATE: 'ytd',
  LAST_YEAR: 'lastyear',
  LAST_QUARTER: 'quarter',
  THIRTY_DAYS: 'thirty',
  SEVEN_DAYS: 'seven',
  TODAY: 'today',
  CUSTOM: 'custom',
  HISTORY: 'history',
  AI_WRITER: 'ai_writer',
} as const

export const FilterTitles = {
  [Filters.AGENDA]: 'Next Meeting',
  [Filters.SUMMARY]: 'Past Meeting',
  [Filters.GLOBAL]: 'Add or Review Notes',
  [Filters.EVENTS]: 'Add or Review Notes',
  [Filters.ALL_NOTES]: 'Search All Notes',
  [Filters.HISTORY]: 'View Past Communications',
  [Filters.AI_WRITER]: 'AI Writer',
} as const
