import { htmlAdapter } from 'lib/remirror/util/froala.adapter'
import { compact, filter, find, isNil, kebabCase } from 'lodash'
import { makeAutoObservable, observable, observe, toJS } from 'mobx'
import { msg } from 'stores/msg'
import { captureExceptionSilently } from '../../helpers/sentry'
import collectionService from '../services/collection.service'
import summaryService from '../services/summary.service'
import topicService from '../services/topic.service'
import {
  Collection,
  CollectionRelation,
  Maybe,
  Summary,
  Topic,
  TopicRelations,
} from 'types/graphql'
import { LibraryNote } from 'stores/library/library.types'
import { toSummaryInput } from '@helpers/mappers/inputs/summary'

export type CollectionWithItems = Collection & {
  items: Summary[]
}

type CollectionWithRelations = {
  collection: Collection
  id: number
  itemId: number
  items: Summary[]
  relations: CollectionRelation[]
}

class Topics {
  _topics: Topic[] = []
  selectedTopics: number[] = []
  _summaries: Summary[] = []
  summaries: Summary[] = []
  _collections = observable.map<number, Collection>([])
  collectionOptions: Collection[] = []
  lastTopicCreated: Topic | null = null

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    observe(
      global.auth,
      'signedIn',
      (c) => {
        if (c.newValue) {
          this.loadTopics()
          this.loadCollections()
          this.loadAllTopics()
        }
      },
      true
    )

    global.bus.on('DELETE_SUMMARIES', this.deleteSummaries)
    global.bus.on('DUPLICATE_SUMMARY', this.duplicateSummary)
  }

  get deletedTopics() {
    return this._topics
      .filter((topic) => topic.deletedAt)
      .sort((a, b) => String(a?.name ?? '').localeCompare(String(b?.name ?? '')))
  }

  get topics() {
    return this._topics
      .filter((topic) => !topic.deletedAt)
      .sort((a, b) => String(a?.name ?? '').localeCompare(String(b?.name ?? '')))
  }

  get collections(): CollectionWithItems[] {
    const collectionsMap = this._summaries.reduce((acum, item) => {
      compact(item.collections).forEach((collection: Collection) => {
        const collectionId = collection.relation?.collectionId
        const collectionData = this.getCollectionOption(collectionId)
        if (!collectionId || !collectionData) return acum

        if (!acum.has(collectionId)) {
          acum.set(collectionId, {
            collection: collectionData,
            id: Number(collectionData.id),
            itemId: Number(item.id),
            items: [...this._summaries],
            relations: [collection.relation!],
          })
        }
      })
      return acum
    }, new Map<number, CollectionWithRelations>([]))

    return Array.from(collectionsMap.values())
  }

  /**
   * @param {*} id
   */
  async loadTopics() {
    try {
      const topics = await topicService.getTopics()
      this._topics = topics

      return topics
    } catch (error) {
      msg.error(['task category', 'fetching'])
      captureExceptionSilently(error, { message: 'loadTopics' })
    }
  }

  /**
   *  WARNING: This method loads summaries not topics
   *  to load topics use loadTopics
   *
   */
  async loadAllTopics() {
    await this.loadSummaries()
    this.selectedTopics = []
  }

  async loadSummaries() {
    try {
      const summaries = await summaryService.getSummaries()
      const migratedSummaries = summaries.map((summary) => ({
        ...summary,
        text: htmlAdapter(summary?.text ?? ''),
      })) as Summary[]

      this._summaries = migratedSummaries
      this.summaries = migratedSummaries

      return migratedSummaries
    } catch (error) {
      msg.error(['library', 'fetching'])
      captureExceptionSilently(error, { message: 'loadSummaries' })
    }
  }

  /**
   * @param {*} id
   */
  async loadCollections() {
    try {
      this._collections.clear()

      const collections = await collectionService.getCollections()
      this._setCollections(compact(collections))

      return collections
    } catch (err: any) {
      captureExceptionSilently(err, { message: 'loadCollections' })
      msg.error(err.message)
    }
  }

  /**
   * @param {*} summary
   */
  async addSummary({
    summary,
    topicId,
  }: {
    topicId?: number
    summary: Summary
  }): Promise<Summary | undefined> {
    try {
      const summaryInput = toSummaryInput(summary)
      const createdSummary = (await summaryService.createSummary({
        topicId: topicId ?? summaryInput.topicId,
        summary: summaryInput,
      })) as Summary

      global.bus.emit('SUMMARY_CREATED', createdSummary as LibraryNote)
      msg.success('Added Note Template', '', 3)

      this._summaries.push(createdSummary)
      this.filterSummaries()

      return createdSummary
    } catch (error) {
      msg.error(['summary', 'adding'])
      captureExceptionSilently(error, {
        message: 'addSummary',
        data: { summary },
      })
    }

    return undefined
  }

  /**
   * @param {*} summary
   */
  duplicateSummary = async (payload: Summary) => {
    try {
      const summaryInput = toSummaryInput(payload)
      summaryInput.collections = []

      const summary = (await summaryService.createSummary({
        topicId: summaryInput.topicId,
        summary: summaryInput,
      })) as Summary

      global.bus.emit('SUMMARY_DUPLICATED', summary as LibraryNote)
      msg.success('Duplicated Note', '', 3)

      this._summaries.push(summary)
      this.filterSummaries()

      return summary
    } catch (error) {
      msg.error(['summary', 'cloning'])
      captureExceptionSilently(error, {
        message: 'duplicateSummary',
        data: { payload },
      })
    }
  }

  /**
   * @param {*} summary
   */
  async updateSummary(id: any, summaryPayload: Summary) {
    try {
      const localSummaryIndex = this._summaries.findIndex((s) => s.id === id)
      if (localSummaryIndex === -1) {
        throw new Error('No Note found with that ID', id)
      }

      const summaryInput = toSummaryInput(summaryPayload)
      const summary = await summaryService.updateSummary({
        id,
        summary: summaryInput,
      })

      msg.success('Note Updated', '', 3)

      this._summaries.splice(localSummaryIndex, 1, summary as Summary)
      global.bus.emit('SUMMARY_UPDATED', summary as LibraryNote)

      this.filterSummaries()

      return summary
    } catch (error) {
      msg.error(['summary', 'updating'])
      captureExceptionSilently(error, {
        message: 'updateSummary',
        data: { id, summaryPayload },
      })
    }
  }

  /**
   * @param {*} summary
   */
  deleteSummaries = async (summaryIds: any) => {
    try {
      await summaryService.deleteSummaries({ ids: summaryIds })
      this._summaries = this._summaries.filter(({ id }) => !summaryIds.some((s: any) => s === id))
      global.bus.emit('SUMMARY_DELETED', summaryIds)
      msg.success(`Note${summaryIds.length > 1 ? 's' : ''} deleted successfully`, '', 3)

      this.filterSummaries()
    } catch (error) {
      msg.error(`There was an issue deleting that information. We'll take a look`)
      captureExceptionSilently(error, {
        message: 'deleteSummaries',
        data: { summaryIds },
      })
    }
  }

  /**
   * @param {*} searchParam
   */
  filterSummaries(
    searchParam: number[] = this.selectedTopics,
    isFavourite = false,
    isDefault = false
  ) {
    let summaries = this._summaries.filter((s) => {
      if (!searchParam.length) return true
      return s.topicId ? searchParam.includes(s.topicId) : false
    })

    if (!isNil(isFavourite)) {
      summaries = this._summaries.filter((s) => Boolean(s.isFavourite) === isFavourite)
    }

    if (!isNil(isDefault)) {
      summaries = filter(summaries, (s) => Boolean(s.isDefault) === isDefault)
    }
    this.selectedTopics = searchParam
    this.summaries = summaries
  }

  /**
   * @param {*} id
   */
  getTopic = (id?: Maybe<number>) => {
    return id ? find(this._topics, { id: Number(id) }) : undefined
  }

  /**
   * @param {*} id
   */
  getTopicByName = (name: string) => {
    return find(this._topics, { name })
  }

  /**
   * @param {*} id
   */
  getTopicName = (id: number) => {
    const topic = find(this._topics, { id: Number(id) })
    return topic ? topic.name : id
  }

  /**
   * @param {number} collectionId
   * @param {number[]} items
   * @param {boolean} justAdding
   * @param {number[]} itemsToUpdate
   */
  updateCollectionItems = async (collectionId: any, itemsToUpdate: any, justAdding: boolean) => {
    const collection = this.collections.find(({ id }) => collectionId === id)
    const numberOfItems = justAdding ? collection?.items?.length : 0

    const summaries = toJS(this._summaries)
    const summariesBk = toJS(this._summaries)

    itemsToUpdate.forEach(({ id, present, index }: any) => {
      const localSummaryIndex = summaries.findIndex((s) => s.id === id)
      if (localSummaryIndex !== -1) {
        const summary = summaries[localSummaryIndex]

        const clearedCollections = summary.collections?.filter(
          ({ relation }: any) => collectionId !== relation.collectionId
        )
        const relation = {
          collectionId,
          order: numberOfItems + index,
          createdAt: new Date(),
        }
        const collections = !present ? clearedCollections : clearedCollections?.concat({ relation })

        summaries.splice(localSummaryIndex, 1, { ...summary, collections })
      }
    })
    this._summaries = summaries
    this.filterSummaries()

    const items = itemsToUpdate
      .filter(({ present }: any) => present)
      .sort((a: any, b: any) => a.index - b.index)
      .map(({ id }: any) => id)

    let resp
    try {
      if (justAdding) {
        resp = await collectionService.addCollectionItems(collectionId, items)
      } else {
        resp = await collectionService.setCollectionItems(collectionId, items)
      }
    } catch (_err) {
      if (!resp) {
        this._summaries = summariesBk
      }
    }
  }

  _setCollections(data: Collection[]) {
    this.collectionOptions = data

    this._collections.replace(data.map((item) => [item.id, item] as any))
  }

  getCollectionOption(id: any) {
    return this._collections.get(id)
  }

  createCollection = async (collection: any) => {
    try {
      const createdCollection = await collectionService.createCollection(collection)

      this._setCollections([...this.collectionOptions, createdCollection] as Collection[])
      return createdCollection
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'createCollection',
        data: { collection },
      })
      throw err
    }
  }

  updateCollection = async (id: any, collection: any) => {
    try {
      const updatedCollectionId = await collectionService.updateCollection(id, collection)
      this._setCollections(
        this.collectionOptions
          .filter((colOpt) => colOpt.id !== id)
          .concat({ id, ...collection, updateAt: new Date() })
      )
      return updatedCollectionId
    } catch (err) {
      captureExceptionSilently(err, {
        message: 'updateCollection',
        data: { id, collection },
      })
      throw err
    }
  }

  addTopic = async (name: any, dueDays: any) => {
    try {
      const topic = await topicService.createTopic({
        topic: { name, dueDays },
      })

      this.lastTopicCreated = topic

      this._topics = [topic, ...this._topics]
      msg.success(`Topic ${name} added`, '', 3)
      return topic
    } catch (error: any) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        data: { topic: { name, dueDays } },
        message: 'addTopic',
      })
    }
  }

  editTopic = async (id: any, name: any, dueDays: any) => {
    const topic = { id, name, dueDays }
    try {
      await topicService.updateTopic({ topic })
      this.updateTopic(id, topic)
      this.filterSummaries()

      msg.success('Topic Updated', '', 3)
      return id
    } catch (error: any) {
      msg.error(error.message)
      captureExceptionSilently(error, { message: 'edit', data: topic })
    }
  }

  getTopicRelations = async (topic: any): Promise<TopicRelations | undefined> => {
    try {
      const topicRelationCount = await topicService.getTopicRelationsCount({
        id: topic.id,
      })

      return topicRelationCount
    } catch (error: any) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'getTopicRelationsCount',
        data: topic,
      })
    }
  }

  deleteTopic = async (topic: any, replacementId: any) => {
    try {
      const id = await topicService.deleteTopic({
        topicId: topic.id,
        replacementId,
      })

      this.updateTopic(topic.id, { deletedAt: new Date().toISOString() })
      if (replacementId) {
        this.loadAllTopics().then(() => this.filterSummaries())
      }

      msg.success('Topic Archived', '', 3)
      return id
    } catch (error: any) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'deleteTopic',
        data: { topic, replacementId },
      })
    }
  }

  restoreTopic = async (topic: any) => {
    try {
      const id = await topicService.restoreTopic({ topicId: topic.id })
      if (id) {
        this.updateTopic(topic.id, { deletedAt: null })
      }

      return id
    } catch (error: any) {
      msg.error(error.message)
      captureExceptionSilently(error, {
        message: 'restoreTopic',
        data: { topic },
      })
    }
  }

  updateTopic(id: number, updatedTopic: Partial<Topic>) {
    this._topics = this._topics.map((item) => (item.id === id ? updatedTopic : item)) as Topic[]
  }
  findTopic = (name: string) => {
    return find(this._topics, { name })
  }
  findTopicById = (id: number) => {
    return find(this._topics, { id })
  }

  getSummary = (id: number) => {
    return find(this._summaries, { id })
  }

  getTopicByKebabCasedName = (kebabNameToFind: any) => {
    return (
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
      this.topics.find((topic) => kebabCase(topic.name) === kebabNameToFind) || {}
    )
  }
}

export default Topics
