import { WealthboxContact } from 'types/graphql'
import { loadContact, loadContacts } from 'stores/services/wealthbox.service'
import { ContactFactory } from '../genericData/generic.factory'
import { contactFields, contactIdFields } from './wealthbox.constants'
import { isEmpty } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { fieldParser } from './wealthbox.fieldparser'

export class WealthboxContactFactory extends ContactFactory<WealthboxContact> {
  dataFields: any = contactFields
  contactIdFields?: string[] = contactIdFields
  selectedContactId: number
  customFieldParser = fieldParser
  contacts = observable.array<WealthboxContact>([])

  constructor() {
    super('wealthbox-connector')
    makeObservable(this, {
      selectedContactId: observable,
      contacts: observable,
    })
  }

  loadContacts = async (name: string = '', reset: boolean = false): Promise<WealthboxContact[]> => {
    if (isEmpty(name) && this.contacts.length && !reset) {
      return this.contacts
    }

    const contacts = await loadContacts(name)

    this.contacts.replace(this.sortNameFunc(contacts))
    return this.contacts
  }

  loadContact = async (contactId: number): Promise<WealthboxContact> => {
    return loadContact(String(contactId))
  }

  onSelectContact = (contactId: number): void => {
    this.selectedContactId = contactId
  }

  contactNameFunc = (contact: WealthboxContact): string | undefined => {
    if (!contact) return undefined

    if (!isEmpty(contact.name)) {
      return contact.name
    }

    const firstName = contact.first_name
    const lastName = contact.last_name

    if (!isEmpty(firstName) && !isEmpty(lastName)) {
      return `${firstName} ${lastName}`
    }

    if (!isEmpty(firstName) && isEmpty(lastName)) {
      return `${firstName}`
    }

    if (isEmpty(firstName) && !isEmpty(lastName)) {
      return `${lastName}`
    }

    return undefined
  }

  sortNameFunc = (contacts: WealthboxContact[]): WealthboxContact[] => {
    return [...contacts].sort((a, b) => {
      let aName = a.first_name
      let bName = b.first_name

      if (isEmpty(aName) || a.type !== 'Person') {
        aName = a.name
      }
      if (isEmpty(bName) || a.type !== 'Person') {
        bName = b.name
      }

      if (!aName) return 1
      if (!bName) return -1
      return aName.localeCompare(bName)
    })
  }
}
