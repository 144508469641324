/* eslint-disable no-underscore-dangle */
import { FilterTypes } from 'components/routes/tasks/notes/notes.constants'
import { queryParams as qp } from 'helpers/href'
import { BrowserHistory, Listener, Update, createBrowserHistory } from 'history'
import { isEmpty } from 'lodash'
import { computed, makeObservable, observable } from 'mobx'
import qs from 'qs'
import type { Location } from 'react-router'

class AppRouter {
  history: BrowserHistory = createBrowserHistory()
  lastLocation: Partial<Location> = { search: '' }
  location: Partial<Location> = { search: '' }
  _historyPerRoute: Record<string, string> = {}

  /**
   * location.pathname shortcut
   */
  get path() {
    const path = this.location?.pathname
    return path ?? ''
  }

  /**
   * location.pathname shortcut - mo query, no leading/trailing slashes
   */
  get pathname() {
    const path = this.path.split('?')[0]
    return path.replace(/^\/+|\/+$/g, '')
  }

  get pathQuery() {
    const path = this.path
    const query = this.location?.search
    return `${path}${query ?? ''}`
  }

  /**
   * location.search shortcut - parsed object
   */
  get params() {
    const { search = '' } = this.location
    const query = isEmpty(search) ? '' : search
    return qp(query.replace(/^\?/, ''))
  }

  constructor() {
    makeObservable(this, {
      history: observable.ref,
      lastLocation: observable.ref,
      location: observable,
      path: computed,
      pathname: computed,
      params: computed,
    })

    this.location = this.history.location
    this.history.listen(this._onLocationChange)
  }

  /* ---------- public ---------- */

  /* ---------- public ---------- */

  /**
   * Sends browser to specified path
   * @param pathname The path of the URL
   * @param search The URL query string
   * @param state Some extra state for this location
   * @param hash The URL hash fragment
   *
   * either:
   *  .goto() // navigate to homepage
   *  .goto({ pathname: 'dashboard', state: { a: 'A' }) // use location object
   *  .goto(pathname, state) // pathname, state params
   */
  goto = (...args: Partial<Location>[] | string[]) => {
    switch (true) {
      // homepage
      case !args.length:
        this.history.push('/')
        break
      // location object
      case typeof args[0] !== 'string':
        this.history.push(args[0])
        break
      // pathname, state params
      default:
        this.history.push(args[0], args[1] || {})
        break
    }
  }

  /**
   * @param clientId
   * @param eventId
   */
  gotoEvent = (
    clientId: any,
    eventId: any,
    filter: string = FilterTypes.NOTES,
    goto: boolean = true,
    searchParams?: string,
  ) => {
    const path = `/notes/client?userId=${clientId}&filter=${filter}&apptId=${eventId}${
      searchParams ? '&' + searchParams : ''
    }`
    if (goto) {
      this.goto(path)
    }
    return path
  }

  /**
   * @param params
   */
  gotoParams = (params: Record<any, any>, merge = true) => {
    const newParams = merge
      ? { ...global.router.params, ...params }
      : { ...params }
    const filteredParams = Object.keys(newParams).reduce((all, key) => {
      if (newParams[key] !== 'DELETE') {
        ;(all as any)[key] = newParams[key]
      }
      return all
    }, {})

    console.log(`🐛 -> RouteParams`, JSON.stringify(filteredParams))

    this.goto(`${this.path}?${qs.stringify(filteredParams)}`)
  }

  /**
   * Opens a new browser tab/window for a route
   */
  open = (pathname: string, target = '_blank', focus = true) => {
    const path = this.history.createHref({ pathname })
    const win = window.open(path, target)
    if (focus) {
      win?.focus()
    }
  }

  reload = () => {
    this.history && this.history.replace('/')
    window.location.reload()
  }

  /**
   * Replace the url state with a non-hash, non-query url
   */
  dehash = () => {
    this.history && this.history.replace(window.location.pathname)
  }

  /* ---------- private ---------- */

  /**
   * @param param0
   */
  _onLocationChange: Listener = ({ location }: Update) => {
    console.log(`Route: ${location.pathname}`)

    this.lastLocation = this.location
    this.location = location

    this.registerSearchHistoryPerRoute(location)
  }

  /**
   * @param state
   * @returns
   */
  registerSearchHistoryPerRoute = (state: Partial<Location>) => {
    if (!state || !state.pathname) {
      return
    }
    const params = new URLSearchParams(state.search).toString()
    if (!params) {
      return
    }
    this._historyPerRoute[state.pathname] = params
  }

  /**
   * @param pathname
   * @returns
   */
  getLastParamsOfRoute = (pathname?: string): string => {
    const path = pathname || this.location?.pathname

    if (path && this._historyPerRoute[path]) {
      return this._historyPerRoute[path]
    }

    return ''
  }
}

export default AppRouter
